import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

function getUrlDomainGlobal(nome_metodo) {

  let result = "";

  if (location.hostname == "localhost") {
    result = location.protocol + "//emtool.local/";
  }

  if (location.hostname != "localhost") {

    result = location.protocol + "//" + location.hostname + "/";

  }

  return result + nome_metodo;


}


var main_domain = getUrlDomainGlobal("");

class APIHolidayPermits {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  getUrlDomainApi(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/public/api/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/api/";

    }

    return result + nome_metodo;

  }


  async getHolPermReq(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtGetVoicesReqHolidayApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async insHoliday(user, v_data_inizio, v_data_fine, v_tipo_richiesta, v_ora_inizio, v_ora_fine, v_request_type, v_note) {

    let formData = new FormData();

    formData.append("v_user", user);
    formData.append("data_inizio", v_data_inizio);
    formData.append("data_fine", v_data_fine);
    formData.append("tipo_richiesta", v_tipo_richiesta);
    formData.append("ora_inizio", v_ora_inizio);
    formData.append("ora_fine", v_ora_fine);
    formData.append("request_type", v_request_type);
    formData.append("note", v_note);

    return axios.post(this.getUrlDomainApi("insertHolidaysPermits"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async sendRequestForApprovation(user, v_id) {

    let formData = new FormData();

    formData.append("v_user", user);
    formData.append("id_richiesta", v_id);

    return axios.get(this.getUrlDomain("sendMultiRequestHolidaysPermits"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async insHolidayNew(v_token) {

    let formData = new FormData();

    formData.append("v_token", v_token);

    return axios.post(this.getUrlDomainApi("insertHolidaysPermitsNew"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insHolidayNewAdmin(v_token) {

    let formData = new FormData();

    formData.append("v_token", v_token);

    return axios.post(this.getUrlDomainApi("insertHolidaysPermitsNewAdmin"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteHolidaysPermitsNew(v_id, v_user) {

    let formData = new FormData();

    formData.append("v_id", v_id);
    formData.append("v_user", v_user);


    return axios.post(this.getUrlDomain("deleteHolidaysPermitsNew"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async checkRequestHolidayRis(v_date_start, v_date_end) {

    let formData = new FormData();

    formData.append("v_date_start", v_date_start);
    formData.append("v_date_end", v_date_end);


    return axios.post(this.getUrlDomain("checkRequestHolidayRis"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getHours(username) {

    let formData = new FormData();

    formData.append("username", username);

    return axios.post(this.getUrlDomain("getHours"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getPeriodDisease(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("getPeriodDisease"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getPeriodHoliday(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("getPeriodHoliday"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  async updateHoliday(v_token, v_id, v_causale, v_date_start, v_time_start, v_date_end, v_time_end, v_note) {

    let formData = new FormData();

    formData.append("v_id", v_id);
    formData.append("v_causale", v_causale);
    formData.append("v_date_start", v_date_start);
    formData.append("v_time_start", v_time_start);
    formData.append("v_date_end", v_date_end);
    formData.append("v_time_end", v_time_end);
    formData.append("v_note", v_note);
    formData.append("v_token", v_token);

    return axios.post(this.getUrlDomain("updateHoliday"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateDayHolidays(v_id_header, v_day_sel, v_causale, v_time_start, v_time_end, v_note, v_token) {

    let formData = new FormData();

    formData.append("v_id_header", v_id_header);
    formData.append("v_day_sel", v_day_sel);
    formData.append("v_causale", v_causale);
    formData.append("v_time_start", v_time_start);
    formData.append("v_time_end", v_time_end);
    formData.append("v_note", v_note);
    formData.append("v_token", v_token);

    return axios.post(this.getUrlDomain("updateDayHoliday"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteDayHolidays(v_id_header, v_day_sel, v_token) {

    let formData = new FormData();

    formData.append("v_id_header", v_id_header);
    formData.append("v_day_sel", v_day_sel);
    formData.append("v_token", v_token);

    return axios.post(this.getUrlDomain("deleteDayHoliday"), formData, { 'Content-Type': 'multipart/form-data' });

  }

}

export default new APIHolidayPermits({
  url: main_domain
})
