<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center"
        style="max-width: 300px; min-width: 300px">

        <div class="Absolute-Center">

        </div>

    </v-container>
</template>

<script>

import router from ".././router";
import apiholidaypermits from "../utils/holidaypermits/apiholidaypermits";


export default {

    mounted: async function () {

        var pointerVue = this;

        if (pointerVue.$route.params.id == 0) {

            window.$cookies.set("yearStartPeriodDisease", "", "9y");
            window.$cookies.set("monthStartPeriodDisease", "", "9y");
            window.$cookies.set("dayStartPeriodDisease", "", "9y");


            window.$cookies.set("yearEndPeriodDisease", "", "9y");
            window.$cookies.set("monthEndPeriodDisease", "", "9y");
            window.$cookies.set("dayEndPeriodDisease", "", "9y");


            setTimeout(() => {

                router.push({
                    path: "/editDiseaseNew/0"
                });

            }, 500);


        }

        if (pointerVue.$route.params.id > 0) {



            pointerVue.getPeriodDisease(pointerVue.$route.params.id);


        }








    },

    data: () => ({
        showPicker: false,

    }),

    methods: {


        getPeriodDisease: async function (v_id) {

            var that = this;


            var response = await apiholidaypermits.getPeriodDisease(
                v_id
            ).then((res) => {

                console.log("res from getPeriodDisease", res);

                var elements = res.data.periodo_dal.split("-");

                console.log(elements);

                var v_anno = parseInt(elements[0]);
                var v_mese = parseInt(elements[1]) - 1;
                var v_giorno = parseInt(elements[2]);

                window.$cookies.set("yearStartPeriodDisease", v_anno, "9y");
                window.$cookies.set("monthStartPeriodDisease", v_mese, "9y");
                window.$cookies.set("dayStartPeriodDisease", v_giorno, "9y");



                var elements2 = res.data.periodo_al.split("-");

                console.log(elements2);

                var v_anno2 = parseInt(elements2[0]);
                var v_mese2 = parseInt(elements2[1]) - 1;
                var v_giorno2 = parseInt(elements2[2]);

                window.$cookies.set("yearEndPeriodDisease", v_anno2, "9y");
                window.$cookies.set("monthEndPeriodDisease", v_mese2, "9y");
                window.$cookies.set("dayEndPeriodDisease", v_giorno2, "9y");



                setTimeout(() => {

                    router.push({
                        path: "/editDiseaseNew/" + that.$route.params.id
                    });

                }, 500);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile eliminare questa richiesta");
                console.log("response", response);

            }

            );



        }


    },
};
</script>
