import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIRisorse {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  // async initInsertRisorsa(user, prodotto) {

  //   let formData = new FormData();

  //   formData.append("username", user);
  //   formData.append("prodotto", prodotto);

  //   return axios.post(this.getUrlDomain("EmtInitAddRisorsaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  // }

  async initInsertRisorsa(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("goInitInsertResource"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertRisorsa(risorsa, user, chkCable, chkDesktop, chkNotebook, chkSoftwareLicence, chkSmartphone, chkSimAndNumber, chkVeicoloAziendale, chkAltro) {

    let formData = new FormData();

    formData.append("username", user);

    formData.append("chkCable", chkCable);
    formData.append("chkDesktop", chkDesktop);
    formData.append("chkNotebook", chkNotebook);
    formData.append("chkSoftwareLicence", chkSoftwareLicence);
    formData.append("chkSmartphone", chkSmartphone);
    formData.append("chkSimAndNumber", chkSimAndNumber);
    formData.append("chkVeicoloAziendale", chkVeicoloAziendale);
    formData.append("chkAltro", chkAltro);

    for (var key in risorsa) {
      formData.append(key, risorsa[key]);
    }

    return axios.post(this.getUrlDomain("EmtInsertRisorsaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  // async initEditRisorsa(id, user, prodotto) {


  //   return axios.get(this.getUrlDomain("EmtInitEditRisorsaApi") + "/" + id + "?api=1&username=" + user + "&prodotto=" + prodotto, { 'Content-Type': 'multipart/form-data' });

  // }

  async initEditRisorsa(id, user, prodotto) {


    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("goInitEditResource"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateRisorsa(risorsa, id, user, chkCable, chkDesktop, chkNotebook, chkSoftwareLicence, chkSmartphone, chkSimAndNumber, chkVeicoloAziendale, chkAltro) {

    let formData = new FormData();

    formData.append("chkCable", chkCable);
    formData.append("chkDesktop", chkDesktop);
    formData.append("chkNotebook", chkNotebook);
    formData.append("chkSoftwareLicence", chkSoftwareLicence);
    formData.append("chkSmartphone", chkSmartphone);
    formData.append("chkSimAndNumber", chkSimAndNumber);
    formData.append("chkVeicoloAziendale", chkVeicoloAziendale);
    formData.append("chkAltro", chkAltro);

    for (var key in risorsa) {
      formData.append(key, risorsa[key]);
    }

    return axios.post(this.getUrlDomain("EmtUpdateRisorsaApi") + "/" + id + "?username=" + user, formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteRisorsa(id, user) {


    return axios.get(this.getUrlDomain("EmtDeleteRisorsaApi") + "?id=" + id + "&username=" + user, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteRisorsaMulti(id, user) {


    return axios.get(this.getUrlDomain("EmtDeleteRisorsaMultiApi") + "?id=" + id + "&username=" + user, { 'Content-Type': 'multipart/form-data' });

  }

  async pianoOrarioAzienda(id_azienda, user) {

    let formData = new FormData();

    formData.append("companyid", id_azienda);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtPianoOrarioRisorsaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initCreateResFromUser(user, companyidsel) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("companyidsel", companyidsel);

    return axios.post(this.getUrlDomain("EmtInitCreateResourceFromUserApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async initInsertClientiProgetti(user, ourcompany, idresource) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("ourcompany", ourcompany);
    formData.append("idresource", idresource);

    return axios.post(this.getUrlDomain("EmtInitInsRisorseClientiAssociatiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initInsertClientiProgettiNew(user, ourcompany, idresource) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("ourcompany", ourcompany);
    formData.append("idresource", idresource);

    return axios.post(this.getUrlDomain("EmtInitInsRisorseClientiAssociatiNewApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertClientiProgetti(clienti, user, idrisorsa) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("idrisorsa", idrisorsa);

    for (var key in clienti) {
      formData.append(key, clienti[key]);
    }

    return axios.post(this.getUrlDomain("EmtInsRisorseClientiAssociatiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editClientiProgetti(v_id, user, ourcompany) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", v_id);
    formData.append("ourcompany", ourcompany);

    return axios.post(this.getUrlDomain("EmtInitEditRisorseClientiAssociatiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async updateClientiProgetti(clienti, user, idrisorsa, v_id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("idrisorsa", idrisorsa);
    formData.append("v_id", v_id);

    for (var key in clienti) {
      formData.append(key, clienti[key]);
    }

    return axios.post(this.getUrlDomain("EmtUpdRisorseClientiAssociatiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteClientiProgetti(v_id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("EmtDelRisorseClientiAssociatiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async syncReferenteSel(v_id_res, v_id_cust) {

    let formData = new FormData();

    formData.append("v_id_res", v_id_res);
    formData.append("v_id_cust", v_id_cust);

    return axios.post(this.getUrlDomain("EmtSyncReferResPeriodoProva"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async setReferenteResPeriodoProva(v_id_ref, v_id_res) {

    let formData = new FormData();

    formData.append("v_id_ref", v_id_ref);
    formData.append("v_id_res", v_id_res);

    return axios.post(this.getUrlDomain("EmtSetReferResPeriodoProva"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async setCorseRisorsa(v_id, corso, user) {

    let formData = new FormData();

    formData.append("v_id", v_id);
    formData.append("user", user);

    for (var key in corso) {
      formData.append(key, corso[key]);
    }

    return axios.post(this.getUrlDomain("setCorseRisorsa"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async editCorseRisorsa(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("editCorseRisorsa"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteCorsoRisorsa(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("deleteCorsoRisorsa"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveTotHolidaysResources(v_array_data) {

    let formData = new FormData();

    formData.append("v_array_data", v_array_data);

    return axios.post(this.getUrlDomain("saveTotHolidaysResources"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateTotalsWithTs(ids, anno, mese) {

    let formData = new FormData();

    formData.append("ids", ids);
    formData.append("anno", anno);
    formData.append("mese", mese);

    return axios.post(this.getUrlDomain("updateTotalsWithTs"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async sendMailHolidaysPermits(v_body, v_login) {

    let formData = new FormData();

    formData.append("v_body", v_body);
    formData.append("v_login", v_login);

    return axios.post(this.getUrlDomain("sendMailHolidaysPermits"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteRowLog(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("deleteRowLog"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getCostiAziendaliRisorsa(v_user) {

    let formData = new FormData();

    formData.append("v_user", v_user);

    return axios.post(this.getUrlDomain("getCostiAziendaliRisorsa"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getInfoAssocUser(v_login) {

    let formData = new FormData();

    formData.append("v_login", v_login);

    return axios.post(this.getUrlDomain("getInfoAssocUser"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateTsNotInForce(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("updateTsNotInForce"), formData, { 'Content-Type': 'multipart/form-data' });

  }

}

export default new APIRisorse({
  url: "https://services.ebadge.it/public/api/"
})
