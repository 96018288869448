import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIScadenze {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async initDDL(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtAgilaCtrlsInitDDLApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getInfoAzienda(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EmtAgilaSediOpGetInfoAzApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveSedeOperativa(sede_operativa, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in sede_operativa) {
      formData.append(key, sede_operativa[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaSaveSediOpApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editScadenza(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);


    return axios.post(this.getUrlDomain("EmtAgilaEditScadApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async deleteScadenza(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);


    return axios.post(this.getUrlDomain("EmtAgilaDeleteScadApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertScadenza(controllo, user) {

    let formData = new FormData();

    formData.append("username", user);

    for (var key in controllo) {
      formData.append(key, controllo[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaInsScadApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateScadenza(controllo, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in controllo) {
      formData.append(key, controllo[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaUpdScadApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteCondizioni(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    return axios.post(this.getUrlDomain("EmtAgilaDeleteCtrlsCondApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async exportXls(user, curSrcText, curCompany, curPrjType, curCtrType, curStatus) {

    let formData = new FormData();

    formData.append("username", user);

    formData.append("curSrcText", curSrcText);
    formData.append("curCompany", curCompany);
    formData.append("curPrjType", curPrjType);
    formData.append("curCtrType", curCtrType);
    formData.append("curStatus", curStatus);


    return axios.post(this.getUrlDomain("EmtAgilaExportXlsCtrlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async loadScadStd(idpratica, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("idp", idpratica);

    return axios.post(this.getUrlDomain("EmtAgilaScadLoadStdValApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getAzienda(id_azienda) {

    let formData = new FormData();

    formData.append("id_azienda", id_azienda);

    return axios.post(this.getUrlDomain("goGetAzienda"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveTipologiaGruppoMail(v_id, gruppo_mail_tipologia) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    for (var key in gruppo_mail_tipologia) {
      formData.append(key, gruppo_mail_tipologia[key]);
    }

    return axios.post(this.getUrlDomain("goSaveGruppoMailTipologia"), formData, { 'Content-Type': 'multipart/form-data' });

  }



}

export default new APIScadenze({
  url: "https://services.ebadge.it/public/api/"
})
