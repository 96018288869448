import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

function getUrlDomainGlobal(nome_metodo)
{

  let result = "";

  if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
  }

  if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

  }

  return result + nome_metodo;


}

var main_domain = getUrlDomainGlobal("");

class APIDisease {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async getHolPermReq(user)
  {

    let formData = new FormData();

    formData.append("username",  user);
   
    return axios.post(this.getUrlDomain("EmtGetVoicesReqHolidayApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getEditDisease(user, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
   
    return axios.post(this.getUrlDomain("EmtGetEditDiseaseApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async initInsertDisease(user, companyid)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("companyid",  companyid);
   
    return axios.post(this.getUrlDomain("initInsertDisease") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getEditAdminDisease(user, id, companyid)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
    formData.append("companyid",  companyid);
   
    return axios.post(this.getUrlDomain("EmtGetEditAdminDiseaseApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async updateDay(v_id, v_protocolli, v_day, v_token)
  {

    let formData = new FormData();

    formData.append("v_id",  v_id);
    formData.append("v_protocolli",  v_protocolli);
    formData.append("v_day",  v_day);
    formData.append("v_token",  v_token);
   
    return axios.post(this.getUrlDomain("updateDayDisease") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteDay(v_id, v_day, v_token, v_hour)
  {

    let formData = new FormData();

    formData.append("v_id",  v_id);
    formData.append("v_day",  v_day);
    formData.append("v_token",  v_token);
    formData.append("v_hour",  v_hour);
   
    return axios.post(this.getUrlDomain("deleteDayDisease") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

}

export default new APIDisease({
  url: main_domain
})
