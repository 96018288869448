import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APISpese {


    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    getUrlDomainApi(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + "api/" + nome_metodo;


    }

    getUrlDomainGo(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = "http://localhost:4008/";
        }

        if (location.hostname != "localhost") {

            result = "https://filestransfer.it:4008/";

        }


        return result + nome_metodo;

    }


    async deleteMultiSpese(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("EasyERDeleteMultiSpeseApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async sendForAppr(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("EasyERSendForApproveSpeseApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async validateForAppr(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("EasyERValidateApproveSpeseApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    // async validateForAppr(user) {

    //     let formData = new FormData();

    //     formData.append("username", user);

    //     return axios.post(this.getUrlDomainGo("validateForAppr"), formData, { 'Content-Type': 'multipart/form-data' });

    // }



    async calcImpEuroForKm(user, km) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("km", km);

        return axios.post(this.getUrlDomain("EasyERCalcImpKmApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async changeTypeSpese(user, id, stato_to) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("stato_to", stato_to);

        return axios.post(this.getUrlDomain("EasyERChangeTypeSpeseApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    // async getSpese(id, user) {

    //   let formData = new FormData();

    //   formData.append("username", user);
    //   formData.append("id", id);

    //   return axios.post(this.getUrlDomain("EasyERGetSpeseUtenteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    // }

    async getSpese(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomainGo("editSpesa"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async updateSpese(spese, id, user, foto) {


        let formData = new FormData();

        formData.append("id", id);
        formData.append("v_user", user);
        formData.append("foto", foto);

        for (var key in spese) {
            formData.append(key, spese[key]);
        }

        return axios.post(this.getUrlDomain("EasyERUpdateSpeseApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async insertSpese(spese, foto, user) {


        let formData = new FormData();

        formData.append("foto", foto);
        formData.append("username", user);

        for (var key in spese) {
            formData.append(key, spese[key]);
        }

        return axios.post(this.getUrlDomain("EasyERInsertSpeseApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    // async insertSpese(spese, foto, user) {


    //     let formData = new FormData();

    //     formData.append("foto", foto);
    //     formData.append("username", user);

    //     for (var key in spese) {
    //         formData.append(key, spese[key]);
    //     }

    //     return axios.post(this.getUrlDomainGo("insertSpesa"), formData, { 'Content-Type': 'multipart/form-data' });

    // }



    async validateInsertSpese(user, data_scontrino, importo) {


        let formData = new FormData();

        formData.append("username", user);
        formData.append("data_scontrino", data_scontrino);
        formData.append("importo", importo);


        return axios.post(this.getUrlDomain("EasyERValidateInsertSpeseApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    // async validateInsertSpese(user, data_scontrino, importo) {


    //     let formData = new FormData();

    //     formData.append("username", user);
    //     formData.append("data_scontrino", data_scontrino);
    //     formData.append("importo", importo);


    //     return axios.post(this.getUrlDomainGo("validateInsertSpesa"), formData, { 'Content-Type': 'multipart/form-data' });

    // }





    async validateUpdateSpese(user, data_scontrino, importo, id) {


        let formData = new FormData();

        formData.append("username", user);
        formData.append("data_scontrino", data_scontrino);
        formData.append("importo", importo);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("EasyERValidateUpdateSpeseApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async validateEnableSpese(user) {


        let formData = new FormData();

        formData.append("username", user);


        return axios.post(this.getUrlDomain("EasyEREnableApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initInsert(user) {


        let formData = new FormData();

        formData.append("username", user);


        return axios.post(this.getUrlDomain("EasyERinitInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    //   async initInsert(user) {


    //     let formData = new FormData();

    //     formData.append("username", user);


    //     return axios.post(this.getUrlDomainGo("initInsertSpesa"), formData, { 'Content-Type': 'multipart/form-data' });

    //   }

    async createLinkForEasyeer(user, v_data) {


        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_data", v_data);


        return axios.post(this.getUrlDomain("createLinkForEasyeer"), formData, { 'Content-Type': 'multipart/form-data' });

    }



}

export default new APISpese({
    url: "http://emtool.local/public/api/smartbook/"
})
