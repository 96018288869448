import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIProfile {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "public/api/smartbook/" + nome_metodo;


  }


  getUrlDomainNew(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }




  /*   constructor ({ url }) {
        console.log ( url );
        httpOk = axios.create({
        baseURL: url,
         headers: { 'Content-Type': 'application/json' }
      })
    } */


  getProfile(username, tokensystem) {
    //var resource = "/getWorkerInfo" ;
    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);


    let payload = "user=" + username + "&password=" + tokensystem + `&timestamp=${new Date().getTime()}`;

    return axios.post(`https://services.ebadge.it/public/api/getWorkerInfoWithoutResAlertMsg?${payload}`, formData, { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data' });

  }



  setProfile(username, tokensystem, profile) {
    // var resource = "/setWorkerInfo" ;
    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);
    formData.append("date", new Date().getTime());

    for (var key in profile) {
      // console.log (key,profile[key] );
      formData.append(key, profile[key]);
    }
    //console.log ( formData );
    return axios.post(`https://services.ebadge.it/public/api/setWorkerInfo`, formData, { 'Content-Type': 'multipart/form-data' });

    //return axios.post( `https://services.ebadge.it/public/api/setWorkerInfoWithoutRes` , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

    //return axios.post( `http://services.local/public/api/setWorkerInfoWithoutRes` , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

    //    return httpOk.post(resource, formData   ) ;
  }




  setProfileNew(username, tokensystem, profile, send_mail_change) {
    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);
    formData.append("date", new Date().getTime());
    formData.append("send_mail_change", send_mail_change);

    for (var key in profile) {
      formData.append(key, profile[key]);
    }

    return axios.post(this.getUrlDomainNew("updateProfile"), formData, { 'Content-Type': 'multipart/form-data' });


  }

  goSetProfileNew(username, tokensystem, profile, send_mail_change) {
    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);
    formData.append("date", new Date().getTime());
    formData.append("send_mail_change", send_mail_change);

    for (var key in profile) {
      formData.append(key, profile[key]);
    }

    return axios.post(this.getUrlDomainNew("goUpdateProfile"), formData, { 'Content-Type': 'multipart/form-data' });


  }







  setProfileWithoutRes(username, tokensystem, profile) {
    // var resource = "/setWorkerInfo" ;
    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);
    formData.append("date", new Date().getTime());

    for (var key in profile) {
      // console.log (key,profile[key] );
      formData.append(key, profile[key]);
    }
    //console.log ( formData );
    //return axios.post( `https://services.ebadge.it/public/api/setWorkerInfo` , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

    return axios.post(`https://services.ebadge.it/public/api/setWorkerInfoWithoutRes`, formData, { 'Content-Type': 'multipart/form-data' });

    //eturn axios.post( `https://services.local/public/api/setWorkerInfoWithoutRes` , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

    //    return httpOk.post(resource, formData   ) ;
  }

  savePassword(username, tokensystem, profile) {
    // var resource = "/setWorkerInfo" ;
    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);

    for (var key in profile) {
      // console.log (key,profile[key] );
      formData.append(key, profile[key]);
    }
    //console.log ( formData );
    return axios.post(`https://app.emtool.eu/public/api/emt/setWorkerPassword`, formData, { 'Content-Type': 'multipart/form-data' });

    //    return httpOk.post(resource, formData   ) ;
  }




  savePasswordNew(username, tokensystem, profile, v_token) {
    // var resource = "/setWorkerInfo" ;
    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);
    formData.append("v_token", v_token);

    for (var key in profile) {
      // console.log (key,profile[key] );
      formData.append(key, profile[key]);
    }
    //console.log ( formData );
    return axios.post(this.getUrlDomainNew("savePwd"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  checkPassword(username, profile, v_token) {
    let formData = new FormData();
    formData.append("user", username);
    formData.append("v_token", v_token);

    for (var key in profile) {
      formData.append(key, profile[key]);
    }

    return axios.post(this.getUrlDomainNew("checkPassword"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  getProfileUser(username, tokensystem, id) {
    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);
    formData.append("id", id);


    let payload = "user=" + username + "&password=" + tokensystem + "&id=" + id + `&timestamp=${new Date().getTime()}`;

    //const params = new url.URLSearchParams(payload);

    //alert("before post");
    //return httpOk.post(  resource, formData   ) ;
    // alert(username+ " toke:" + tokensystem);

    return axios.post(this.getUrlDomain("getProfileUser") + `?${payload}`, formData, { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data' });


  }

  async getProfileEmt(username, tokensystem, v_token) {

    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);
    formData.append("v_token", v_token);

    return axios.post(this.getUrlDomainNew("getProfileEmt"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async goGetProfileEmt(username, tokensystem, v_token) {

    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);
    formData.append("v_token", v_token);

    return axios.post(this.getUrlDomainNew("goGetProfile"), formData, { 'Content-Type': 'multipart/form-data' });

  }

}

export default new APIProfile({
  url: "https://services.ebadge.it/public/api/"
})
