<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center"
        style="max-width: 300px; min-width: 300px">

        <div class="Absolute-Center">

        </div>

    </v-container>
</template>

<script>

import router from ".././router";
import apiholidaypermits from "../utils/holidaypermits/apiholidaypermits";


export default {

    mounted: async function () {

        var pointerVue = this;

        pointerVue.getPeriodHoliday(pointerVue.$route.params.id);








    },

    data: () => ({
        showPicker: false,

    }),

    methods: {


        getPeriodHoliday: async function (v_id) {

            var that = this;


            var response = await apiholidaypermits.getPeriodHoliday(
                v_id
            ).then((res) => {

                console.log("res from getPeriodHoliday", res);

                var elements = res.data.periodo_dal.split("-");

                console.log(elements);

                var v_anno = parseInt(elements[0]);
                var v_mese = parseInt(elements[1]) - 1;
                var v_giorno = parseInt(elements[2]);

                window.$cookies.set("yearStartPeriodHoliday", v_anno, "9y");
                window.$cookies.set("monthStartPeriodHoliday", v_mese, "9y");
                window.$cookies.set("dayStartPeriodHoliday", v_giorno, "9y");




                setTimeout(() => {

                    router.push({
                        path: "/updateFeriePermessi/" + that.$route.params.id
                    });

                }, 500);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile eliminare questa richiesta");
                console.log("response", response);

            }

            );



        }


    },
};
</script>
