<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_history_holiday_emt">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall' class='checkall' slot='start'
                                                    style="width:10px!important;height:10px!important">
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all clHeaderDataInizio'>Data I.</th>
                                        <th class='all cl-title-center clHeaderOraInizio'>Ora I.</th>
                                        <th class='all clHeaderDataFine'>Data F.</th>
                                        <th class='all cl-title-center clHeaderOraFine'>Ora F.</th>
                                        <th class='all cl-title-center'>Ore</th>
                                        <th class='all'>Risorsa</th>
                                        <th class='all'>Tipo</th>
                                        <th class='all'>Stato</th>
                                        <th class='all'>Note</th>
                                        <th class='all clHeaderInseritoIl'>Inserito Il</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="12">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-left">
            <v-bottom-sheet v-model="sheetChangeStatus">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetChangeStatus = !sheetChangeStatus">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                    <div class="py-3">
                        Seleziona stato
                    </div>
                    <v-list-item v-for="tile in allStatus" :key="tile.title" @click="changeStatusAdmin(tile.title)">
                        <v-list-item-avatar>
                            <v-avatar size="32px" tile>
                                <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.title
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetStatus">
                <v-sheet class="text-center" height="455px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetStatus = !sheetStatus">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                    <div class="py-3">
                        Seleziona stato
                    </div>
                    <v-list-item v-for="tile in allStatusFilter" :key="tile.title" @click="changeStatus(tile.title)">
                        <v-list-item-avatar>
                            <v-avatar size="32px" tile>
                                <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.title
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">

                                    <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                        style="margin-right:10px" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>


                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpopupdatefrom" style="display:none">

            <template>
                <modal name="popupDateFrom" :clickToClose="false" :width="300" :height="400">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataDa" v-model="dataDa" label="Da" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataDa" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataA" v-model="dataA" label="A" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataA" no-title @input="showPicker4 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-from" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" />


                                <v-img alt="" class="btn-reset-date-from" contain src="@/assets/reset.png"
                                    max-width="25" max-height="25"
                                    style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" />

                                <v-img alt="" class="btn-confirm-date-from" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="408">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Filtri

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="Cerca testo" class="styleInput">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                        <v-col cols="12" md="12"
                            style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                            <div id="cont_anno" style="cursor:pointer">
                                <ion-item id="itemAnno"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                        position="floating">Stato </ion-label>

                                    <ion-select id="stato" name="stato" class='stato' cancel-Text="Chiudi" done-Text=""
                                        :value="filterStato" interface="action-sheet">

                                        <ion-select-option v-for="item in allStatusFilter" :key="item.title"
                                            :value='item.title'>
                                            {{
                                                item.title }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                        <v-col cols="12" md="12"
                            style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataDa" v-model="dataDa" class="styleInput"
                                        label="Periodo Dal" persistent-hint readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataDa" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                        <v-col cols="12" md="12"
                            style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                            <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataA" class="styleInput" v-model="dataA" label="Periodo Al"
                                        persistent-hint readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataA" no-title @input="showPicker4 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-delete-search" contain src="@/assets/reset-iemt.png"
                                    max-width="35" max-height="35"
                                    style="cursor:pointer;color:black;margin-right:10px;margin-top: -2px;"
                                    transition="scale-transition" title="Elimina Filtri" @click="btnResetSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupinsert" style="display:none">

            <template>
                <modal name="popupInsert" :clickToClose="false" :width="263" :height="682">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Inserimento Richiesta

                        </v-col>

                    </v-row>

                    <v-row style="padding-bottom:0px!important">

                        <v-col cols="12" md="12" style="padding-bottom:0px!important">

                            <div id="cont_companyid" style="cursor:pointer">
                                <ion-item id="itemAzienda"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                        position="floating">Tipo Richiesta </ion-label>

                                    <ion-select id="idtype_holidays_permits" name="idtype_holidays_permits"
                                        class='idtype_holidays_permits' cancel-Text="Chiudi" done-Text=""
                                        :value="causale" interface="action-sheet">

                                        <ion-select-option v-for="item in causali" :key="item.idtype_holidays_permits"
                                            :value='item.idtype_holidays_permits'> {{
                                                item.description
                                            }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>


                    </v-row>

                    <v-row v-if="vediTipoGiornata" style="padding-bottom:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12" style="padding-bottom:0px!important;padding-top:0px!important">

                            <div class="flex-center">

                                <v-radio-group v-model="tipoGiornata" row>

                                    <v-radio label="Giornata Completa" value="G_COMPLETA"
                                        v-on:change="chgRadio"></v-radio>
                                    <v-radio label="Giornata Parziale" value="G_PARZIALE"
                                        v-on:change="chgRadio"></v-radio>

                                </v-radio-group>


                            </div>



                        </v-col>



                    </v-row>

                    <v-row v-if="vediDataInizio" style="padding-bottom:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12" style="padding-bottom:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="startDate" label="Data Inizio" persistent-hint readonly
                                        v-on="on">
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="startDate" no-title @input="showPicker = false"
                                    locale="it"></v-date-picker>
                            </v-menu>


                        </v-col>


                    </v-row>




                    <v-row v-if="vediOraInizio" style="padding-bottom:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12"
                            style="padding-bottom:0px!important;padding-top:0px!important;margin-left: 11px;">

                            <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Ora
                                Inizio</label>
                            <ion-datetime minute-Values="0,15,30,45"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px;width:238px"
                                id="startTime" name="startTime" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm"></ion-datetime>



                        </v-col>


                    </v-row>


                    <v-row v-if="vediDataFine" style="padding-bottom:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12" style="padding-bottom:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="endDate" label="Data Fine" persistent-hint readonly
                                        v-on="on">
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="endDate" no-title @input="showPicker3 = false"
                                    locale="it"></v-date-picker>
                            </v-menu>


                        </v-col>


                    </v-row>



                    <v-row v-if="vediOraFine" style="padding-bottom:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12"
                            style="padding-bottom:0px!important;padding-top:0px!important;margin-left: 11px;">

                            <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Ora
                                Fine</label>
                            <ion-datetime minute-Values="0,15,30,45"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px;width:238px"
                                id="endTime" name="endTime" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm"></ion-datetime>


                        </v-col>


                    </v-row>




                    <v-row v-if="vediNote" style="padding-bottom:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12" style="padding-bottom:0px!important;padding-top:0px!important">

                            <v-textarea v-model="notes" id="note" label="Note"></v-textarea>


                        </v-col>


                    </v-row>





                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-insert" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelInsert" />

                                <v-img alt="" class="btn-confirm-insert" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmInsert" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>






    </div>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apiholidaypermits from "../utils/holidaypermits/apiholidaypermits";


import {
    bus
} from "../main";
import router from ".././router";

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();



        window.perpage = 100;
        bus.$off("bottomIconClickEvent");

        var pointerVue = this;

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
            //window.curStatus = "to Approve";

            window.curStatus = "Not Closed";

        }
        else {
            //window.curStatus = "Open";
            window.curStatus = "Tutti";

        }

        pointerVue.filterStato = window.curStatus;

        window.curSrcText = "";

        window.idRichestaFromMail = window.$cookies.get("sel_filter_idrichiesta_storico_ferie");




        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );


        $(document).ready(function () {




            $('body').on('ionChange', '.stato', function () {

                pointerVue.filterStato = this.value;

            });



            $('body').on('click', '.selfiltri', function () {

                console.log(("EVENTO CLICK SELFILTRI"));

                $(".contpopupsearch").show();

                pointerVue.$modal.show('popupSearch');

            });

            $('body').on('click', '.selaggiorna', function () {

                console.log(("EVENTO CLICK SELAGGIORNA"));

                pointerVue.hideShowButtons(false);

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();

            });


            $('body').on('ionChange', '.idtype_holidays_permits', function () {

                console.log(("EVENTO CLICK TIPO"));

                console.log(this.value);

                pointerVue.startDate = "";
                $("#startTime").val("");
                pointerVue.endDate = "";
                $("#endTime").val("");
                pointerVue.notes = "";

                pointerVue.causale = this.value;

                pointerVue.vediTipoGiornata = false;
                pointerVue.vediDataInizio = false;
                pointerVue.vediOraInizio = false;
                pointerVue.vediDataFine = false;
                pointerVue.vediOraFine = false;
                pointerVue.vediNote = false;

                if (this.value == 1) { // ferie


                    pointerVue.tipoGiornata = "G_COMPLETA";
                    pointerVue.vediTipoGiornata = true;
                    pointerVue.vediDataInizio = true;
                    pointerVue.vediDataFine = true;
                    pointerVue.vediNote = true;

                }

                if (this.value != 1) { // altri tipi di richieste


                    pointerVue.vediDataInizio = true;
                    pointerVue.vediOraInizio = true;
                    pointerVue.vediDataFine = true;
                    pointerVue.vediOraFine = true;
                    pointerVue.vediNote = true;


                }


            });



            $('body').on('click', '.selazienda', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetAziende = true;

            });


            $('body').on('click', '.selstato', function () {

                console.log(("EVENTO CLICK SELSTATO"));

                pointerVue.sheetStatus = true;

            });



            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            $('body').on('click', '.seltimesheet', function (event) {
                console.log(event);


                router.push({
                    path: "/timesheetGridUnProj"
                });



            });

            $('body').on('click', '.seldata', function (event) {
                console.log(event);


                $(".contpopupdatefrom").show();

                pointerVue.$modal.show('popupDateFrom');



            });

            $('body').on('click', '.btn-cancel-date-from', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDateFrom');

            });

            $('body').on('click', '.btn-reset-date-from', function (event) {
                console.log(event);

                pointerVue.dataDa = null;
                pointerVue.dataA = null;


            });


            $('body').on('click', '.btn-confirm-date-from', function (event) {
                console.log(event);

                window.idRichestaFromMail = "";

                window.$cookies.set("sel_filter_idrichiesta_storico_ferie", "", "9y");


                window.dataDa = pointerVue.dataDa;
                window.dataA = pointerVue.dataA;

                pointerVue.$modal.hide('popupDateFrom');

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();


            });


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });




        });



        setTimeout(() => {

            this.syncAziende();

            this.loadCausali();


        }, 100);


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;

            var ids = "";

            console.log(data);
            switch (data) {

                case "btn_delete":

                    this.deleteRow();

                    break;

                case "btn_changestatusadmin":

                    console.log("CURR ITEM: ", this.currItem.stato);

                    this.sheetChangeStatus = true;

                    break;

                case "btn_send_approvation":

                    this.sendInApprovation();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":

                    //this.insertHolidays();

                    router.push({
                        path: "/editFeriePermessiAdmin/0"
                    });


                    // if ((a2 == "N") || (b2 == "N")) {

                    //     router.push({
                    //         path: "/editFeriePermessi"
                    //     });


                    // }





                    break;

                case "btn_edit":



                    ids = "";
                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");

                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/initUpdateFeriePermessi/" + ids
                    });


                    break;

                case "btn_back":


                    var a = window.$cookies.get("a");
                    var b = window.$cookies.get("b");

                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                        router.push({
                            path: "/filterHolidayHistory"
                        });


                    }
                    else {

                        router.push({
                            path: "/holiday"
                        });

                    }




                    break;

                default:
                    break;

            }
        });




        this.setupButtonsGrid();

        window.start = 0;

        pointerVue.setupGrid();

        this.hideShowButtons(false);

        setTimeout(() => {

            $("th:first").removeClass("sorting_asc");


        }, 300);


    },
    data: () => ({

        filterStato: "",

        oreContratto: 0,

        vediTipoGiornata: false,

        vediDataInizio: false,
        vediOraInizio: false,
        vediDataFine: false,
        vediOraFine: false,
        vediNote: false,


        tipoGiornata: "",

        showTime: true,

        notes: "",

        startDate: "",
        endDate: "",


        causali: [],

        causale: "",

        textSearch: "",

        dataDa: null,
        showPicker3: false,

        dataA: null,
        showPicker4: false,

        aziende: [],

        search: "",

        sheetAziende: false,

        allStatus: [{
            img: 'keep.png',
            title: 'Refused',
            "id": 1,
            icon: "mdi-account-lock-open",
            "color": "red"
        },
        {
            img: 'hangouts.png',
            title: 'to Approve',
            "id": 2,
            icon: "mdi-account-arrow-right",
            "color": "yellow"
        },
        {
            img: 'inbox.png',
            title: 'Approved',
            "id": 3,
            icon: "mdi-account-lock",
            "color": "green"
        },

        {
            img: 'inbox.png',
            title: 'Open',
            "id": 3,
            icon: "mdi-account-lock-open",
            "color": "orange"
        },

        ],


        allStatusFilter: [{
            img: '',
            title: 'Tutti',
            "id": 1,
            icon: "mdi-table",
            "color": "black"
        },
        {
            img: 'keep.png',
            title: 'Refused',
            "id": 2,
            icon: "mdi-account-lock-open",
            "color": "red"
        },
        {
            img: 'hangouts.png',
            title: 'to Approve',
            "id": 3,
            icon: "mdi-account-arrow-right",
            "color": "yellow"
        },
        {
            img: 'inbox.png',
            title: 'Approved',
            "id": 4,
            icon: "mdi-account-lock",
            "color": "green"
        },

        {
            img: 'inbox.png',
            title: 'Open',
            "id": 5,
            icon: "mdi-account-lock-open",
            "color": "orange"
        },

        {
            img: 'inbox.png',
            title: 'Not Closed',
            "id": 6,
            icon: "mdi-account-lock-open",
            "color": "orange"
        },

        ],




        tiles: [{
            img: 'keep.png',
            title: 'Keep'
        }],



        sheetChangeStatus: false,
        sheetStatus: false,

        currItem: { stato: "" },


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,





    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')


        },

    },

    methods: {

        btnConfirmSearch: function () {

            window.curSrcText = this.textSearch;

            window.curStatus = this.filterStato;

            window.dataDa = this.dataDa;

            window.dataA = this.dataA;

            $('.selfiltri').attr('src', '/emt/img/emt-filtri-attivi.png');


            var filter1 = 1;
            if (this.textSearch == "") {
                filter1 = 0;
            }

            var filter2 = 1;
            if (this.filterStato == "Tutti") {
                filter2 = 0;
            }

            var filter3 = 1;
            if (this.dataDa == null) {
                filter3 = 0;
            }
            if (this.dataDa == "") {
                filter3 = 0;
            }

            var filter4 = 1;
            if (this.dataA == null) {
                filter4 = 0;
            }
            if (this.dataA == "") {
                filter4 = 0;
            }


            if ((filter1 == 0) && (filter2 == 0) && (filter3 == 0) && (filter4 == 0)) {

                $('.selfiltri').attr('src', '/emt/img/emt-filtri-disattivi.png');
            }



            this.$modal.hide('popupSearch');

            window.idRichestaFromMail = "";

            window.$cookies.set("sel_filter_idrichiesta_storico_ferie", "", "9y");

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            window.table.ajax.reload();

        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');
        },

        chgRadio: function () {

            console.log("CHANGE: ", this.tipoGiornata);


            var v_value = "";

            if (this.tipoGiornata == "G_COMPLETA") {
                v_value = "G_PARZIALE";
            }

            if (this.tipoGiornata == "G_PARZIALE") {
                v_value = "G_COMPLETA";
            }


            this.startDate = "";
            $("#startTime").val("");
            this.endDate = "";
            $("#endTime").val("");
            this.notes = "";


            this.vediDataInizio = false;
            this.vediOraInizio = false;
            this.vediDataFine = false;
            this.vediOraFine = false;
            this.vediNote = false;

            if (v_value == "G_COMPLETA") {

                this.vediDataInizio = true;
                this.vediDataFine = true;
                this.vediNote = true;

            }

            if (v_value == "G_PARZIALE") {

                this.vediDataInizio = true;
                this.vediOraInizio = true;
                this.vediDataFine = true;
                this.vediOraFine = true;
                this.vediNote = true;

            }

        },


        generateRandomString: function (length, characters) {

            let result = "";
            const charLength = characters.length;

            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * charLength);
                result += characters.charAt(randomIndex);
            }

            return result;


        },



        btnResetSearch: function () {


            window.curSrcText = "";
            this.textSearch = "";

            window.curStatus = "Tutti";
            this.filterStato = "Tutti";

            window.dataDa = null;
            this.dataDa = null;

            window.dataA = null;
            this.dataA = null;


            $('.selfiltri').attr('src', '/emt/img/emt-filtri-disattivi.png');


            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();
        },



        loadLogo: async function () {

            console.log("LOAD LOGO API");

            var token = window.$cookies.get("token");

            try {
                var response = await this.$api
                    .LoadLogoCompanyNew(token, "emtool")
                    .then((res) => {

                        console.log("res LoadLogoCompany: ", res);

                        this.$root.$children[0].pathLogoImage = res.data.UrlLogo;

                        // window.$cookies.set("user_models", res.data.Models, "9y");



                    });
            } catch (error) {
                console.log("err LoadLogoCompany", error);
            }

            console.log(response);



        },




        insertAndSendRequest: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal
                .fire({
                    title: "Sei sicuro?",
                    html: "Vuoi inserire la richiesta ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        that.$modal.hide('popupInsert');

                        console.log(v_token);

                        var elabTime = 0;

                        if (that.causale == 1) {

                            if (that.tipoGiornata == "G_PARZIALE") {
                                elabTime = 1;
                            }

                        }
                        else {
                            elabTime = 1;
                        }





                        var v_start_time = "";

                        if (elabTime == 1) {

                            if ($("#startTime").val() != "") {

                                var elements = $("#startTime").val().split("T");
                                var elements2 = elements[1].split(":");
                                v_start_time = elements2[0] + ":" + elements2[1];

                            }

                        }
                        else {
                            v_start_time = "09:00:00";
                        }




                        var v_end_time = "";

                        if (elabTime == 1) {

                            if ($("#endTime").val() != "") {

                                var elements3 = $("#endTime").val().split("T");
                                var elements4 = elements3[1].split(":");
                                v_end_time = elements4[0] + ":" + elements4[1];

                            }

                        }
                        else {

                            if (that.oreContratto == 8) {
                                v_end_time = "18:00:00";
                            }

                            if (that.oreContratto == 4) {
                                v_end_time = "13:00:00";
                            }

                        }




                        console.log("DATA INIZIO: ", that.startDate);
                        console.log("ORA INIZIO: ", v_start_time);
                        console.log("DATA FINE: ", that.endDate);
                        console.log("ORA FINE: ", v_end_time);

                        var parType = "";
                        if (that.causale == 1) parType = "ferie";
                        if (that.causale == 2) parType = "permesso retribuito";
                        if (that.causale == 3) parType = "permesso non retribuito";
                        if (that.causale == 6) parType = "trasferta";
                        if (that.causale == 8) parType = "permesso studio";





                        var v_key = that.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                        v_key = v_key + "|" + v_token.toString();
                        v_key = v_key + "|" + that.startDate.toString();
                        v_key = v_key + "|" + that.endDate.toString();
                        v_key = v_key + "|" + parType.toString();
                        v_key = v_key + "|" + v_start_time.toString();
                        v_key = v_key + "|" + v_end_time.toString();
                        v_key = v_key + "|" + that.causale.toString();
                        v_key = v_key + "|" + that.notes.toString();
                        v_key = v_key + "|" + that.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                        v_key = btoa(v_key);
                        v_key = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

                        console.log(v_key);

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;


                        var response = await apiholidaypermits.insHolidayNew(
                            v_key
                        ).then((res) => {

                            var v_id_inserted = res.data.IdInserted;

                            console.log("res from insHolidayNew", res);

                            var API_URL = that.getUrlDomain("sendMultiRequestHolidaysPermitsNew");

                            var v_key2 = that.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                            v_key2 = v_key2 + "|" + v_token.toString();
                            v_key2 = v_key2 + "|" + v_id_inserted.toString();
                            v_key2 = v_key2 + "|" + that.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                            v_key2 = btoa(v_key2);
                            v_key2 = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key2 + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

                            $.ajax({
                                type: "GET",
                                url: API_URL,
                                data: {
                                    "v_token": v_key2
                                },
                                success: function (resultData) {
                                    that.$root.$children[0].showProgress = false;
                                    console.log("resultData", resultData, that);

                                    that.$swal({
                                        icon: "success",
                                        text: "La richiesta è stata inserita correttamente",
                                        showConfirmButton: false,
                                        timer: 2000
                                    });

                                    that.hideShowButtons(false);

                                    window.table.ajax.reload();


                                },
                                error: function (errorData) {
                                    console.log(errorData);
                                    var msg = errorData.responseJSON.Error;

                                    that.$swal({
                                        icon: "error",
                                        text: msg,
                                        showConfirmButton: false,
                                        timer: 3000
                                    });
                                    that.$root.$children[0].showProgress = false;

                                }
                            });




                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );






                    }

                });



        },


        loadCausali: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiholidaypermits.getHolPermReq(
                v_token
            ).then((res) => {

                console.log(response);

                console.log("res from getHolPermReq", res);

                var causali = res.data.Result;

                that.causali = causali;

                that.oreContratto = res.data.OreContr;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );



        },



        insertHolidays: function () {

            this.showTime = false;

            this.causale = null;
            this.startDate = "";
            this.endDate = "";
            $("#startTime").val("");
            $("#endTime").val("");
            this.notes = "";



            this.vediTipoGiornata = false;

            this.vediDataInizio = false;
            this.vediOraInizio = false;
            this.vediDataFine = false;
            this.vediOraFine = false;

            this.vediNote = false;


            $(".contpopupinsert").show();

            this.$modal.show('popupInsert');

        },


        btnCancelInsert: function () {

            this.$modal.hide('popupInsert');

        },

        addZero: function (number) {
            return (number < 10 ? '0' : '') + number;
        },

        btnConfirmInsert: function () {

            var that = this;

            var errore = "";

            var dt_start2 = "";
            var dt_end2 = "";

            var elements = null;
            var elements2 = null;
            var str_dt_start = null;
            var elements3 = null;
            var elements4 = null;
            var str_dt_end = null;


            console.log("CAUSALE: ", that.causale);

            if (that.causale == "") {

                errore = "Specificare il tipo richiesta";

            }

            if (errore == "") {

                if (that.causale == null) {

                    errore = "Specificare il tipo richiesta";

                }

            }


            if (errore == "") {

                if (that.causale == 1) // ferie
                {


                    if (that.tipoGiornata == "G_COMPLETA") {

                        if (errore == "") {

                            if (that.startDate == "") {

                                errore = "Specificare la data inizio";

                            }

                        }



                        if (errore == "") {

                            if (that.endDate == "") {

                                errore = "Specificare la data fine";

                            }

                        }


                        if (errore == "") {



                            str_dt_start = that.startDate.toString() + " 00:00:00";

                            str_dt_end = that.endDate.toString() + " 00:00:00";

                            dt_start2 = new Date(str_dt_start).getTime();
                            dt_end2 = new Date(str_dt_end).getTime();

                            if (dt_start2 > dt_end2) {

                                errore = "Data Fine deve essere maggiore o uguale a Data Inizio";


                            }

                        }




                    }

                    if (that.tipoGiornata == "G_PARZIALE") {

                        if (errore == "") {

                            if (that.startDate == "") {

                                errore = "Specificare la data inizio";

                            }

                        }



                        if (errore == "") {

                            if ($("#startTime").val() == "") {

                                errore = "Specificare l'ora di inizio";


                            }


                        }




                        if (errore == "") {

                            if (that.endDate == "") {

                                errore = "Specificare la data fine";

                            }

                        }


                        if (errore == "") {

                            if ($("#endTime").val() == "") {

                                errore = "Specificare l'ora di fine";

                            }

                        }



                        if (errore == "") {


                            elements = $("#startTime").val().split("T");
                            elements2 = elements[1].split(":");

                            str_dt_start = that.startDate.toString() + " " + elements2[0] + ":" + elements2[1] + ":00";

                            elements3 = $("#endTime").val().split("T");
                            elements4 = elements3[1].split(":");

                            str_dt_end = that.endDate.toString() + " " + elements4[0] + ":" + elements4[1] + ":00";

                            console.log(str_dt_start);
                            console.log(str_dt_end);

                            dt_start2 = new Date(str_dt_start).getTime();
                            dt_end2 = new Date(str_dt_end).getTime();

                            if (dt_start2 > dt_end2) {

                                errore = "Data Fine deve essere maggiore o uguale a Data Inizio";


                            }

                        }

                    }



                }

                if (that.causale != 1) // non ferie
                {

                    if (errore == "") {

                        if (that.startDate == "") {

                            errore = "Specificare la data inizio";

                        }

                    }



                    if (errore == "") {

                        if ($("#startTime").val() == "") {

                            errore = "Specificare l'ora di inizio";


                        }


                    }




                    if (errore == "") {

                        if (that.endDate == "") {

                            errore = "Specificare la data fine";

                        }

                    }


                    if (errore == "") {

                        if ($("#endTime").val() == "") {

                            errore = "Specificare l'ora di fine";

                        }

                    }


                    if (errore == "") {


                        elements = $("#startTime").val().split("T");
                        elements2 = elements[1].split(":");

                        str_dt_start = that.startDate.toString() + " " + elements2[0] + ":" + elements2[1] + ":00";

                        elements3 = $("#endTime").val().split("T");
                        elements4 = elements3[1].split(":");

                        str_dt_end = that.endDate.toString() + " " + elements4[0] + ":" + elements4[1] + ":00";


                        dt_start2 = new Date(str_dt_start).getTime();
                        dt_end2 = new Date(str_dt_end).getTime();

                        if (dt_start2 > dt_end2) {

                            errore = "Data Fine deve essere maggiore o uguale a Data Inizio";


                        }

                    }


                }

            }






            if (errore == "") {


                console.log("ok");


                //that.insertAndSendRequest();

                that.checkHolidays();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }


        },


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.idRichestaFromMail = "";

            window.$cookies.set("sel_filter_idrichiesta_storico_ferie", "", "9y");


            window.curCompany = azienda.companyid;

            window.$cookies.set("sel_filter_azienda_storico_ferie", azienda.companyid, "9y");

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },

        syncAziende: function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            setTimeout(() => {

                that.$root.$children[0].showProgress = false;

                if (window.$cookies.get("companyUser") != "null") {
                    that.aziende = JSON.parse(window.$cookies.get("companyUser"));

                }
                else {

                    that.aziende = [];

                }


            }, 300);


        },


        syncAziendeOld: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getFilterAziende(
                v_token,
                "emt"
            ).then((res) => {

                that.showSpinner = false;
                that.$root.$children[0].showProgress = false;

                console.log("res from getFilterAziende", res);

                try {

                    this.aziende = res.data.company;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        changeStatus: async function (status) {

            console.log(status);

            this.sheetStatus = false;

            window.idRichestaFromMail = "";

            window.$cookies.set("sel_filter_idrichiesta_storico_ferie", "", "9y");


            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.curStatus = status;

            this.hideShowButtons(false);

            window.table.ajax.reload();


        },


        checkHolidays: async function () {

            var that = this;

            var response;

            try {

                //  that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apiholidaypermits.checkRequestHolidayRis(
                    that.startDate,
                    that.endDate
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from checkRequestHolidayRis", res);

                    if (res.data.Result == "OK") {

                        console.log("ok");

                        that.insertAndSendRequest();


                    }
                    else {
                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });
                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile eliminare questa richiesta");
                    console.log("response", response);

                }

                );


            } catch (error) {
                that.$root.$children[0].showProgress = false;
                console.log(error);

            }


        },


        deleteRow: async function () {
            var token = window.$cookies.get("token");
            var that = this;

            this.$swal
                .fire({
                    title: "Sei sicuro?",
                    html: "Vuoi eliminare gli elementi selezionati ? ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);
                        console.log("VAL TOKEN:", token);



                        var response;

                        try {

                            //  that.showSpinner = true;
                            that.$root.$children[0].showProgress = true;

                            response = await apiholidaypermits.deleteHolidaysPermitsNew(
                                ids,
                                token
                            ).then((res) => {

                                that.$root.$children[0].showProgress = false;
                                console.log("res from deleteHolidaysPermitsNew", res);

                                if (res.data.Result == "OK") {

                                    that.$swal({
                                        icon: "success",
                                        text: "La tua richiesta è stata rimossa correttamente",
                                        showConfirmButton: false,
                                        timer: 3000
                                    });

                                    that.hideShowButtons(false);

                                    window.table.ajax.reload();
                                }
                                else {

                                    that.$swal({
                                        icon: "error",
                                        text: res.data.Result,
                                        showConfirmButton: false,
                                        timer: 3000
                                    });

                                }



                            }).catch(err => {
                                that.$root.$children[0].showProgress = false;
                                console.log(err);
                                var msg = err.response.data.Error;

                                that.$swal({
                                    icon: "error",
                                    text: msg,
                                    showConfirmButton: false,
                                    timer: 8000
                                });
                                console.log("ERrori", "Non è stato possibile eliminare questa richiesta");
                                console.log("response", response);

                            }

                            );


                        } catch (error) {
                            that.$root.$children[0].showProgress = false;
                            console.log(error);

                        }
                        console.log(response);


                    }
                });



        },







        changeStatusAdmin: async function (status) {

            //  this.currFilterStatus = status;

            console.log(status);


            //START
            var token = window.$cookies.get("token");

            /*   var username = window.$cookies.get("username");
              var token_system = window.$cookies.get("token_system"); */
            //var API_URL = "https://services.ebadge.it/public/api/sendReqHoliday";

            //var API_URL = "https://app.emtool.eu/api/acceptRejectHolidaysPermits";

            var API_URL = this.getUrlDomainApi("acceptRejectMultiHolidaysPermits");

            //var id_richiesta = window.id_par_sel;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);


            var operation = "";
            if (status == "Refused") operation = 'reject';
            if (status == "to Approve") operation = 'to Approve';
            if (status == "Approved") operation = 'accept';
            if (status == "Open") operation = 'open';

            console.log("STATUS: ", status);
            console.log("TOKEN: ", token);
            console.log("API_URL: ", API_URL);
            console.log("OPERATION: ", operation);
            console.log("ID_RICHIESTA: ", ids);

            var that = this;
            that.$root.$children[0].showProgress = true;

            /* eslint-disable no-unreachable */
            /*    return; */
            $.ajax({
                type: "POST",
                url: API_URL,
                data: {
                    "v_user": token,
                    'id_richiesta': ids,
                    'operation': operation,
                },
                success: function (resultData) {

                    that.$root.$children[0].showProgress = false;

                    console.log("resultData", resultData, that);

                    that.$swal({
                        icon: "success",
                        text: "La richiesta è stata inviata correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });
                    that.sheetChangeStatus = false;

                    that.hideShowButtons(false);

                    window.table.ajax.reload();

                },
                error: function (errorData) {

                    that.sheetChangeStatus = false;

                    console.log(errorData);
                    var msg = errorData.responseJSON.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    that.showSpinner = false;

                }
            });
            //END

        },



        sendInApprovation: function () {


            var that = this;
            var token = window.$cookies.get("token");

            var API_URL = this.getUrlDomain("sendMultiRequestHolidaysPermits");

            //var id_richiesta = window.id_par_sel;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);




            console.log("TOKEN: ", token);
            console.log("API_URL: ", API_URL);
            console.log("id_richiesta: ", ids);

            that.$root.$children[0].showProgress = true;

            $.ajax({
                type: "GET",
                url: API_URL,
                data: {
                    "v_user": token,
                    'id_richiesta': ids,

                },
                success: function (resultData) {
                    that.$root.$children[0].showProgress = false;
                    console.log("resultData", resultData, that);

                    that.$swal({
                        icon: "success",
                        text: "La richiesta è stata inviata correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    that.hideShowButtons(false);

                    window.table.ajax.reload();


                },
                error: function (errorData) {
                    console.log(errorData);
                    var msg = errorData.responseJSON.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    that.$root.$children[0].showProgress = false;

                }
            });


        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/public/api/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/api/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            var pointerVue = this;

            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");
            var is_from_ts_user = window.$cookies.get("is_from_ts_user");

            var filtri = `<img src='/emt/img/refresh-emt.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/emt/img/emt-filtri-disattivi.png' style='border:0!important;' class='selfiltri' title='Filtri' />`;

            if (is_from_ts_user == 1) {

                filtri = `<img src='/emt/img/refresh-emt.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/emt/img/emt-filtri-disattivi.png' style='border:0!important;' class='selfiltri' title='Filtri' /><img src='/emt/img/timesheet3.png' style='border:0!important;' class='seltimesheet' title='Timesheet' />`;


            }


            if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                filtri = "<img src='/emt/img/refresh-emt.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/emt/img/aziende-emt.png' style='border:0!important;' class='selazienda' title='Aziende' /><img src='/emt/img/emt-filtri-attivi.png' style='border:0!important;' class='selfiltri' title='Filtri' />";

            }

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                    window.curCompany = window.$cookies.get("sel_filter_azienda_storico_ferie");


                }



                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    // {
                    //     data: 'DT_RowIndex',
                    //     orderable: true,
                    //     searchable: false,
                    //     name: '',
                    //     width: '1%',
                    // },

                    {
                        data: 'row_id',
                        orderable: true,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'data_inizio',
                        orderable: true,
                        name: 'data_inizio',
                        width: '3%'
                    },

                    {
                        data: 'ora_inizio',
                        orderable: true,
                        name: 'ora_inizio',
                        width: '4%'
                    },

                    {
                        data: 'data_fine',
                        orderable: true,
                        name: 'data_fine',
                        width: '4%'
                    },

                    {
                        data: 'ora_fine',
                        orderable: true,
                        name: 'ora_fine',
                        width: '4%'
                    },

                    {
                        data: 'ore_totali',
                        orderable: true,
                        name: 'ore_totali',
                        width: '4%'
                    },

                    {
                        data: 'risorsa',
                        orderable: true,
                        name: 'risorsa',
                        width: '10%'
                    },

                    {
                        data: 'tipo',
                        orderable: true,
                        name: 'tipo',
                        width: '10%'
                    },


                    {
                        data: 'stato',
                        orderable: true,
                        name: 'stato',
                        width: '10%'
                    },

                    {
                        data: 'note',
                        orderable: true,
                        name: 'note',
                        width: '10%'
                    },


                    // {
                    //     data: 'created_at',
                    //     name: 'created_at',
                    //     width: '3%',

                    //     render: function (data, row) {
                    //         console.log(data, row);

                    //         var date = "";
                    //         try {

                    //             date = data.substring(0, 10);
                    //         } catch (error) {
                    //             console.log(error);
                    //         }

                    //         var hour = "";
                    //         try {

                    //             hour = data.split(" ")[1].slice(0, 5);
                    //         } catch (error) {
                    //             console.log(error);
                    //         }

                    //         var html = "<div style='display:flex'><div style='visibility:hidden;font-size:0px'>" + data + "</div><div><div class='font-row-grid-datetime'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div></div></div>";

                    //         return html;
                    //     }

                    // },

                    {
                        data: 'created_at',
                        name: 'created_at',
                        width: '3%',
                    },

                ];


                $(".spin").show();




                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("EmtGetHolidayApi") + '?v_user=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;

                            valori.curCompany = window.curCompany;
                            valori.curStatus = window.curStatus;

                            valori.dataDa = window.dataDa;
                            valori.dataA = window.dataA;

                            valori.curSrcText = window.curSrcText;

                            valori.idRichestaFromMail = window.idRichestaFromMail;


                            window.valori = valori;
                            return valori;


                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);






                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        setTimeout(() => {

                            $("th:first").removeClass("sorting_asc");


                        }, 300);



                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {

                            $(".spin").show();

                            setTimeout(() => {

                                $(".spin").hide();

                            }, 500);

                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("EmtGetHolidayApi") + '?v_user=' + v_token,
                                data: window.valori,
                                success: function (data) {

                                    //  $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });



                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");


                $("#myTable_filter").find("label").remove();


                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Storico Ferie</div>
                       ` + filtri + `

                </div>
            `);

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });

                // $('body').on('click', '.btn-confirm-search', function () {

                //     console.log("ok");

                //     window.curSrcText = pointerVue.textSearch;

                //     pointerVue.$modal.hide('popupSearch');

                //     pointerVue.showSpinner = true;
                //     pointerVue.$root.$children[0].showProgress = true;


                //     window.table.ajax.reload();


                // });

                // $('body').on('click', '.btn-cancel-search', function () {

                //     console.log("ok");

                //     pointerVue.$modal.hide('popupSearch');

                // });


                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $(".selectstatusall").on("ionChange", function (opt) {
                    console.log(opt);
                    $(".spin").show();
                    window.curStatus = this.value;



                    window.table.ajax.reload();

                });

                $('body').on('click', '.cont_history_holiday_emt th', function () {


                    console.log("TH-ACTIVE: ", $(this));


                    $(this).removeClass("sorting");



                });


                $('body').on('click', '.cont_history_holiday_emt tr', function () {

                    var row_data = window.table.row(this).data();

                    if (row_data != null) {


                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        console.log("ROM IS ACTIVE: ", set_cls_active);



                        console.log("THIS: ", this);

                        var hasClassActive = $(this).hasClass("active-row");

                        if (hasClassActive) {

                            console.log("ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", false);

                            $(this).removeClass("active-row");

                        }

                        if (!hasClassActive) {

                            console.log("NOT ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", true);

                            $(this).addClass("active-row");



                        }

                        window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        if (window.id_par_sel == undefined) {
                            window.id_par_sel = id_sel;
                        }

                        console.log("ID SELECTED: ", window.id_par_sel);

                        pointerVue.hideShowButtonsSelRow();


                    }



                });


                window.parentiAddedEvents = true;

            });

        },


        hideShowButtonsSelRow: function () {

            var tot = 0;
            var tot_open_status = 0;
            var rich_mod = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;


                    var val_status_id = $(this).attr("status-id");
                    rich_mod = $(this).attr("rich-mod");

                    if (val_status_id == "Open") // stato aperto
                    {

                        tot_open_status = tot_open_status + 1;

                    }



                }

            });

            console.log("TOT SEL: ", tot);
            console.log("TOT SEL OPEN STATUS: ", tot_open_status);


            setTimeout(() => {


                var a = window.$cookies.get("a");
                var b = window.$cookies.get("b");

                var pulsantis = [];

                if (tot == 0) {

                    if (a == 'N' && b == 'N') { // dipendente

                        pulsantis.push({
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/calendar-emt.png"),
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        });

                    }


                }


                if (tot == 1) {

                    if (a == 'N' && b == 'N') { // dipendente

                        if (rich_mod == 1) {

                            pulsantis.push(

                                {
                                    text: "",
                                    icon: "mdi-delete",
                                    image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                                    link: "/delete",
                                    id: "btn_edit",
                                    disabled: false,
                                    title: "Modifica",
                                    width: 30
                                }


                            );

                            pulsantis.push(

                                {
                                    text: "",
                                    icon: "mdi-delete",
                                    image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                                    link: "/delete",
                                    id: "btn_delete",
                                    disabled: false,
                                    title: "Elimina",
                                    width: 30
                                }


                            );

                        }



                    }


                }





                if (tot > 0) {

                    if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {

                        pulsantis.push(

                            {
                                text: "",
                                icon: "mdi-send-clock-outline",
                                link: "/btn_changestatusadmin",
                                id: "btn_changestatusadmin",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/cambio-stato-emt.png",
                                title: "Aggiorna Stato",
                                width: 30

                            }

                        );

                    }




                    if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {

                        pulsantis.push(

                            {
                                text: "",
                                icon: "mdi-delete",
                                image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                                link: "/delete",
                                id: "btn_delete",
                                disabled: false,
                                title: "Elimina",
                                width: 30
                            }


                        );

                    }



                }


                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },






        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var a = window.$cookies.get("a");
                var b = window.$cookies.get("b");

                var pulsantis = [];

                if (v_enable == false) {

                    if (a == "S") {
                        pulsantis.push({
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/calendar-emt.png"),
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        });

                    }



                    if (a == 'N' && b == 'N') { // dipendente

                        pulsantis.push({
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/calendar-emt.png"),
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        });

                    }





                }


                if (v_enable == true) {

                    if (a == "S") {
                        pulsantis.push({
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/calendar-emt.png"),
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        });

                    }

                    if (a == 'N' && b == 'N') { // dipendente

                        if (window.id_status == "Open") {

                            pulsantis.push({
                                text: "sendinapprovation",
                                icon: "mdi-send-clock-outline",
                                link: "/delete",
                                id: "btn_send_approvation",
                                disabled: false,
                            });

                        }

                    }

                    if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {

                        pulsantis.push(

                            {
                                text: "",
                                icon: "mdi-send-clock-outline",
                                link: "/btn_changestatusadmin",
                                id: "btn_changestatusadmin",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/cambio-stato-emt.png",
                                title: "Aggiorna Stato",
                                width: 30

                            }

                        );

                        pulsantis.push(

                            {
                                text: "",
                                icon: "mdi-delete",
                                image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                                link: "/delete",
                                id: "btn_delete",
                                disabled: false,
                                title: "Elimina",
                                width: 30
                            }



                        );

                    }

                    // pulsantis.push(

                    //     {
                    //         text: "",
                    //         icon: "mdi-delete",
                    //         image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                    //         link: "/delete",
                    //         id: "btn_delete",
                    //         disabled: false,
                    //         title: "Elimina",
                    //         width: 30
                    //     }



                    // );



                    // var show_back = 0;
                    if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {

                        // if (v_system == "null") {

                        //     show_back = 0;

                        // }
                        // else {

                        //     show_back = 1;

                        // }

                        show_back = 0;


                    }
                    else {

                        show_back = 1;

                    }


                    // if (show_back == 1) {

                    //     pulsantis.push(

                    //         {
                    //             text: "Indietro",
                    //             icon: "mdi-arrow-left",
                    //             image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    //             link: "/confOrariChoice",
                    //             id: "btn_back",
                    //             disabled: false,
                    //             title: "Indietro",
                    //             width: 35
                    //         }




                    //     );

                    // }




                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/calendar-emt.png"),
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },


    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.clTextCellRed {
    color: red !important;
}

.clTextCellRedBold {
    color: red !important;
    font-weight: bold !important;
}


.styleInput .v-label {
    font-size: 10px !important;
}

.cont_history_holiday_emt .selfiltri {
    max-width: 27px;
    min-height: 27px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 27px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}



.font-row-grid-datetime {
    font-size: 10px !important;
    padding: 5px !important;
    width: 100px !important;
}

.cl-date {
    font-size: 10px;
    color: gray;
}


.cont_history_holiday_emt .selaggiorna {
    max-width: 22px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}



.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}


ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_history_holiday_emt #myTable {
    margin-right: 3px;
}

.cont_history_holiday_emt table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_history_holiday_emt table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    /* padding-bottom: 9px !important; */
}

.cont_history_holiday_emt th {
    padding-left: 5px !important;
}

.cont_history_holiday_emt ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_history_holiday_emt .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}


.cont_history_holiday_emt #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #f5cf2e;
}

.cont_history_holiday_emt div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_history_holiday_emt #myTable_wrapper {
    padding-top: 0px;
}

.cont_history_holiday_emt .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 0vw
}

.cont_history_holiday_emt .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_history_holiday_emt .selectstatusall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/emt/img/filtro-stato-emt.png);
    text-indent: -119px;
    background-size: 24px !important;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -5px !important;

}

.cont_history_holiday_emt .v-input .v-label {

    font-size: 10px !important;
}

ion-select {
    font-size: 10px !important;
}


.cont_history_holiday_emt .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
    color: black !important;
}

.closeStatusSheet {
    position: absolute;
    right: 0;
}

.selstato {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}


.cont_history_holiday_emt #myTable {
    max-width: 95vw !important;
    margin-right: 0px;
    min-width: 95vw !important;
    position: relative;
    width: 95vw !important;

}

.cont_history_holiday_emt .selazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.itemAzienda {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.cont_history_holiday_emt .clDataDa {
    font-weight: bold;
    transform: translate(6px, -12px);
}

.cont_history_holiday_emt .clValueDataDa {
    font-weight: bold;
    transform: translate(11px, -11px);
}

.cont_history_holiday_emt .seldata {
    max-width: 27px;
    min-height: 27px;
    margin-left: 11px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 27px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: --2px;
}

.cont_history_holiday_emt .seltimesheet {
    max-width: 27px;
    min-height: 27px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 27px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}



.cont_history_holiday_emt .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_history_holiday_emt .checkall {
    width: 13px;
    height: 13px;
}


.cont_history_holiday_emt .cl-title-center {
    text-align: center;
}

.cont_history_holiday_emt .cl-text-center {
    text-align: center;
}

.cont_history_holiday_emt .cl-data-inizio {
    width: 60px !important;
}

.cont_history_holiday_emt .cl-data-fine {
    width: 60px !important;
}

.cont_history_holiday_emt input[type='search'] {
    visibility: hidden;
}

.cont_history_holiday_emt .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_history_holiday_emt .val_status {
    width: 10px !important;
}



@media screen and (max-width: 960px) {


    .cont_history_holiday_emt .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_history_holiday_emt #myTable {
        /* margin-top:54px; */
    }

    .cont_history_holiday_emt #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_history_holiday_emt #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;

    }

    .cont_history_holiday_emt #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_history_holiday_emt .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_history_holiday_emt #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
    }

    .cont_history_holiday_emt .cl_num {
        margin-left: -2px !important;
    }

    .cl_n_matricola {
        display: inline-flex;
    }

    .cl_desc_project {
        display: inline-flex;
    }

    .cl_costo_giornaliero {
        display: inline-flex;
    }

    .cl_diaria {
        display: inline-flex;
    }

    .cl_ticket {
        display: inline-flex;
    }

    .cl_piano_orario {
        display: inline-flex;
    }

    .cl_ebadge {
        display: inline-flex;
    }

    .cl_totale_ferie {
        display: inline-flex;
    }

    .cont_history_holiday_emt .cl_checkall {
        padding-left: 1px;
        padding-top: 2px;
    }

    .cont_history_holiday_emt .cl-data-inizio {
        width: 58px !important;
    }

    .cont_history_holiday_emt .cl-text-center {
        text-align: center;
        width: 39px !important;
    }

    .cont_history_holiday_emt .cl-data-fine {
        width: 58px !important;
    }

    .clHeaderDataInizio {
        width: 50px !important;
    }

    .clHeaderOraInizio {
        width: 50px !important;
    }

    .clHeaderDataFine {
        width: 50px !important;
    }

    .clHeaderOraFine {
        width: 50px !important;
    }

    .clHeaderInseritoIl {
        width: 54px !important;
    }

    .clColRisorsa {
        width: 150px !important;
    }

    .clColTipo {
        width: 150px !important;
    }

    .clColNote {
        width: 150px !important;
    }


}
</style>
