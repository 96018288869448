<template>
    <div id="timesheetCalendarRisVerTs">

        <div class="cl_cont_azi_res_ts">



            <div class="cont_names">

                <div id="res_full_name" class="name_res"></div>

                <div id='userRed' title='Aperto'>
                    <img src="@/assets/user_red.png"
                        style='border:0!important;width:30px;float:right;margin-top:-13px;margin-right:16px' />
                </div>

                <div id='userYellow'>
                    <img src="@/assets/user_yellow.png"
                        style='border:0!important;width:30px;float:right;margin-top:-13px;margin-right:16px' />
                </div>

                <div id='userGreen'>
                    <img src="@/assets/user_green.png"
                        style='border:0!important;width:30px;float:right;margin-top:-13px;margin-right:16px' />
                </div>

                <div id="full_name_res" class="clResName"></div>


            </div>


        </div>


        <div class="totals">
            <div class="grouptotal">
                <span class="titletotals" title="Ore Ordinarie">
                    <v-icon title="Ore Ordinarie">mdi-account-hard-hat</v-icon>
                    <span> {{ totPresenzaMese }} H</span>
                </span>
                <span class="detailtotals">{{ totPresenzaMese }} </span>
            </div>
            <div class="grouptotal">
                <span class="titletotals" title="Ore Assenza">
                    <v-icon title="Ore Assenza">mdi-account-minus</v-icon>
                    <span> {{ totAssenzaMese }} H</span>
                </span>
                <span class="detailtotals">{{ totAssenzaMese }} </span>
            </div>

            <div class="grouptotal">
                <span class="titletotals" title="Ore Straordinarie">
                    <v-icon title="Ore Straordinario">mdi-account-plus</v-icon>
                    <span> {{ totStraordinariMese }} H</span>
                </span>
                <span class="detailtotals">{{ totStraordinariMese }}</span>
            </div>

            <div class="grouptotal" v-if="viewRep == 'S'">
                <span class="titletotals" title="Ore Reperibilità">
                    <v-icon title="Ore Reperibilità">mdi-account-star</v-icon>
                    <span> {{ totReperibilitaMese }} H</span>
                </span>
                <span class="detailtotals">{{ totReperibilitaMese }}</span>
            </div>

            <div class="grouptotal" v-if="viewInterv == 'S'">
                <span class="titletotals" title="Ore Intervento">
                    <v-icon title="Ore Intervento">mdi-account-star</v-icon>
                    <span> {{ totInterventoMese }} H</span>
                </span>
                <span class="detailtotals">{{ totInterventoMese }}</span>
            </div>
        </div>

        <v-layout d-flex align-center justify-center style="min-height: auto;margin-top:66px;">
            <v-card-actions>
                <div class="contcalendar">

                    <datepicker ref="defCalendar" @dayclick="onDayClick" @update:from-page="pageChange"
                        format="DD-MM-YYYY" v-model="date" is-expanded :first-day-of-week="2" locale="it" color="yellow"
                        is-light :attributes="attributes" :disabled-dates="disabledDates" :min-date="dateStartEdit"
                        :max-date="dateEndEdit" />

                </div>
            </v-card-actions>
        </v-layout>

        <rise-loader v-if="showSpinner" :size="'20px'" :color="'#ffd212'" />



        <div class="mx-auto nocolor contQuanties" color="transparent" style="transform: translate(0px, -4px);}">



            <v-list class="transparent containerFields">
                <v-list-item v-for="item in forecast" :key="item.day" :id="item.day"
                    style="min-height: 40px!important;max-height: 40px!important;">


                    <div style="display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
">

                        <div style="width: 37px;"
                            v-if="(item.day != 'Reperibilità') || (item.day == 'Reperibilità' && viewRep == 'S') || (item.day == 'Ordinarie' && IsPartitaIva == 'S') || (item.day == 'Assenza' && IsPartitaIva == 'S')">
                            <v-icon>{{ item.icon }}</v-icon>
                        </div>

                        <div style="width: 59px;"
                            v-if="(item.day != 'Reperibilità') || (item.day == 'Reperibilità' && viewRep == 'S') || (item.day == 'Ordinarie' && IsPartitaIva == 'S') || (item.day == 'Assenza' && IsPartitaIva == 'S')">
                            {{ item.day }}
                        </div>

                        <div class="conSelect"
                            v-if="item.day == 'Straordinari' || (item.day == 'Ordinarie' && IsPartitaIva == 'S') || (item.day == 'Assenza' && IsPartitaIva == 'S')">

                            <v-text-field readonly type="text" :min="0" :max="10" class="selectHour2"
                                @change="updateSumDay" :items="itemsQuantity" label="" dense
                                :value="item.quantity + ' H'" :ref="'combo' + item.day"
                                :id="'input' + item.day"></v-text-field>

                            <div class="addbuttons">

                                <div class="minusContainer">
                                    <img src='/emt/img/image_minus.png' class="minus" :data-id="item.day"
                                        v-on:click="sumHours" />
                                </div>

                                <div>
                                    <img src='/emt/img/image_plus.png' class="plus" :data-id="item.day"
                                        v-on:click="sumHours" />
                                </div>

                            </div>

                        </div>

                        <v-select v-if="item.day == 'Causale' && IsPartitaIva == 'S'" class="selectHour2"
                            :items="causali" item-value="idtypeactivity" item-text="description" label="" dense
                            v-model="item.quantity" @change="updateSumDay"
                            style="width:180px!important;min-width: 180px!important;"></v-select>


                        <div class="conSelect" style="margin-left: 15px;" v-if="item.day == 'Descrizione'">

                            <v-text-field label="" dense :value="item.description"
                                style="min-width: 165px!important;width: 165px!important;" maxlength="28"
                                @input="onChangeDescription" :id="'input' + item.day"
                                v-model="valDescription"></v-text-field>

                        </div>

                        <div class="conSelect" v-if="item.day == 'Reperibilità' && viewRep == 'S'">

                            <v-text-field readonly type="text" :min="0" :max="10" style="margin-top: 18px!important;"
                                class="selectHour2" @change="updateSumDay" :items="itemsQuantity" label="" dense
                                :value="item.quantity + ' H'" :ref="'combo' + item.day"
                                :id="'input' + item.day"></v-text-field>

                            <div class="addbuttons">

                                <div class="minusContainer">
                                    <img src='/emt/img/image_minus.png' class="minus" :data-id="item.day"
                                        v-on:click="sumHours" />
                                </div>

                                <div>
                                    <img src='/emt/img/image_plus.png' class="plus" :data-id="item.day"
                                        v-on:click="sumHours" />
                                </div>

                            </div>

                            <div style="transform: translateY(-9px);">
                                <v-switch label="" false-value="N" true-value="S" v-model="viewInterv"
                                    @change="onChangeViewInterv"></v-switch>
                            </div>


                        </div>

                        <div class="conSelect" v-if="item.day == 'Intervento'">

                            <v-text-field readonly type="text" :min="0" :max="10"
                                v-if="item.day != 'Causale' && item.day != 'Totale'" class="selectHour2"
                                @change="updateSumDay" :items="itemsQuantity" label="" dense
                                :value="item.quantity + ' H'" :ref="'combo' + item.day"
                                :id="'input' + item.day"></v-text-field>


                            <div class="addbuttons">

                                <div class="minusContainer">
                                    <img src='/emt/img/image_minus.png' class="minus" :data-id="item.day"
                                        v-on:click="sumHours" />
                                </div>

                                <div>
                                    <img src='/emt/img/image_plus.png' class="plus" :data-id="item.day"
                                        v-on:click="sumHours" />
                                </div>

                            </div>

                        </div>

                        <div class="conSelect" v-if="item.day == 'Dalle'">

                            <v-text-field readonly type="text" :min="0" :max="10" class="selectHour2" :disabled="false"
                                label="" dense :value="item.timeStart" :id="'input' + item.day"></v-text-field>


                            <div class="addbuttons">

                                <div class="minusContainer">
                                    <img src='/emt/img/image_minus.png' class="minus" :data-id="item.day"
                                        v-on:click="sumHours2" />
                                </div>

                                <div>
                                    <img src='/emt/img/image_plus.png' class="plus" :data-id="item.day"
                                        v-on:click="sumHours2" />
                                </div>

                            </div>

                        </div>

                        <div class="conSelect" v-if="item.day == 'Alle'">

                            <v-text-field readonly type="text" :min="0" :max="10" class="selectHour2" :disabled="false"
                                label="" dense :value="item.timeEnd" :id="'input' + item.day"></v-text-field>



                            <div class="addbuttons">

                                <div class="minusContainer">
                                    <img src='/emt/img/image_minus.png' class="minus" :data-id="item.day"
                                        v-on:click="sumHours2" />
                                </div>

                                <div>
                                    <img src='/emt/img/image_plus.png' class="plus" :data-id="item.day"
                                        v-on:click="sumHours2" />
                                </div>

                            </div>

                        </div>

                        <div class="conSelect" v-if="item.day == 'Progetto'">

                            <div class="clViewProjects" title="Vedi ore progetti">
                                <v-icon style="font-size:20px!important"
                                    v-on:click="openPopupOreProgetti">mdi-table</v-icon>
                            </div>

                        </div>

                    </div>


                </v-list-item>
            </v-list>


        </div>


        <div class="contpopuppprogetti">

            <template>
                <modal name="popupProgetti" :clickToClose="false" :width="300" :height="269">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Ore Progetti

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px;display: flex;">

                            <div>Giorno:</div>
                            <div style="margin-left: 10px;">{{ dataGiornataProgetti }}</div>
                            <div style="margin-left: 144px;">Ore Totali:</div>
                            <div style="margin-left: 10px;">{{ oreTotaliGiornataProgetti }}</div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div v-for="item in valuesProjects"
                                style="display: flex;height: 40px;margin-left: 10px;align-items: center;justify-items: center;"
                                :key="item.projectid" :id="item.projectid">

                                <div style="width:60px">
                                    {{ item.codice }}
                                </div>

                                <div class="conSelect" style="margin-top:11px;margin-left: 11px;">

                                    <v-text-field readonly type="text" :min="0" :max="10" class="selectHour2" label=""
                                        dense :value="item.ore + ' H'" :ref="'combo' + item.projectid"
                                        :id="'input' + item.projectid"></v-text-field>

                                    <div class="addbuttons" style="margin-left: 28px;">

                                        <div class="minusContainer">
                                            <img src='/emt/img/image_minus.png' class="minus" :data-id="item.projectid"
                                                v-on:click="sumHoursProject" />
                                        </div>

                                        <div>
                                            <img src='/emt/img/image_plus.png' class="plus" :data-id="item.projectid"
                                                v-on:click="sumHoursProject" />
                                        </div>

                                    </div>

                                </div>


                            </div>


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-ore-progetti" contain
                                    src="@/assets/btn_cancel3_emt.png" max-width="32" max-height="32"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnCancelOreProgetti" />

                                <v-img alt="" class="btn-confirm-ore-progetti" contain
                                    src="@/assets/btn_confirm3_emt.png" max-width="32" max-height="32"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmOreProgetti" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupfilterpdfamm" style="display:none">

            <template>
                <modal name="popupFilterPdfAmm" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Seleziona Progetto


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div id="progetto" style="cursor:pointer">
                                <ion-item id="itemmFilterProgetto"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label
                                        style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                        position="floating">Progetto </ion-label>

                                    <ion-select id="selprogetto" name="selprogetto" class='selprogetto'
                                        cancel-Text="Chiudi" done-Text="" :value="selProgettoFilter"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in progetti" :key="item.projectid"
                                            :value='item.projectid'> {{ item.codice }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelFilterPdfAmm" />


                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmFilterPdfAmm" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupfilterpdfcompleto" style="display:none">

            <template>
                <modal name="popupFilterPdfCompleto" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Seleziona Progetto


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div id="progetto" style="cursor:pointer">
                                <ion-item id="itemmFilterProgetto"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label
                                        style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                        position="floating">Progetto </ion-label>

                                    <ion-select id="selprogetto" name="selprogetto" class='selprogetto'
                                        cancel-Text="Chiudi" done-Text="" :value="selProgettoFilter"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in progetti" :key="item.projectid"
                                            :value='item.projectid'> {{ item.codice }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelFilterPdfCompleto" />


                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmFilterPdfCompleto" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </div>
</template>

<script>
var env = process.env.NODE_ENV;
if (env != "development") console.log = function () { };

import _ from 'lodash';


import {
    bus
} from "../main";

import apitimesheet from "../utils/timesheet/apitimesheet";
import router from ".././router";

import datepicker from "v-calendar/lib/components/date-picker.umd";
import RiseLoader from "vue-spinner/src/RiseLoader.vue";


export default {

    watch: {
        date: {
            handler: function () {
                // console.log(this.date)
            },
            deep: true,
        },
    },

    updated() {

    },

    mounted: async function () {

        /*eslint-disable no-undef*/




        this.$root.$children[0].checkLogout();

        var pointerVue = this;

        window.numEventOrdinario = 0;
        window.numEventASaveAndOpen = 0;
        window.numEventASaveAndClose = 0;
        window.numEventASave = 0;
        window.numEventPdfAmm = 0;
        window.numEventPdfFull = 0;

        window.numEventDipAvanti = 0;
        window.numEventDipIndietro = 0;

        window.curRowDip = 0;

        window.curProgetto = "";

        this.dipendenti = this.$root.$children[0].dipendenti;
        this.aziende = this.$root.$children[0].aziende;

        console.log("AZI 2: ", this.aziende);

        this.isDipEbadge = parseInt(window.$cookies.get("is_ebadge"));


        $("#full_name_res").html(window.$cookies.get("sel_cognome_nome_ver_ts"));


        bus.$off("bottomIconClickEvent");

        $("#Ordinarie").hide();
        $("#Assenza").hide();
        $("#Straordinari").hide();
        $("#Causale").hide();
        $("#Descrizione").hide();
        $("#Reperibilità").hide();
        $("#Intervento").hide();
        $("#Dalle").hide();
        $("#Alle").hide();
        $("#Progetto").hide();
        $("#Totale").hide();


        setTimeout(() => {

            this.getIdProgettoAssociato();

            this.getIdProjectDefault();

        }, 100);



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {



                case "btn_refr_tms":
                    this.resetDay();
                    this.syncMonth();
                    break;


                case "btn_back":

                    router.push({
                        path: "/verificaTimesheet"
                    });


                    break;

                case "btn_save_open":

                    this.validateSaveAndOpen();

                    break;

                case "btn_save_close":

                    this.validateSaveAndClose();

                    break;

                case "btn_pdf_amministrativo":

                    if (this.countProjAssoc < 2) {

                        if (this.enableIntervRis == "N") {

                            this.pdfAmministrativoWithRep();

                        }

                        if (this.enableIntervRis == "S") {

                            console.log("ok");

                            this.pdfAmministrativoWithRepInterv();


                        }


                    }

                    if (this.countProjAssoc > 1) {

                        this.openPopupFilterPdfAmm();



                    }

                    break;

                case "btn_pdf_completo":

                    if (this.countProjAssoc < 2) {

                        if (this.enableIntervRis == "N") {

                            this.pdfCompletoWithRep();

                        }

                        if (this.enableIntervRis == "S") {

                            console.log("ok");

                            this.pdfCompletoWithRepInterv();


                        }


                    }

                    if (this.countProjAssoc > 1) {

                        this.openPopupFilterPdfCompleto();



                    }

                    break;



                default:
                    break;
            }
        });



        $(document).ready(function () {

            $('body').on('ionChange', '.selprogetto', function () {

                pointerVue.selProgettoFilter = this.value;


            });

            $('body').on('click', '.selprogetti', function () {


                pointerVue.sheetProgetti = true;


            });



            $('body').on('click', '#btn_ferie_permessi', function () {


                pointerVue.getTotalsHolidaysPermits();

            });



            $('body').on('click', '.deleteRow', function () {

                pointerVue.deleteRowTs($(this).attr("v_login"), $(this).attr("v_day"), $(this).attr("v_month"), $(this).attr("v_year"));


            });


        });



        setTimeout(() => {

            pointerVue.getProgetti();

        }, 1000);








    },

    mountedOLD() {
        //console.log ( "CAlendar" ,this.$refs.defCalendar );
        //var pointerTHis = this ;

        $("body").on("click", ".v-menu__content", function () {

            //$(this).hide();
            //  $(".selectHour").trigger("blur");
            //      window.pointerThis = pointerTHis ;

            //this.$refs.comboOrdinari.click();
            //      console.log ( "all refs" , window.refs );
            //$(".v-select--is-menu-active").find(".v-icon").trigger("click");

            /* $(".v-select--is-menu-active").find(".v-select__slot").trigger("blur");

            setTimeout(() => {
                $("body").trigger("click");

            }, 100); */

            //$(".v-select__slot").trigger("click");
            //window.refs.comboOrdinari.hide();

            //   this.$refs.combo.blur();

        });

    },
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];
        this.$root.$children[0].buttonsTop = [];

        //to manage if admin
        var namesurname = window.$cookies.get("namesurname");

        this.$root.$children[0].curDipTop = namesurname;
        this.$root.$children[0].curDipOreTop = "";
        this.$root.$children[0].curDipOreTopLabel = "";

        bus.$off("bottomIconClickEvent");
        bus.$off("menuClickEvent");
    },



    methods: {

        pdfCompletoMoreProjectWithRep: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);

            //var v_id_prj = that.idProjDefault;

            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {

                for (var xOrdine = 1; xOrdine <= 3; xOrdine++) {

                    var strIndex = xx.toString() + "_" + xOrdine.toString();

                    if (strIndex in that.monthArray) {

                        console.log("GIORNO; ", that.monthArray[strIndex]);

                        var hourFromIntervento = "";
                        if (that.monthArray[strIndex].hour_from_intervento != null) {
                            hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                        }

                        var hourToIntervento = "";
                        if (that.monthArray[strIndex].hour_from_intervento != null) {
                            hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                        }

                        var showCols = 0;
                        if (that.viewInterv == "S") {
                            showCols = 1;
                        }

                        var strNote = "";
                        if (that.monthArray[strIndex].note != null) {
                            strNote = that.monthArray[strIndex].note;
                        }

                        data_timesheet.push({
                            day: that.monthArray[strIndex].day,
                            day_modified: 0,
                            forzatura: "N",
                            hour_activity: that.monthArray[strIndex].hour_activity,
                            hour_activity2: 0,
                            hour_from_intervento: hourFromIntervento,
                            hour_intervento: that.monthArray[strIndex].hour_intervento,
                            hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                            hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                            hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                            hour_to_intervento: hourToIntervento,
                            idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                            idtypeactivity2: 0,
                            month: that.curMonth,
                            note: strNote,
                            ordine: xOrdine,
                            projectid: that.monthArray[strIndex].id_progetto,
                            sede_cliente: 0,
                            show_cols: showCols,
                            year: that.curYear
                        });

                    }





                }


            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;


            var response = await apitimesheet.saveData(

                that.curYear,
                that.curMonth,
                window.$cookies.get("sel_login_ver_ts"),
                JSON.stringify(data_timesheet),
                v_token
            ).then(async (res) => {


                console.log("res from saveData", res);

                var response2 = await apitimesheet.pdfCompletoMoreProjWithRep(

                    that.curYear,
                    that.curMonth,
                    window.$cookies.get("sel_login_ver_ts"),
                    v_token,
                    that.selProgettoFilter

                ).then((res2) => {

                    console.log("res from pdfCompletoMoreProjWithRep", res2);

                    that.showSpinner = false;

                    window.numEventPdfAmm = 0;






                    var file_path = res2.data.Url;
                    var a = document.createElement('A');
                    a.href = file_path;
                    a.target = "_blank";
                    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);




                }).catch(err2 => {

                    console.log(response2);

                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );



            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },




        btnCancelFilterPdfCompleto: function () {

            this.$modal.hide('popupFilterPdfCompleto');

        },

        btnConfirmFilterPdfCompleto: function () {


            var that = this;

            var errore = "";

            if (that.selProgettoFilter == 0) {
                errore = "Selezionare un progetto";
            }

            if (errore == "") {
                console.log("ok");

                that.$modal.hide('popupFilterPdfCompleto');

                setTimeout(() => {

                    if (that.enableIntervRis == "N") {

                        that.pdfCompletoMoreProjectWithRep();

                    }


                }, 200);


            }
            else {
                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },

        openPopupFilterPdfCompleto: function () {

            this.selProgettoFilter = 0;

            $(".contpopupfilterpdfcompleto").show();

            this.$modal.show('popupFilterPdfCompleto');

        },

        pdfAmministrativoMoreProjectWithRep: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);

            //var v_id_prj = that.idProjDefault;

            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {

                for (var xOrdine = 1; xOrdine <= 3; xOrdine++) {

                    var strIndex = xx.toString() + "_" + xOrdine.toString();

                    if (strIndex in that.monthArray) {

                        console.log("GIORNO; ", that.monthArray[strIndex]);

                        var hourFromIntervento = "";
                        if (that.monthArray[strIndex].hour_from_intervento != null) {
                            hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                        }

                        var hourToIntervento = "";
                        if (that.monthArray[strIndex].hour_from_intervento != null) {
                            hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                        }

                        var showCols = 0;
                        if (that.viewInterv == "S") {
                            showCols = 1;
                        }

                        var strNote = "";
                        if (that.monthArray[strIndex].note != null) {
                            strNote = that.monthArray[strIndex].note;
                        }

                        data_timesheet.push({
                            day: that.monthArray[strIndex].day,
                            day_modified: 0,
                            forzatura: "N",
                            hour_activity: that.monthArray[strIndex].hour_activity,
                            hour_activity2: 0,
                            hour_from_intervento: hourFromIntervento,
                            hour_intervento: that.monthArray[strIndex].hour_intervento,
                            hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                            hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                            hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                            hour_to_intervento: hourToIntervento,
                            idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                            idtypeactivity2: 0,
                            month: that.curMonth,
                            note: strNote,
                            ordine: xOrdine,
                            projectid: that.monthArray[strIndex].id_progetto,
                            sede_cliente: 0,
                            show_cols: showCols,
                            year: that.curYear
                        });

                    }





                }


            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;

            // var v_key = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");
            // v_key = v_key + "|" + that.curYear.toString();
            // v_key = v_key + "|" + that.curMonth.toString();
            // v_key = v_key + "|" + atob(v_token);
            // v_key = v_key + "|" + JSON.stringify(data_timesheet);
            // v_key = v_key + "|" + v_token;
            // v_key = v_key + "|" + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

            // console.log("V_KEY: ", v_key);

            // v_key = btoa(v_key);
            // v_key = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

            var response = await apitimesheet.saveData(

                that.curYear,
                that.curMonth,
                window.$cookies.get("sel_login_ver_ts"),
                JSON.stringify(data_timesheet),
                v_token
            ).then(async (res) => {

                //this.showSpinner = false;

                console.log("res from saveData", res);

                var response2 = await apitimesheet.pdfAmministrativoMoreProjWithRep(

                    that.curYear,
                    that.curMonth,
                    window.$cookies.get("sel_login_ver_ts"),
                    v_token,
                    that.selProgettoFilter

                ).then((res2) => {

                    console.log("res from pdfAmministrativoMoreProjWithRep", res2);

                    that.showSpinner = false;

                    window.numEventPdfAmm = 0;






                    var file_path = res2.data.Url;
                    var a = document.createElement('A');
                    a.href = file_path;
                    a.target = "_blank";
                    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);




                }).catch(err2 => {

                    console.log(response2);

                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );



            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },



        btnCancelFilterPdfAmm: function () {

            this.$modal.hide('popupFilterPdfAmm');

        },

        btnConfirmFilterPdfAmm: function () {

            var that = this;

            var errore = "";

            if (that.selProgettoFilter == 0) {
                errore = "Selezionare un progetto";
            }

            if (errore == "") {
                console.log("ok");

                that.$modal.hide('popupFilterPdfAmm');

                setTimeout(() => {

                    if (that.enableIntervRis == "N") {

                        that.pdfAmministrativoMoreProjectWithRep();

                    }

                    if (that.enableIntervRis == "S") {

                        console.log("ok");

                        that.pdfAmministrativoMoreProjectWithRep();

                    }

                }, 200);


            }
            else {
                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }

        },

        openPopupFilterPdfAmm: function () {

            this.selProgettoFilter = 0;

            $(".contpopupfilterpdfamm").show();

            this.$modal.show('popupFilterPdfAmm');

        },

        updateDaysForProgects: function () {

            console.log("PIANO_ORARIO: ", this.piano_orario[0]);
            var ore_totali = this.piano_orario[0].lunedi;
            console.log("LUNEDI: ", ore_totali);



            // rimuovo per il giorno corrente gli elementi che hanno ordinale maggiore di 1
            for (var xOrd = 2; xOrd <= this.countProjAssoc; xOrd++) {

                var keyToRemove = this.curDay + "_" + xOrd.toString();

                console.log("KEY: ", keyToRemove);

                if (keyToRemove in this.monthArray) {
                    delete this.monthArray[keyToRemove];
                }

            }


            // verifico su quanti progetti ho specificato le ore
            var cntProjSpecOre = 0;
            for (var xEle = 0; xEle < this.valuesProjects.length; xEle++) {
                if (this.valuesProjects[xEle].ore > 0) {
                    cntProjSpecOre = cntProjSpecOre + 1;
                }
            }

            if (cntProjSpecOre == 1) {

                // recupero l'id del progetto su cui ho specificato le ore
                var id_project = 0;
                var hour_project2 = 0;
                var codice2 = "";
                for (var xEle2 = 0; xEle2 < this.valuesProjects.length; xEle2++) {
                    if (this.valuesProjects[xEle2].ore > 0) {
                        id_project = this.valuesProjects[xEle2].projectid;
                        hour_project2 = this.valuesProjects[xEle2].ore;
                        codice2 = this.valuesProjects[xEle2].codice;
                    }
                }

                // aggiorno la struttura generale con l'id del progetto
                this.monthArray[this.curDay + "_1"].id_progetto = id_project;
                this.monthArray[this.curDay + "_1"].hour_ordinario = hour_project2;
                this.monthArray[this.curDay + "_1"].desc_progetto = codice2;


            }

            if (cntProjSpecOre > 1) {

                var id_project2 = 0;
                var hour_project = 0;
                var codice3 = "";

                var xCount = 1;
                for (var xEle3 = 0; xEle3 < this.valuesProjects.length; xEle3++) {

                    id_project2 = 0;
                    hour_project = 0;
                    if (this.valuesProjects[xEle3].ore > 0) {
                        id_project2 = this.valuesProjects[xEle3].projectid;
                        hour_project = this.valuesProjects[xEle3].ore;
                        codice3 = this.valuesProjects[xEle3].codice;
                    }

                    if (xCount == 1) {

                        var xKeyDay2 = this.curDay + "_" + xCount.toString();
                        this.monthArray[xKeyDay2].id_progetto = id_project2;
                        this.monthArray[xKeyDay2].hour_ordinario = hour_project;
                        this.monthArray[xKeyDay2].desc_progetto = codice3;

                        if (this.oreTotaliGiornataProgetti < ore_totali) {
                            this.monthArray[xKeyDay2].hour_activity = ore_totali - hour_project;
                        }

                        console.log("XKEYDAY2: ", xKeyDay2);
                        console.log("ID_PROGETTO: ", id_project2);
                        console.log("GOUR_ORDINARIO: ", hour_project);
                        console.log("CODICE: ", codice3);
                        console.log("MONTH ARRAY 1: ", this.monthArray);

                    }

                    if (xCount > 1) {

                        var xKeyToCopy = this.curDay + "_1";
                        var xKeyDay3 = this.curDay + "_" + xCount.toString();

                        // inserisco la copia dell'elemento
                        if (xKeyToCopy in this.monthArray) {
                            this.monthArray[xKeyDay3] = { ...this.monthArray[xKeyToCopy] }; // Clona l'oggetto
                        }

                        // aggiorno il nuovo elemento
                        this.monthArray[xKeyDay3].id_progetto = id_project2;
                        this.monthArray[xKeyDay3].hour_ordinario = hour_project;
                        this.monthArray[xKeyDay3].desc_progetto = codice3;

                        if (this.oreTotaliGiornataProgetti < ore_totali) {
                            this.monthArray[xKeyDay3].hour_activity = ore_totali - hour_project;
                        }

                        console.log("MONTH ARRAY 2: ", this.monthArray);

                    }

                    xCount = xCount + 1;
                }


            }

            console.log("MONTH ARRAY 3: ", this.monthArray);

            this.$modal.hide('popupProgetti');

            this.syncMOnthIcons();

        },

        openPopupOreProgetti: function (event) {

            console.log("EVENTO: ", event);

            var that = this;

            console.log("ARRAY_MONTH: ", that.monthArray);

            var errore = "";

            var vDay = this.monthArray[that.curDay + "_1"];

            if (vDay.hour_ordinario == 0) {
                errore = "Specificare le ore ordinarie";
            }

            if (errore == "") {
                if (vDay.idtypeactivity > 0) {
                    if (vDay.hour_activity == 0) {
                        errore = "Specificare le ore di assenza";
                    }
                }
            }

            if (errore == "") {
                if (vDay.hour_activity > 0) {
                    if (vDay.idtypeactivity == 0) {
                        errore = "Specificare una causale";
                    }
                }
            }




            if (errore == "") {

                that.dataGiornataProgetti = that.curDay;

                // var totOre = 0;


                // for (let key in that.monthArray) {

                //     var curEle = that.monthArray[key];

                //     if (curEle.day == that.curDay) {
                //         totOre = totOre + parseFloat(curEle.hour_ordinario);
                //     }


                // }

                // that.oreTotaliGiornataProgetti = totOre;

                that.oreTotaliGiornataProgetti = that.forecast[0].quantity;

                // carico le ore attuali dei progetti del giorno selezionato nella struttura

                // resetto la struttura
                for (var xProj = 0; xProj < that.valuesProjects.length; xProj++) {
                    that.valuesProjects[xProj].ore = 0;
                }

                // carico i valori attuali dei progetti per il giorno selezionato
                for (var xOrd3 = 1; xOrd3 <= 3; xOrd3++) // fino ad un massimo di 3 progetti
                {

                    var xKeyDay = that.curDay + "_" + xOrd3.toString();

                    if (xKeyDay in that.monthArray) {

                        if (that.monthArray[xKeyDay].id_progetto != "") {
                            if (that.monthArray[xKeyDay].id_progetto > 0) {

                                for (var xProj2 = 0; xProj2 < that.valuesProjects.length; xProj2++) {

                                    if (that.monthArray[xKeyDay].id_progetto == that.valuesProjects[xProj2].projectid) {

                                        that.valuesProjects[xProj2].ore = that.monthArray[xKeyDay].hour_ordinario;

                                    }

                                }

                            }
                        }

                    }

                }




                setTimeout(() => {

                    $(".contpopuppprogetti").show();

                    that.$modal.show('popupProgetti');

                }, 200);

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });



            }








        },

        btnConfirmOreProgetti: function () {

            var errore = "";

            var totOre = 0;
            for (var xx12 = 0; xx12 < this.valuesProjects.length; xx12++) {
                totOre = totOre + parseFloat(this.valuesProjects[xx12].ore)
            }

            if (totOre != parseFloat(this.oreTotaliGiornataProgetti)) {
                errore = "Le ore totali dei progetti devono essere " + this.oreTotaliGiornataProgetti;
            }

            if (errore == "") {

                console.log("ok");

                this.updateDaysForProgects();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });
            }



        },

        btnCancelOreProgetti: function () {

            this.$modal.hide('popupProgetti');

        },

        sumHoursProject: function (event) {

            var operation = "+";

            if (event.target.classList.contains('minus')) {
                operation = '-';
            }

            console.log("OPERATION: ", operation);


            if (event) {
                var dataid = event.target.getAttribute("data-id");
                var disabled = event.target.attributes.getNamedItem('disabled');

                console.log("DATAID", dataid);
                console.log("DISABLED", disabled);

                console.log("PO", this.piano_orario);
                console.log("curDay", this.curDay);

                var strDay = new Date(this.curYear, this.curMonth - 1, this.curDay);
                //console.log ("dw", strDay.getDay() );
                var mapDay = {
                    "0": "domenica",
                    "1": "lunedi",
                    "2": "martedi",
                    "3": "mercoledi",
                    "4": "giovedi",
                    "5": "venerdi",
                    "6": "sabato"
                };

                var po = this.piano_orario[0];
                var dayLiteral = mapDay[strDay.getDay()];

                console.log("DAYLITERAL: ", dayLiteral);
                console.log("IS PIVA: ", this.IsUserPartitaIva);

                if ((dayLiteral == "sabato") || (dayLiteral == "domenica")) {
                    dayLiteral = "venerdi";
                }


                var oretoday = parseFloat(po[dayLiteral]);

                var max = 10;
                max = oretoday;

                console.log("MAX: ", max);

                var currentHour = 0;

                for (var xx9 = 0; xx9 < this.valuesProjects.length; xx9++) {

                    if (parseInt(dataid) == parseInt(this.valuesProjects[xx9].projectid)) {
                        console.log("PRJ FOUND");
                        currentHour = this.valuesProjects[xx9].ore;
                    }

                }

                console.log("CURRENT_HOUR: ", currentHour);

                var newHour = currentHour;

                if (disabled == null) {

                    if (operation == '+')
                        newHour = parseFloat(newHour) + 0.5;
                    else
                        newHour = parseFloat(newHour) - 0.5;

                }

                console.log("NEW_HOUR: ", newHour);

                if (newHour <= max && operation == '+') {

                    for (var xx10 = 0; xx10 < this.valuesProjects.length; xx10++) {

                        if (parseInt(dataid) == parseInt(this.valuesProjects[xx10].projectid)) {
                            console.log("PRJ FOUND 2");
                            this.valuesProjects[xx10].ore = newHour;
                        }

                    }

                }


                if (newHour >= 0 && operation == '-') {


                    for (var xx11 = 0; xx11 < this.valuesProjects.length; xx11++) {

                        if (parseInt(dataid) == parseInt(this.valuesProjects[xx11].projectid)) {
                            console.log("PRJ FOUND 3");
                            this.valuesProjects[xx11].ore = newHour;
                        }

                    }

                }




            }

        },

        getDateStartDisease: function () {

            var yearStart = window.$cookies.get("yearStartPeriodTsCalendar");
            var monthStart = window.$cookies.get("monthStartPeriodTsCalendar");
            var dayStart = window.$cookies.get("dayStartPeriodTsCalendar");

            console.log("YEAR_START GET_DATE_START_DISEASE: ", yearStart);
            console.log("MONTH_START GET_DATE_START_DISEASE: ", monthStart);
            console.log("DAY_START GET_DATE_START_DISEASE: ", dayStart);

            var result = null;
            if ((yearStart != null) && (monthStart != null) && (dayStart != null)) {
                result = new Date(parseInt(yearStart), parseInt(monthStart), parseInt(dayStart));
            }

            console.log("RESULT GET_DATE_START_DISEASE: ", result);


            return result

        },

        getDateEndDisease: function () {

            var yearEnd = window.$cookies.get("yearEndPeriodTsCalendar");
            var monthEnd = window.$cookies.get("monthEndPeriodTsCalendar");
            var dayEnd = window.$cookies.get("dayEndPeriodTsCalendar");


            console.log("YEAR_END GET_DATE_END_DISEASE: ", yearEnd);
            console.log("MONTH_END GET_DATE_END_DISEASE: ", monthEnd);
            console.log("DAY_END GET_DATE_END_DISEASE: ", dayEnd);

            var resultEnd = null;
            if ((yearEnd != null) && (monthEnd != null) && (dayEnd != null)) {
                resultEnd = new Date(parseInt(yearEnd), parseInt(monthEnd), parseInt(dayEnd));
            }

            console.log("RESULT GET_DATE_END_DISEASE: ", resultEnd);


            return resultEnd

        },

        pdfCompletoWithRepInterv: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);

            var v_id_prj = that.idProjDefault;

            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {

                var strIndex = xx.toString() + "_1";

                console.log("GIORNO; ", that.monthArray[strIndex]);

                var hourFromIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                }

                var hourToIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                }

                var showCols = 0;
                if (that.viewInterv == "S") {
                    showCols = 1;
                }

                var strNote = "";
                if (that.monthArray[strIndex].note != null) {
                    strNote = that.monthArray[strIndex].note;
                }

                data_timesheet.push({
                    day: that.monthArray[strIndex].day,
                    day_modified: 0,
                    forzatura: "N",
                    hour_activity: that.monthArray[strIndex].hour_activity,
                    hour_activity2: 0,
                    hour_from_intervento: hourFromIntervento,
                    hour_intervento: that.monthArray[strIndex].hour_intervento,
                    hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                    hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                    hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                    hour_to_intervento: hourToIntervento,
                    idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                    idtypeactivity2: 0,
                    month: that.curMonth,
                    note: strNote,
                    ordine: 1,
                    projectid: v_id_prj,
                    sede_cliente: 0,
                    show_cols: showCols,
                    year: that.curYear
                });

            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;



            var response = await apitimesheet.saveData(

                that.curYear,
                that.curMonth,
                window.$cookies.get("sel_login_ver_ts"),
                JSON.stringify(data_timesheet),
                v_token

            ).then(async (res) => {


                console.log("res from saveData", res);

                var response2 = await apitimesheet.pdfCompletoWithRepInterv(

                    that.curYear,
                    that.curMonth,
                    window.$cookies.get("sel_login_ver_ts"),
                    v_token

                ).then((res2) => {

                    console.log("res from pdfCompletoWithRepInterv", res2);

                    this.showSpinner = false;

                    window.numEventPdfAmm = 0;






                    var file_path = res2.data.Url;
                    var a = document.createElement('A');
                    a.href = file_path;
                    a.target = "_blank";
                    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);




                }).catch(err2 => {

                    console.log(response2);

                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );


            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },


        pdfCompletoWithRep: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);

            var v_id_prj = that.idProjDefault;

            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {

                var strIndex = xx.toString() + "_1";

                console.log("GIORNO; ", that.monthArray[strIndex]);

                var hourFromIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                }

                var hourToIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                }

                var showCols = 0;
                if (that.viewInterv == "S") {
                    showCols = 1;
                }

                var strNote = "";
                if (that.monthArray[strIndex].note != null) {
                    strNote = that.monthArray[strIndex].note;
                }

                data_timesheet.push({
                    day: that.monthArray[strIndex].day,
                    day_modified: 0,
                    forzatura: "N",
                    hour_activity: that.monthArray[strIndex].hour_activity,
                    hour_activity2: 0,
                    hour_from_intervento: hourFromIntervento,
                    hour_intervento: that.monthArray[strIndex].hour_intervento,
                    hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                    hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                    hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                    hour_to_intervento: hourToIntervento,
                    idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                    idtypeactivity2: 0,
                    month: that.curMonth,
                    note: strNote,
                    ordine: 1,
                    projectid: v_id_prj,
                    sede_cliente: 0,
                    show_cols: showCols,
                    year: that.curYear
                });

            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;


            var response = await apitimesheet.saveData(

                that.curYear,
                that.curMonth,
                window.$cookies.get("sel_login_ver_ts"),
                JSON.stringify(data_timesheet),
                v_token

            ).then(async (res) => {


                console.log("res from saveData", res);

                var response2 = await apitimesheet.pdfCompletoWithRep(

                    that.curYear,
                    that.curMonth,
                    window.$cookies.get("sel_login_ver_ts"),
                    v_token

                ).then((res2) => {

                    console.log("res from pdfCompletoWithRep", res2);

                    this.showSpinner = false;

                    window.numEventPdfAmm = 0;






                    var file_path = res2.data.Url;
                    var a = document.createElement('A');
                    a.href = file_path;
                    a.target = "_blank";
                    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);




                }).catch(err2 => {

                    console.log(response2);

                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );


            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },







        pdfAmministrativoWithRepInterv: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);

            var v_id_prj = that.idProjDefault;

            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {

                var strIndex = xx.toString() + "_1";

                console.log("GIORNO; ", that.monthArray[strIndex]);

                var hourFromIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                }

                var hourToIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                }

                var showCols = 0;
                if (that.viewInterv == "S") {
                    showCols = 1;
                }

                var strNote = "";
                if (that.monthArray[strIndex].note != null) {
                    strNote = that.monthArray[strIndex].note;
                }

                data_timesheet.push({
                    day: that.monthArray[strIndex].day,
                    day_modified: 0,
                    forzatura: "N",
                    hour_activity: that.monthArray[strIndex].hour_activity,
                    hour_activity2: 0,
                    hour_from_intervento: hourFromIntervento,
                    hour_intervento: that.monthArray[strIndex].hour_intervento,
                    hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                    hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                    hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                    hour_to_intervento: hourToIntervento,
                    idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                    idtypeactivity2: 0,
                    month: that.curMonth,
                    note: strNote,
                    ordine: 1,
                    projectid: v_id_prj,
                    sede_cliente: 0,
                    show_cols: showCols,
                    year: that.curYear
                });

            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;


            var response = await apitimesheet.saveData(

                that.curYear,
                that.curMonth,
                window.$cookies.get("sel_login_ver_ts"),
                JSON.stringify(data_timesheet),
                v_token

            ).then(async (res) => {


                console.log("res from saveData", res);

                var response2 = await apitimesheet.pdfAmministrativoWithRepInterv(

                    that.curYear,
                    that.curMonth,
                    window.$cookies.get("sel_login_ver_ts"),
                    v_token

                ).then((res2) => {

                    console.log("res from pdfAmministrativoWithRepInterv", res2);

                    this.showSpinner = false;

                    window.numEventPdfAmm = 0;






                    var file_path = res2.data.Url;
                    var a = document.createElement('A');
                    a.href = file_path;
                    a.target = "_blank";
                    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);




                }).catch(err2 => {

                    console.log(response2);

                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );


            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },


        pdfAmministrativoWithRep: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);

            var v_id_prj = that.idProjDefault;

            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {

                var strIndex = xx.toString() + "_1";

                console.log("GIORNO; ", that.monthArray[strIndex]);

                var hourFromIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                }

                var hourToIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                }

                var showCols = 0;
                if (that.viewInterv == "S") {
                    showCols = 1;
                }

                var strNote = "";
                if (that.monthArray[strIndex].note != null) {
                    strNote = that.monthArray[strIndex].note;
                }

                data_timesheet.push({
                    day: that.monthArray[strIndex].day,
                    day_modified: 0,
                    forzatura: "N",
                    hour_activity: that.monthArray[strIndex].hour_activity,
                    hour_activity2: 0,
                    hour_from_intervento: hourFromIntervento,
                    hour_intervento: that.monthArray[strIndex].hour_intervento,
                    hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                    hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                    hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                    hour_to_intervento: hourToIntervento,
                    idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                    idtypeactivity2: 0,
                    month: that.curMonth,
                    note: strNote,
                    ordine: 1,
                    projectid: v_id_prj,
                    sede_cliente: 0,
                    show_cols: showCols,
                    year: that.curYear
                });

            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;


            var response = await apitimesheet.saveData(

                that.curYear,
                that.curMonth,
                window.$cookies.get("sel_login_ver_ts"),
                JSON.stringify(data_timesheet),
                v_token

            ).then(async (res) => {


                console.log("res from saveData", res);

                var response2 = await apitimesheet.pdfAmministrativoWithRep(

                    that.curYear,
                    that.curMonth,
                    window.$cookies.get("sel_login_ver_ts"),
                    v_token

                ).then((res2) => {

                    console.log("res from pdfAmministrativoWithRep", res2);

                    this.showSpinner = false;

                    window.numEventPdfAmm = 0;






                    var file_path = res2.data.Url;
                    var a = document.createElement('A');
                    a.href = file_path;
                    a.target = "_blank";
                    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);




                }).catch(err2 => {

                    console.log(response2);

                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );


            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },






        validateSaveAndClose() {

            console.log("ARRAY_MONTH: ", this.monthArray);



            var errore = "";

            var ultimo_giorno = this.ultimoGiornoData(this.curYear, this.curMonth);

            console.log("ULTIMO GIORNO: ", ultimo_giorno);



            for (var xx4 = 1; xx4 <= ultimo_giorno; xx4++) {

                if (errore == "") {

                    var vDay = this.monthArray[xx4 + "_1"];

                    if (vDay.is_festivo == 0) {
                        if (vDay.desc_day != "Sab") {
                            if (vDay.desc_day != "Dom") {

                                if (vDay.hour_activity > 0) {
                                    if (vDay.idtypeactivity == 0) {
                                        errore = "Specificare una causale per il giorno " + vDay.day;
                                    }
                                }

                            }
                        }
                    }

                }


            }






            if (errore == "") {
                console.log("ok");

                if (this.countProjAssoc <= 1) {

                    this.saveAndClose();
                }

                if (this.countProjAssoc > 1) {

                    this.saveAndCloseMoreProjs();
                }



            }
            else {
                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }

        },

        ultimoGiornoData: function (anno, mese) {

            var res = new Date(anno, mese, 0).toISOString().split('T')[0];

            var elems = res.split("-");

            return parseInt(elems[2]) + 1;
        },

        validateSaveAndOpen() {

            console.log("ARRAY_MONTH: ", this.monthArray);



            var errore = "";

            var ultimo_giorno = this.ultimoGiornoData(this.curYear, this.curMonth);

            console.log("ULTIMO GIORNO: ", ultimo_giorno);



            for (var xx4 = 1; xx4 <= ultimo_giorno; xx4++) {

                if (errore == "") {

                    var vDay = this.monthArray[xx4 + "_1"];

                    if (vDay.is_festivo == 0) {
                        if (vDay.desc_day != "Sab") {
                            if (vDay.desc_day != "Dom") {

                                if (vDay.hour_activity > 0) {
                                    if (vDay.idtypeactivity == 0) {
                                        errore = "Specificare una causale per il giorno " + vDay.day;
                                    }
                                }

                            }
                        }
                    }

                }


            }






            if (errore == "") {
                console.log("ok");

                if (this.countProjAssoc <= 1) {

                    this.saveAndOpen();
                }

                if (this.countProjAssoc > 1) {

                    this.saveAndOpenMoreProjs();
                }

            }
            else {
                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }

        },


        onChangeViewInterv: function (newValue) {

            console.log("NEW VALUE: ", newValue);

            if (newValue == "S") {
                $("#Intervento").show();
                $("#Dalle").show();
                $("#Alle").show();
            }

            if (newValue == "N") {
                $("#Intervento").hide();
                $("#Dalle").hide();
                $("#Alle").hide();
            }


        },

        onChangeDescription: function (value) {

            this.monthArray[this.curDay + "_1"].note = value;

        },

        hideShowSecNav: function (v_show) {

            if (v_show) {

                $("#btn_indietro_dip").show();
                $("#name_dip_sel").show();
                $("#btn_avanti_dip").show();

            }
            else {


                $("#btn_indietro_dip").hide();
                $("#name_dip_sel").hide();
                $("#btn_avanti_dip").hide();

            }



        },



        getProgetti: async function () {

            console.log("GETPROGETTI: ", window.user_selected);


            var that = this;

            var sel_login_ver_ts = window.$cookies.get("sel_login_ver_ts");



            var response = await apitimesheet.getProgetti(

                sel_login_ver_ts

            ).then((res) => {


                console.log("res from getProgetti", res);


                that.progetti = res.data.Result;


                that.oreContratto = res.data.OreContratto;

            }).catch(err => {

                that.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        manageClickProgetti: async function (progetto) {

            this.sheetProgetti = false;

            console.log("PROGETTO: ", progetto);

            window.curProgetto = progetto.projectid;


            this.syncMonth();




        },

        goToHoliday: function () {

            window.$cookies.set("is_from_ts_user", 1, "9y");

            router.push({
                path: "/initHoliday"
            });


        },

        goToDisease: function () {

            window.$cookies.set("is_from_ts_user", 1, "9y");

            router.push({
                path: "/initDisease"
            });


        },

        copyRow: async function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });


            console.log("IDS: ", ids);



            that.showSpinner = true;

            var response = await apitimesheet.copyRow(
                ids
            ).then((res) => {

                that.showSpinner = false;

                console.log("res from copyRow", res);

                that.$swal({
                    icon: "success",
                    text: "Dati copiati con successo",
                    showConfirmButton: false,
                    timer: 2000
                });

                window.numEventASave = 0;

                that.syncMonth();

            }).catch(err => {

                that.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        deleteRow: async function () {

            var that = this;

            var str_values = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (str_values != "") {
                        str_values = str_values + "#";
                    }

                    var v_login = $(this).attr("v_login");
                    var v_day = $(this).attr("v_day");
                    var v_month = $(this).attr("v_month");
                    var v_year = $(this).attr("v_year");
                    var v_ordine = $(this).attr("v_ordine");

                    //this.deleteRowTs(v_login, v_day, v_month, v_year, v_ordine);

                    str_values = str_values + v_login + "|" + v_day + "|" + v_month + "|" + v_year + "|" + v_ordine;

                }

            });


            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {


                        console.log("VALUES: ", str_values);



                        that.showSpinner = true;

                        var response = await apitimesheet.deleteRowTsMultiNew(
                            str_values
                        ).then((res) => {

                            that.showSpinner = false;

                            console.log("res from deleteRowTsMultiNew", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati con successo",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            window.numEventASave = 0;

                            that.syncMonth();

                        }).catch(err => {

                            that.showSpinner = false;

                            window.numEventASaveAndOpen = 0;

                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );




                    }





                });


        },


        formatDateTime: function (v_datetime) {

            if (v_datetime != "") {

                if (v_datetime.toString().indexOf("T") !== -1) {


                    v_datetime = v_datetime.toString().substring(11, 16);
                    v_datetime = v_datetime + ":00";


                }

            }

            return v_datetime;

        },


        hideShowButtonsSelRow: function () {




            setTimeout(() => {

                var pulsantis = [];

                pulsantis.push(

                    {
                        text: "Ricarica",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/refresh-emt.png",
                        link: "",
                        id: "btn_refr_tms",
                        disabled: false,
                        title: "Aggiorna",
                        width: 30
                    },



                );



                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);



        },

        viewColumns: function (v_enable) {
            window.table.column(10).visible(v_enable);
            window.table.column(11).visible(v_enable);
            window.table.column(12).visible(v_enable);
        },


        enableViewColumns: function () {

            var that = this;

            if (that.showColumns == true) {
                window.table.column(10).visible(false);
                window.table.column(11).visible(false);
                window.table.column(12).visible(false);
                that.showColumns = false;
            }
            else {

                window.table.column(10).visible(true);
                window.table.column(11).visible(true);
                window.table.column(12).visible(true);
                that.showColumns = true;


            }


        },

        updateTotalRows: function () {

            var dataTableElements = window.table.data();

            console.log("DATA TOTAL ROWS: ", dataTableElements);

            for (var xDay = 0; xDay < dataTableElements.length; xDay++) {

                var vRowDay = dataTableElements[xDay];

                var total = parseFloat(vRowDay.hour_ordinario) + parseFloat(vRowDay.hour_activity);

                var keyTotal = "#sel_ore_totali_" + vRowDay.day.toString();

                $(keyTotal).html(total.toFixed(2));


            }



        },


        colorRows: function () {

            var dataTableElements = window.table.data();

            var numCol = 14;

            for (var xDay = 0; xDay < dataTableElements.length; xDay++) {


                console.log("ELEMENTS TR2: ", dataTableElements[xDay]);

                var vRowDay = dataTableElements[xDay];

                if (vRowDay.desc_day != "Dom") {

                    if (vRowDay.desc_day != "Sab") {


                        if (vRowDay.fest_gen == 1) {

                            for (var xCol3 = 0; xCol3 <= numCol; xCol3++) {


                                $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol3).addClass("clRowFest");

                            }


                        }


                        if (vRowDay.fest_gen != 1) {

                            if (vRowDay.idtypeactivity == 4) {

                                for (var xCol4 = 0; xCol4 <= numCol; xCol4++) {


                                    $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol4).addClass("clRowMalattia");

                                }


                            }


                            if (vRowDay.idtypeactivity != 4) {

                                if (vRowDay.hour_ordinario > 0) {


                                    if (vRowDay.hour_activity == 0) {



                                        for (var xCol = 0; xCol <= numCol; xCol++) {


                                            $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol).addClass("clRowGreen");

                                        }




                                    }



                                }

                                if (vRowDay.hour_ordinario == 0) {


                                    if (vRowDay.hour_activity > 0) {



                                        for (var xCol2 = 0; xCol2 <= numCol; xCol2++) {


                                            $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol2).addClass("clRowYellow");

                                        }


                                    }



                                }




                                if (vRowDay.hour_ordinario > 0) {


                                    if (vRowDay.hour_activity > 0) {



                                        for (var xCol5 = 0; xCol5 <= numCol; xCol5++) {


                                            $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol5).addClass("clRowYellow");

                                        }


                                    }



                                }



                            }









                        }

                    }


                }




            }


        },


        generateRandomString: function (length, characters) {

            let result = "";
            const charLength = characters.length;

            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * charLength);
                result += characters.charAt(randomIndex);
            }

            return result;


        },


        deleteRowTs: async function (v_login, v_day, v_month, v_year) {

            var that = this;

            console.log("LOGIN: ", v_login);
            console.log("DAY: ", v_day);
            console.log("MONTH: ", v_month);
            console.log("YEAR: ", v_year);


            var strDay = v_day;
            if (strDay.length == 1) {
                strDay = "0" + strDay.toString();
            }

            var strMonth = v_month;
            if (strMonth.length == 1) {
                strMonth = "0" + strMonth.toString();
            }

            var strDate = strDay + "/" + strMonth + "/" + v_year.toString();

            var strMsg = "Vuoi eliminare la giornata del " + strDate + " ?";

            that.$swal
                .fire({
                    title: "Elimina",
                    html: strMsg,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {



                        that.showSpinner = true;

                        var response = await apitimesheet.deleteRowTs(
                            v_login,
                            v_day,
                            v_month,
                            v_year
                        ).then((res) => {

                            that.showSpinner = false;

                            console.log("res from saveData", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati con successo",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            window.numEventASave = 0;

                            that.syncMonth();

                        }).catch(err => {

                            that.showSpinner = false;

                            window.numEventASaveAndOpen = 0;

                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );





                    }





                });


        },


        sendTimesheetForApprove: async function () {

            var that = this;

            var $mesi = [
                "",
                "gennaio",
                "febbraio",
                "marzo",
                "aprile",
                "maggio",
                "giugno",
                "luglio",
                "agosto",
                "settembre",
                "ottobre",
                "novembre",
                "dicembre",
            ];
            var text = $mesi[that.curMonth] + " - " + that.curYear;

            console.log("this.curStatus", that.curStatus)
            var statonum = 1;
            // (1 - Aperto 2 - In Approvazione 3 - Chiuso) (obbligatorio)

            if (that.curStatus == 'Open') statonum = 1;
            if (that.curStatus == 'Close') statonum = 3;
            if (that.curStatus == 'For approval') statonum = 2;

            if (that.aa == 'Y' || that.su == 'Y') {

                console.log(statonum);

                that.sheetStatus = true;

            } else {
                that.$swal
                    .fire({
                        title: "Sei sicuro?",
                        html: "Vuoi inviare il timesheet <h4>" + text + " ?</h4> ",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                        cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                        customClass: {
                            cancelButton: 'order-1',
                            confirmButton: 'order-2',
                        }

                    })
                    .then((result) => {
                        if (result.value == true) {

                            that.saveDataForAppr();

                        }
                    });
            }

        },


        validateSendTimesheetForApprove: function () {

            var that = this;


            var params = that.getParamsApi();

            console.log("SAVE_ADN_OPEN");
            console.log("CUR_YEAR: ", that.curYear);
            console.log("CUR_MONTH: ", that.curMonth);
            console.log("USR_SEL: ", atob(params.token));

            var data_timesheet = [];

            $(".fldchanged").each(function (index) {

                console.log(index + ": " + $(this).text());

                console.log("VAL: ", $(this).val());

                var v_row = $(this).parent().parent();

                console.log("V_ROW: ", v_row);
                var item = window.table.row(v_row).data();

                //var v_day = index + 1; // valore giorno
                var v_day = item.day; // valore giorno
                var v_forz = "N";
                var v_note = $("#sel_desc_" + v_day + "_" + item.ordine).val();
                var v_h_ord = $("#sel_ordinario_" + v_day + "_" + item.ordine).val();
                var v_h_straord = $("#sel_eccedenze_" + v_day + "_" + item.ordine).val();
                var v_h_act = $("#sel_ore_ass_" + v_day + "_" + item.ordine).val();
                var v_id_type_act = $("#sel_tipo_ass_" + v_day + "_" + item.ordine).val();
                var v_h_act2 = $("#sel_ore_ass_2_" + v_day + "_" + item.ordine).val();
                var v_id_type_act2 = $("#sel_tipo_ass_2_" + v_day + "_" + item.ordine).val();

                var v_id_prj = that.idProjDefault;

                if (that.countProjAssoc > 1) {

                    v_id_prj = $("#sel_prj_" + v_day + "_" + item.ordine).val();

                }


                var v_desc_day = $("#desc_day_" + v_day + "_" + item.ordine).html();

                var v_h_rep = $("#sel_reperibilita_" + v_day + "_" + item.ordine).val();

                var v_h_int = 0;
                var v_h_from_int = "";
                var v_h_to_int = "";

                if (that.showColumns == true) {

                    v_h_int = $("#sel_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_from_int = $("#sel_ora_da_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_to_int = $("#sel_ora_a_intervento_" + v_day + "_" + item.ordine).val();

                }


                var check_sede_cliente = 0;
                if ($("#sel_sede_cliente_" + v_day + "_" + item.ordine).prop("checked")) {
                    check_sede_cliente = 1;
                }


                console.log("VAL GIORNO: ", v_day);
                console.log("VAL FORZATURA: ", v_forz);

                data_timesheet.push({
                    day_modified: $(this).val(),
                    forzatura: v_forz,
                    note: v_note,
                    year: that.curYear,
                    month: that.curMonth,
                    day: v_day,
                    hour_ordinario: v_h_ord,
                    hour_straordinario: v_h_straord,
                    hour_activity: v_h_act,
                    idtypeactivity: v_id_type_act,
                    hour_activity2: v_h_act2,
                    idtypeactivity2: v_id_type_act2,
                    projectid: v_id_prj,
                    desc_day: v_desc_day,
                    is_fest: $(this).attr("is-fest"),
                    hour_reperibilita: v_h_rep,
                    hour_intervento: v_h_int,
                    hour_from_intervento: that.formatDateTime(v_h_from_int),
                    hour_to_intervento: that.formatDateTime(v_h_to_int),
                    ordine: item.ordine,
                    sede_cliente: check_sede_cliente
                });

            });

            console.log("DATA TS: ", data_timesheet);


            var errore = "";

            if (that.countProjAssoc < 2) {

                for (var x = 0; x <= data_timesheet.length - 1; x++) {

                    var giorno = data_timesheet[x];


                    if ((giorno.desc_day.indexOf("Sab") == -1) && (giorno.desc_day.indexOf("Dom") == -1)) {

                        if (parseInt(giorno.idtypeactivity) != 8)  // diverso da non in forza
                        {



                            if (errore == "") {

                                if (parseFloat(giorno.hour_activity) > 0) // sono presenti delle ore di assenza nella gionata
                                {

                                    if (parseInt(giorno.idtypeactivity) == 0) // non è stata specificata la causale
                                    {

                                        errore = "Specificare una causale per le ore di assenza del giorno " + giorno.day;

                                    }


                                }


                            }


                            if (errore == "") {

                                if (parseInt(giorno.idtypeactivity) > 0) // è stata specificata la causale
                                {


                                    if (parseInt(giorno.idtypeactivity) != 5) {

                                        if (parseFloat(giorno.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                        {

                                            errore = "Specificare le ore di assenza per il giorno " + giorno.day;


                                        }



                                    }




                                }

                            }


                            if (errore == "") {

                                if (giorno.hour_straordinario == "") {

                                    errore = "Specificare un valore per lo straordinario per il giorno " + giorno.day;


                                }


                            }

                            if (that.IsPartiIVAPartTime != "S") {

                                if (errore == "") {

                                    if ((parseFloat(giorno.hour_ordinario) == 0) && (parseFloat(giorno.hour_activity) == 0)) {

                                        errore = "Specificare un valore per le ore di assenza per il giorno " + giorno.day;


                                    }

                                }


                            }






                            if (errore == "") {

                                var h_ord = parseFloat(giorno.hour_ordinario);
                                var h_straord = parseFloat(giorno.hour_straordinario);
                                var h_ass = parseFloat(giorno.hour_activity);

                                if ((h_ord == 0) && (h_straord > 0) && (h_ass > 0)) {

                                    errore = "Specificare delle ore ordinarie per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                var h_ord2 = parseFloat(giorno.hour_ordinario);
                                var h_straord2 = parseFloat(giorno.hour_straordinario);
                                var h_ass2 = parseFloat(giorno.hour_activity);

                                if ((h_ord2 == 0) && (h_straord2 > 0) && (h_ass2 > 0)) {

                                    errore = "Specificare delle ore ordinarie per il giorno " + giorno.day;


                                }

                            }

                            if (errore == "") {

                                var h_straord3 = parseFloat(giorno.hour_straordinario);

                                if (h_straord3 > 10) {

                                    errore = "Le ore di straordinario devono essere al massimo 10 per il giorno " + giorno.day;


                                }

                            }






                        }




                    }









                }

            }

            if (that.countProjAssoc >= 2) {

                for (var x2 = 0; x2 <= data_timesheet.length - 1; x2++) {

                    var giorno2 = data_timesheet[x2];

                    if ((giorno2.desc_day.indexOf("Sab") == -1) && (giorno2.desc_day.indexOf("Dom") == -1)) {

                        if (errore == "") {

                            if (parseFloat(giorno2.hour_activity) > 0) // sono presenti delle ore di assenza nella gionata
                            {

                                if (parseInt(giorno2.idtypeactivity) == 0) // non è stata specificata la causale
                                {

                                    errore = "Specificare una causale per le ore di assenza del giorno " + giorno2.day;

                                }


                            }


                        }

                        if (errore == "") {

                            if (parseInt(giorno2.idtypeactivity) > 0) // è stata specificata la causale
                            {

                                if (parseFloat(giorno2.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                {

                                    errore = "Specificare le ore di assenza per il giorno " + giorno2.day;


                                }



                            }

                        }

                    }






                    if (errore == "") {

                        if (parseFloat(giorno2.hour_ordinario) > 0) // ci sono delle ore di attivita
                        {

                            if (giorno2.projectid == 0) // non è stato specificato il progettogionata
                            {

                                errore = "Specificare il progetto per il giorno " + giorno2.day;


                            }



                        }

                    }

                    if ((giorno2.desc_day.indexOf("Sab") == -1) && (giorno2.desc_day.indexOf("Dom") == -1)) {

                        if (errore == "") {

                            if (parseInt(giorno2.hour_ordinario) == 0) // non sono presenti le ore ordinarie
                            {

                                if (parseFloat(giorno2.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                {

                                    errore = "Specificare le ore ordinarie per il giorno " + giorno2.day;


                                }



                            }

                        }

                    }


                }



            }


            if (errore == "") {


                console.log("ok");


                that.sendTimesheetForApprove();

            }
            else {

                window.numEventASave = 0;

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                });


            }


        },



        validateSave: function () {

            var that = this;


            var params = that.getParamsApi();

            console.log("SAVE_ADN_OPEN");
            console.log("CUR_YEAR: ", that.curYear);
            console.log("CUR_MONTH: ", that.curMonth);
            console.log("USR_SEL: ", atob(params.token));

            var data_timesheet = [];

            $(".fldchanged").each(function (index) {

                console.log(index + ": " + $(this).text());

                var v_row = $(this).parent().parent();

                console.log("V_ROW: ", v_row);
                var item = window.table.row(v_row).data();

                console.log("ROM ITEM: ", item);
                console.log("ROM ITEM ORDINE: ", item.ordine);


                console.log("VAL: ", $(this).val());


                console.log("SEL TIPO ASS: ", $("#sel_tipo_ass_" + v_day + "_" + item.ordine));


                var v_day = item.day; // valore giorno

                var v_forz = "N";
                var v_note = $("#sel_desc_" + v_day + "_" + item.ordine).val()
                var v_h_ord = $("#sel_ordinario_" + v_day + "_" + item.ordine).val();
                var v_h_straord = $("#sel_eccedenze_" + v_day + "_" + item.ordine).val();
                var v_h_act = $("#sel_ore_ass_" + v_day + "_" + item.ordine).val();
                var v_id_type_act = $("#sel_tipo_ass_" + v_day + "_" + item.ordine).val();
                var v_h_act2 = $("#sel_ore_ass_2_" + v_day + "_" + item.ordine).val();
                var v_id_type_act2 = $("#sel_tipo_ass_2_" + v_day + "_" + item.ordine).val();
                var v_totale = $("#sel_ore_totali_" + v_day + "_" + item.ordine).html();


                var v_id_prj = that.idProjDefault;

                if (that.countProjAssoc > 1) {

                    v_id_prj = $("#sel_prj_" + v_day + "_" + item.ordine).val();

                }



                var v_desc_day = $("#desc_day_" + v_day + "_" + item.ordine).html();
                var v_h_rep = $("#sel_reperibilita_" + v_day + "_" + item.ordine).val();

                var v_h_int = 0;
                var v_h_from_int = "";
                var v_h_to_int = "";

                if (that.showColumns == true) {

                    v_h_int = $("#sel_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_from_int = $("#sel_ora_da_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_to_int = $("#sel_ora_a_intervento_" + v_day + "_" + item.ordine).val();

                }

                console.log("ORA FROM: ", v_h_from_int);
                console.log("ORA TO: ", v_h_to_int);

                console.log("VAL GIORNO: ", v_day);
                console.log("VAL FORZATURA: ", v_forz);


                var check_sede_cliente = 0;
                if ($("#sel_sede_cliente_" + v_day + "_" + item.ordine).prop("checked")) {
                    check_sede_cliente = 1;
                }



                data_timesheet.push({
                    day_modified: $(this).val(),
                    forzatura: v_forz,
                    note: v_note,
                    year: that.curYear,
                    month: that.curMonth,
                    day: v_day,
                    hour_ordinario: v_h_ord,
                    hour_straordinario: v_h_straord,
                    hour_activity: v_h_act,
                    idtypeactivity: v_id_type_act,
                    hour_activity2: v_h_act2,
                    idtypeactivity2: v_id_type_act2,
                    projectid: v_id_prj,
                    desc_day: v_desc_day,
                    is_fest: $(this).attr("is-fest"),
                    hour_reperibilita: v_h_rep,
                    hour_intervento: v_h_int,
                    hour_from_intervento: that.formatDateTime(v_h_from_int),
                    hour_to_intervento: that.formatDateTime(v_h_to_int),
                    ordine: item.ordine,
                    sede_cliente: check_sede_cliente,
                    ore_totali: v_totale
                });

            });

            console.log("DATA TS: ", data_timesheet);


            var errore = "";

            if (that.countProjAssoc < 2) {

                for (var x = 0; x <= data_timesheet.length - 1; x++) {

                    var giorno = data_timesheet[x];


                    if ((giorno.desc_day.indexOf("Sab") == -1) && (giorno.desc_day.indexOf("Dom") == -1)) {

                        if (parseInt(giorno.idtypeactivity) != 8)  // diverso da non in forza
                        {

                            if (errore == "") {

                                if (parseFloat(giorno.hour_activity) > 0) // sono presenti delle ore di assenza nella gionata
                                {

                                    if (parseInt(giorno.idtypeactivity) == 0) // non è stata specificata la causale
                                    {

                                        errore = "Specificare una causale per le ore di assenza del giorno " + giorno.day;

                                    }


                                }


                            }


                            if (errore == "") {

                                if (parseInt(giorno.idtypeactivity) > 0) // è stata specificata la causale
                                {

                                    if (parseFloat(giorno.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                    {

                                        errore = "Specificare le ore di assenza per il giorno " + giorno.day;


                                    }



                                }

                            }



                            if (errore == "") {

                                if (parseInt(giorno.is_fest) == 1) // giorno festivo
                                {


                                    if (parseInt(giorno.day_modified) == 1) {


                                        if (parseFloat(giorno.hour_straordinario) == 0) {

                                            if (giorno.idtypeactivity != 5) {

                                                errore = "Non è possibile specificare un'assenza diversa dal festivo per il giorno " + giorno.day;



                                            }



                                        }


                                    }




                                }



                            }


                            if (errore == "") {

                                if (parseFloat(giorno.hour_reperibilita) == 0) {


                                    if (parseFloat(giorno.hour_intervento) > 0) {


                                        errore = "Specificare la reperibilità per il giorno " + giorno.day;


                                    }


                                }

                            }

                            if (errore == "") {

                                if (parseFloat(giorno.hour_reperibilita) > 0) {


                                    if (parseFloat(giorno.hour_intervento) > 0) {


                                        if (parseFloat(giorno.hour_reperibilita) < parseFloat(giorno.hour_intervento)) {

                                            errore = "La reperibilità deve essere maggiore o uguale dell'intervento per il giorno " + giorno.day;

                                        }




                                    }


                                }


                            }


                            if (errore == "") {

                                if (parseFloat(giorno.hour_intervento) > 0) {

                                    if ((giorno.hour_from_intervento == "") || (giorno.hour_to_intervento == "")) {

                                        errore = "Specificare Ora Da e Ora A dell'intervento per il giorno " + giorno.day;

                                    }

                                }


                            }


                            if (errore == "") {

                                if (parseFloat(giorno.hour_intervento) > 0) {

                                    var date1 = "2023-01-01 " + giorno.hour_from_intervento;
                                    var date2 = "2023-01-01 " + giorno.hour_to_intervento;

                                    date1 = new Date(date1);
                                    date2 = new Date(date2);

                                    if (date2 < date1) {

                                        errore = "Il valore di Ora A deve essere maggiore di Ora Da per il giorno " + giorno.day;


                                    }



                                }


                            }


                            if (errore == "") {

                                if ((giorno.hour_from_intervento != "") && (giorno.hour_to_intervento != "")) {

                                    if ((giorno.hour_from_intervento != "<nil>") && (giorno.hour_to_intervento != "<nil>")) {

                                        if (parseFloat(giorno.hour_intervento) == 0) {

                                            errore = "Specificare il totale ore intervento per il giorno " + giorno.day;


                                        }


                                    }



                                }


                            }




                            if (errore == "") {

                                if (giorno.hour_straordinario == "") {

                                    errore = "Specificare un valore per lo straordinario per il giorno " + giorno.day;


                                }


                            }

                            if (errore == "") {

                                if (giorno.hour_activity == "") {

                                    errore = "Specificare un valore per le ore di assenza per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                if ((parseFloat(giorno.hour_ordinario) == 0) && (parseFloat(giorno.hour_activity) == 0)) {

                                    errore = "Specificare un valore per le ore di assenza per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                var h_ord = parseFloat(giorno.hour_ordinario);
                                var h_straord = parseFloat(giorno.hour_straordinario);
                                var h_ass = parseFloat(giorno.hour_activity);

                                if ((h_ord == 0) && (h_straord > 0) && (h_ass > 0)) {

                                    errore = "Specificare delle ore ordinarie per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                var h_ord2 = parseFloat(giorno.hour_ordinario);
                                var h_straord2 = parseFloat(giorno.hour_straordinario);
                                var h_ass2 = parseFloat(giorno.hour_activity);

                                if ((h_ord2 == 0) && (h_straord2 > 0) && (h_ass2 > 0)) {

                                    errore = "Specificare delle ore ordinarie per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                var h_straord3 = parseFloat(giorno.hour_straordinario);

                                if (h_straord3 > 10) {

                                    errore = "Le ore di straordinario devono essere al massimo 10 per il giorno " + giorno.day;


                                }

                            }




                        }




                    }

                }


            }



            if (that.countProjAssoc >= 2) {

                for (var x2 = 0; x2 <= data_timesheet.length - 1; x2++) {

                    var giorno2 = data_timesheet[x2];

                    if ((giorno2.desc_day.indexOf("Sab") == -1) && (giorno2.desc_day.indexOf("Dom") == -1)) {

                        if (errore == "") {

                            if (parseFloat(giorno2.hour_activity) > 0) // sono presenti delle ore di assenza nella gionata
                            {

                                if (parseInt(giorno2.idtypeactivity) == 0) // non è stata specificata la causale
                                {

                                    errore = "Specificare una causale per le ore di assenza del giorno " + giorno2.day;

                                }


                            }


                        }

                        if (errore == "") {

                            if (parseInt(giorno2.idtypeactivity) > 0) // è stata specificata la causale
                            {

                                if (parseFloat(giorno2.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                {

                                    errore = "Specificare le ore di assenza per il giorno " + giorno2.day;


                                }



                            }

                        }

                    }






                    if (errore == "") {

                        if (parseFloat(giorno2.hour_ordinario) > 0) // ci sono delle ore di attivita
                        {

                            if (giorno2.projectid == 0) // non è stato specificato il progettogionata
                            {

                                errore = "Specificare il progetto per il giorno " + giorno2.day;


                            }



                        }

                    }

                    if ((giorno2.desc_day.indexOf("Sab") == -1) && (giorno2.desc_day.indexOf("Dom") == -1)) {

                        if (errore == "") {

                            if (parseInt(giorno2.hour_ordinario) == 0) // non sono presenti le ore ordinarie
                            {

                                if (parseFloat(giorno2.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                {

                                    errore = "Specificare le ore ordinarie per il giorno " + giorno2.day;


                                }



                            }

                        }

                    }


                    if (giorno2.projectid > 0) {

                        if (parseFloat(giorno2.hour_ordinario) == 0) {
                            errore = "Specificare le ore ordinarie per il giorno " + giorno2.day;

                        }


                    }







                }



            }




            if (errore == "") {

                console.log("ok");

                that.saveData();

            }
            else {

                window.numEventASave = 0;

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                });


            }


        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        getIdProgettoAssociato: async function () {

            var that = this;

            var v_token = window.$cookies.get("sel_token");


            that.showSpinner = true;

            var response = await apitimesheet.getIdProgettoAssociato(
                atob(v_token),
                that.curYear,
                that.curMonth
            ).then(async (res) => {

                that.showSpinner = false;

                console.log("res from getIdProgettoAssociato", res);



                that.progetti = res.data.FilterProgetti;



            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        getIdProjectDefault: async function () {

            var that = this;

            var v_token = window.$cookies.get("sel_token");


            that.showSpinner = true;

            var response = await apitimesheet.goGetIdProgettoDefault(
                v_token,
                that.curYear,
                that.curMonth
            ).then((res) => {

                that.showSpinner = false;

                console.log("res from goGetIdProgettoDefault", res);

                that.idProjDefault = res.data.Result;

                that.IsPartiIVAPartTime = res.data.IsPartiIVAPartTime;

                that.countProjAssoc = res.data.CntProj;


                that.showColumns = false;

                if (res.data.ViewColInterv == 1) {
                    that.showColumns = true;

                }

                if (that.countProjAssoc < 2) {


                    $('.selprogetti').hide();


                }

                if (that.countProjAssoc > 1) {


                    var projectsValues = [];

                    for (var xx = 0; xx < res.data.Projects.length; xx++) {

                        projectsValues.push({
                            projectid: res.data.Projects[xx].projectid,
                            codice: res.data.Projects[xx].codice,
                            ore: 0
                        });

                    }

                    console.log("PROJECTS: ", projectsValues);

                    that.valuesProjects = projectsValues;

                }



            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },

        saveAndCloseMoreProjs: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);



            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {


                for (var xOrdine = 1; xOrdine <= 3; xOrdine++) {

                    var strIndex = xx.toString() + "_" + xOrdine.toString();

                    if (strIndex in that.monthArray) {

                        console.log("GIORNO; ", that.monthArray[strIndex]);

                        var hourFromIntervento = "";
                        if (that.monthArray[strIndex].hour_from_intervento != null) {
                            hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                        }

                        var hourToIntervento = "";
                        if (that.monthArray[strIndex].hour_from_intervento != null) {
                            hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                        }

                        var showCols = 0;
                        if (that.viewInterv == "S") {
                            showCols = 1;
                        }

                        var strNote = "";
                        if (that.monthArray[strIndex].note != null) {
                            strNote = that.monthArray[strIndex].note;
                        }

                        data_timesheet.push({
                            day: that.monthArray[strIndex].day,
                            day_modified: 0,
                            forzatura: "N",
                            hour_activity: that.monthArray[strIndex].hour_activity,
                            hour_activity2: 0,
                            hour_from_intervento: hourFromIntervento,
                            hour_intervento: that.monthArray[strIndex].hour_intervento,
                            hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                            hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                            hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                            hour_to_intervento: hourToIntervento,
                            idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                            idtypeactivity2: 0,
                            month: that.curMonth,
                            note: strNote,
                            ordine: xOrdine,
                            projectid: that.monthArray[strIndex].id_progetto,
                            sede_cliente: 0,
                            show_cols: showCols,
                            year: that.curYear
                        });


                    }



                }







            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;


            var response = await apitimesheet.saveAndClose(

                that.curYear,
                that.curMonth,
                window.$cookies.get("sel_login_ver_ts"),
                JSON.stringify(data_timesheet),
                v_token

            ).then((res) => {

                that.showSpinner = false;

                console.log("res from saveAndClose", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati con successo",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    router.push({
                        path: "/verificaTimesheet"
                    });

                }, 2000);

            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        saveAndOpenMoreProjs: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);



            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {


                for (var xOrdine = 1; xOrdine <= 3; xOrdine++) {

                    var strIndex = xx.toString() + "_" + xOrdine.toString();

                    if (strIndex in that.monthArray) {

                        console.log("GIORNO; ", that.monthArray[strIndex]);

                        var hourFromIntervento = "";
                        if (that.monthArray[strIndex].hour_from_intervento != null) {
                            hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                        }

                        var hourToIntervento = "";
                        if (that.monthArray[strIndex].hour_from_intervento != null) {
                            hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                        }

                        var showCols = 0;
                        if (that.viewInterv == "S") {
                            showCols = 1;
                        }

                        var strNote = "";
                        if (that.monthArray[strIndex].note != null) {
                            strNote = that.monthArray[strIndex].note;
                        }

                        data_timesheet.push({
                            day: that.monthArray[strIndex].day,
                            day_modified: 0,
                            forzatura: "N",
                            hour_activity: that.monthArray[strIndex].hour_activity,
                            hour_activity2: 0,
                            hour_from_intervento: hourFromIntervento,
                            hour_intervento: that.monthArray[strIndex].hour_intervento,
                            hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                            hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                            hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                            hour_to_intervento: hourToIntervento,
                            idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                            idtypeactivity2: 0,
                            month: that.curMonth,
                            note: strNote,
                            ordine: xOrdine,
                            projectid: that.monthArray[strIndex].id_progetto,
                            sede_cliente: 0,
                            show_cols: showCols,
                            year: that.curYear
                        });


                    }



                }







            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;


            var response = await apitimesheet.saveAndOpen(

                that.curYear,
                that.curMonth,
                window.$cookies.get("sel_login_ver_ts"),
                JSON.stringify(data_timesheet),
                v_token

            ).then((res) => {

                that.showSpinner = false;

                console.log("res from saveAndOpen", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati con successo",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    router.push({
                        path: "/verificaTimesheet"
                    });

                }, 2000);

            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        saveAndOpen: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);

            var v_id_prj = that.idProjDefault;

            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {

                var strIndex = xx.toString() + "_1";

                console.log("GIORNO; ", that.monthArray[strIndex]);

                var hourFromIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                }

                var hourToIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                }

                var showCols = 0;
                if (that.viewInterv == "S") {
                    showCols = 1;
                }

                var strNote = "";
                if (that.monthArray[strIndex].note != null) {
                    strNote = that.monthArray[strIndex].note;
                }

                data_timesheet.push({
                    day: that.monthArray[strIndex].day,
                    day_modified: 0,
                    forzatura: "N",
                    hour_activity: that.monthArray[strIndex].hour_activity,
                    hour_activity2: 0,
                    hour_from_intervento: hourFromIntervento,
                    hour_intervento: that.monthArray[strIndex].hour_intervento,
                    hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                    hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                    hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                    hour_to_intervento: hourToIntervento,
                    idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                    idtypeactivity2: 0,
                    month: that.curMonth,
                    note: strNote,
                    ordine: 1,
                    projectid: v_id_prj,
                    sede_cliente: 0,
                    show_cols: showCols,
                    year: that.curYear
                });

            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;


            var response = await apitimesheet.saveAndOpen(

                that.curYear,
                that.curMonth,
                window.$cookies.get("sel_login_ver_ts"),
                JSON.stringify(data_timesheet),
                v_token

            ).then((res) => {

                that.showSpinner = false;

                console.log("res from saveAndOpen", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati con successo",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    router.push({
                        path: "/verificaTimesheet"
                    });

                }, 2000);

            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        saveAndClose: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);

            var v_id_prj = that.idProjDefault;

            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {

                var strIndex = xx.toString() + "_1";

                console.log("GIORNO; ", that.monthArray[strIndex]);

                var hourFromIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                }

                var hourToIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                }

                var showCols = 0;
                if (that.viewInterv == "S") {
                    showCols = 1;
                }

                var strNote = "";
                if (that.monthArray[strIndex].note != null) {
                    strNote = that.monthArray[strIndex].note;
                }

                data_timesheet.push({
                    day: that.monthArray[strIndex].day,
                    day_modified: 0,
                    forzatura: "N",
                    hour_activity: that.monthArray[strIndex].hour_activity,
                    hour_activity2: 0,
                    hour_from_intervento: hourFromIntervento,
                    hour_intervento: that.monthArray[strIndex].hour_intervento,
                    hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                    hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                    hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                    hour_to_intervento: hourToIntervento,
                    idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                    idtypeactivity2: 0,
                    month: that.curMonth,
                    note: strNote,
                    ordine: 1,
                    projectid: v_id_prj,
                    sede_cliente: 0,
                    show_cols: showCols,
                    year: that.curYear
                });

            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;


            var response = await apitimesheet.saveAndClose(

                that.curYear,
                that.curMonth,
                window.$cookies.get("sel_login_ver_ts"),
                JSON.stringify(data_timesheet),
                v_token

            ).then((res) => {

                that.showSpinner = false;

                console.log("res from saveAndClose", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati con successo",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    router.push({
                        path: "/verificaTimesheet"
                    });

                }, 2000);

            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },



        saveDataForAppr: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var params = that.getParamsApi();

            console.log("SAVE_ADN_OPEN");
            console.log("CUR_YEAR: ", that.curYear);
            console.log("CUR_MONTH: ", that.curMonth);
            console.log("USR_SEL: ", atob(params.token));

            var data_timesheet = [];

            $(".fldchanged").each(function (index) {

                console.log(index + ": " + $(this).text());

                console.log("VAL: ", $(this).val());

                var v_row = $(this).parent().parent();

                console.log("V_ROW: ", v_row);
                var item = window.table.row(v_row).data();

                // var v_day = index + 1; // valore giorno
                var v_day = item.day;
                var v_forz = "N";
                var v_note = $("#sel_desc_" + v_day + "_" + item.ordine).val();
                var v_h_ord = $("#sel_ordinario_" + v_day + "_" + item.ordine).val();
                var v_h_straord = $("#sel_eccedenze_" + v_day + "_" + item.ordine).val();
                var v_h_act = $("#sel_ore_ass_" + v_day + "_" + item.ordine).val();
                var v_id_type_act = $("#sel_tipo_ass_" + v_day + "_" + item.ordine).val();

                var v_id_prj = that.idProjDefault;

                if (that.countProjAssoc > 1) {

                    v_id_prj = $("#sel_prj_" + v_day + "_" + item.ordine).val();

                }

                console.log("VAL GIORNO: ", v_day);
                console.log("VAL FORZATURA: ", v_forz);

                var dataSpecifica = new Date(that.curYear, that.curMonth - 1, v_day);
                var opzioniNomeGiorno = { weekday: 'long' };
                var nomeGiorno = dataSpecifica.toLocaleDateString('it-IT', opzioniNomeGiorno);

                console.log("NOME GIORNO: ", nomeGiorno);


                if ((nomeGiorno == "sabato") || (nomeGiorno == "domenica")) {
                    v_id_prj = 0;

                }

                var v_h_rep = $("#sel_reperibilita_" + v_day + "_" + item.ordine).val();

                var v_h_int = 0;
                var v_h_from_int = "";
                var v_h_to_int = "";

                var v_show_cols = 0;

                if (that.showColumns == true) {

                    v_h_int = $("#sel_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_from_int = $("#sel_ora_da_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_to_int = $("#sel_ora_a_intervento_" + v_day + "_" + item.ordine).val();

                    v_show_cols = 1;
                }

                var check_sede_cliente = 0;
                if ($("#sel_sede_cliente_" + v_day + "_" + item.ordine).prop("checked")) {
                    check_sede_cliente = 1;
                }

                data_timesheet.push({
                    day_modified: $(this).val(),
                    forzatura: v_forz,
                    note: v_note,
                    year: that.curYear,
                    month: that.curMonth,
                    day: v_day,
                    hour_ordinario: v_h_ord,
                    hour_straordinario: v_h_straord,
                    hour_activity: v_h_act,
                    idtypeactivity: v_id_type_act,
                    hour_activity2: 0,
                    idtypeactivity2: 0,
                    projectid: v_id_prj,
                    hour_reperibilita: v_h_rep,
                    hour_intervento: v_h_int,
                    hour_from_intervento: that.formatDateTime(v_h_from_int),
                    hour_to_intervento: that.formatDateTime(v_h_to_int),
                    ordine: item.ordine,
                    show_cols: v_show_cols,
                    sede_cliente: check_sede_cliente
                });

            });

            console.log("DATA TS: ", data_timesheet);

            this.showSpinner = true;

            var v_key = this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");
            v_key = v_key + "|" + that.curYear.toString();
            v_key = v_key + "|" + that.curMonth.toString();
            v_key = v_key + "|" + atob(params.token);
            v_key = v_key + "|" + JSON.stringify(data_timesheet);
            v_key = v_key + "|" + v_token;
            v_key = v_key + "|" + this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");
            v_key = btoa(v_key);
            v_key = this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");


            var response = await apitimesheet.saveDataNew(

                v_key

            ).then(async (res) => {

                console.log(res);

                setTimeout(() => {

                    var response2 = that.updateStatusMonth(2).then((res2) => {

                        that.showSpinner = false;

                        if (res2.status == 200) {
                            // this.$swal("Il timesheet è stato inviato correttamente");
                            that.$swal({
                                icon: "success",
                                text: "Il timesheet è stato inviato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });
                            that.syncMonth();
                        } else {
                            that.$swal({
                                icon: "error",
                                text: "Il timesheet non è stato inviato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }
                    });

                    console.log("Response", response2);

                }, 1000);





            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },





        setFlagFldChanged: function (v_day) {

            console.log("FLAG CHANGED");

            $("#fldchanged_" + v_day).val("1");

        },



        funcEvent: function () {

            var that = this;

            $(".selordinario").off();

            $(".selordinario").on("ionChange", function (opt) {

                console.log("NUM EVENT ORD: ", window.numEventOrdinario);

                if (window.numEventOrdinario == 0) {

                    // alert("evento")

                    console.log(opt);


                    //console.log("ID: ", $(this).attr("id"));

                    var val_data_hour_ordinario = $(this).attr("id");
                    var val_day = val_data_hour_ordinario.split("_")[2];
                    //var val_forzatura = $("#sel_forzatura_" + val_day).val();
                    // var val_forzatura = "N";

                    // var hour_ordinario = this.value;

                    window.numEventOrdinario = window.numEventOrdinario + 1;

                    // that.calcOvtAbs(val_day, val_forzatura, hour_ordinario);

                    that.setFlagFldChanged(val_day);

                }

            });

            $(".selforzatura").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[2];

                console.log("DAY: ", val_day);

                if (this.value == "S") {

                    $("#sel_eccedenze_" + val_day).removeAttr("readonly");
                    $("#sel_ore_ass_" + val_day).removeAttr("readonly");

                }

                if (this.value == "N") {

                    $("#sel_eccedenze_" + val_day).val("0.00");
                    $("#sel_eccedenze_" + val_day).attr('readonly', true);

                    $("#sel_ore_ass_" + val_day).val("0.00");
                    $("#sel_ore_ass_" + val_day).attr('readonly', true);

                }

                that.setFlagFldChanged(val_day);

            });



            $(".selstraordinario").on("ionBlur", function (opt) {

                console.log(opt);

                that.setFlagFldChanged(val_day);

            });

            $(".selassenza").on("ionBlur", function (opt) {

                console.log(opt);

                that.setFlagFldChanged(val_day);

            });


            $(".seltipoass1").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[3];

                that.setFlagFldChanged(val_day);

            });

            $(".oreass2").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[4];

                that.setFlagFldChanged(val_day);

            });

            $(".seltipoass2").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[3];

                that.setFlagFldChanged(val_day);

            });

            $(".seledesc").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[2];

                that.setFlagFldChanged(val_day);

            });




            $("#btn_avanti_dip").on("click", function (opt) {

                console.log(opt);

                if (window.numEventDipAvanti == 0) {

                    //console.log("CUR DIP: ", that.curResource);

                    window.numEventDipAvanti = window.numEventDipAvanti + 1;

                    var cur_pos = 0;
                    var find_pos = 0;

                    for (var dip of that.dipendenti) {

                        if (that.curResource == dip.fld_name) {
                            find_pos = cur_pos;
                        }

                        cur_pos = cur_pos + 1;

                    }

                    console.log("CUR POS DIP: ", find_pos);

                    find_pos = find_pos + 1;

                    console.log("NEXT POS DIP: ", find_pos);

                    console.log("NEXT DIP: ", that.dipendenti[find_pos]);

                    that.manageClickDipendenti(that.dipendenti[find_pos]);


                }

            });


            $("#btn_indietro_dip").on("click", function (opt) {

                console.log(opt);

                if (window.numEventDipIndietro == 0) {

                    console.log("CUR DIP 1: ", that.curResource);

                    window.numEventDipIndietro = window.numEventDipIndietro + 1;

                    var cur_pos = 0;
                    var find_pos = 0;

                    for (var dip of that.dipendenti) {

                        if (that.curResource == dip.fld_name) {
                            find_pos = cur_pos;
                        }

                        cur_pos = cur_pos + 1;

                    }

                    console.log("CUR POS DIP: ", find_pos);

                    find_pos = find_pos - 1;

                    console.log("NEXT POS DIP: ", find_pos);

                    console.log("NEXT DIP: ", that.dipendenti[find_pos]);

                    that.manageClickDipendenti(that.dipendenti[find_pos]);


                }

            });










        },

        manageEbadge: function () {
            setTimeout(() => {
                // alert ( this.tab );

            }, 300);
        },

        resetDay: function () {
            this.date = "";
            this.lastDayId = "";
            this.dayStatusSelected = 0;
        },



        changeStatus: async function (status) {
            console.log(status);
            this.sheetStatus = false;
            var statusInt = 0;

            if (status == "Aperto") statusInt = 1;
            if (status == "For approval") statusInt = 2;
            if (status == "Chiuso") statusInt = 3;
            console.log("Stato int", statusInt);

            //this.$root.$children[0].showProgress = true ;
            this.showSpinner = true;

            var response = await this.updateStatusMonthAdmin(statusInt).then((res) => {
                if (res.status == 200) {
                    // this.$swal("Il timesheet è stato inviato correttamente");
                    this.$swal({
                        icon: "success",
                        text: "Il timesheet è stato aggiornato correttamente",
                        showConfirmButton: false,
                        timer: 500
                    });
                    this.syncMonth();
                } else {
                    this.$swal({
                        icon: "error",
                        text: "Il timesheet non è stato aggiornato correttamente",
                    });
                }
            });
            //this.$root.$children[0].showProgress = false ;
            this.showSpinner = false;

            console.log("Response", response);

        },

        clearSearch() {
            this.search = "";
        },
        clearSearchDipendenti() {
            this.searchDipendenti = "";
        },

        sumHours: function (event) {

            var operation = "+";

            if (event.target.classList.contains('minus')) {
                operation = '-';
            }

            if (event) {
                var dataid = event.target.getAttribute("data-id");
                var disabled = event.target.attributes.getNamedItem('disabled');

                console.log("DATAID", dataid);
                console.log("DISABLED", disabled);

                console.log("PO", this.piano_orario);
                console.log("curDay", this.curDay);

                var strDay = new Date(this.curYear, this.curMonth - 1, this.curDay);
                //console.log ("dw", strDay.getDay() );
                var mapDay = {
                    "0": "domenica",
                    "1": "lunedi",
                    "2": "martedi",
                    "3": "mercoledi",
                    "4": "giovedi",
                    "5": "venerdi",
                    "6": "sabato"
                };

                var po = this.piano_orario[0];
                var dayLiteral = mapDay[strDay.getDay()];

                var oretoday = parseFloat(po[dayLiteral]);

                var max = 10;
                max = oretoday;
                var index = 0;
                var newQuant;
                if (dataid == 'Ordinari') index = 0;
                if (dataid == 'Straordinari') index = 1;
                if (dataid == 'Assenza') index = 2;
                if (dataid == 'Reperibilità') {
                    max = 20;
                    index = 6;
                }
                if (dataid == 'Intervento') {
                    max = 20;
                    index = 7;
                }

                console.log("quantita", this.forecast[index].quantity);

                if (disabled == null) {

                    if (operation == '+')
                        newQuant = parseFloat(this.forecast[index].quantity) + 0.5;
                    else
                        newQuant = parseFloat(this.forecast[index].quantity) - 0.5;

                    if (newQuant <= max && operation == '+') {
                        this.forecast[index].quantity = newQuant.toString();

                    }

                    if (newQuant >= 0 && operation == '-') {
                        this.forecast[index].quantity = newQuant.toString();

                    }

                    var diff = 0;
                    //adapta absence with ordinary , user cliecked ordinari so check if more than usual
                    if (dataid == 'Ordinari') {
                        if (newQuant <= oretoday) {
                            diff = oretoday - newQuant;
                            console.log("Diff:", diff);
                            //needed to set absense
                            if (diff > oretoday) diff = oretoday;
                            this.forecast[2].quantity = diff.toString();
                        }
                    }

                    if (dataid == 'Assenza') {
                        if (newQuant <= oretoday) {
                            diff = oretoday - newQuant;
                            console.log("Diff:", diff);
                            //needed to set absense
                            if (diff > oretoday) diff = oretoday;
                            this.forecast[0].quantity = diff.toString();
                        }
                    }

                    this.updateSumDay();
                }

            }

        },

        sumHours2: function (event) {

            var operation = "+";

            if (event.target.classList.contains('minus')) {
                operation = '-';
            }

            if (event) {
                var dataid = event.target.getAttribute("data-id");

                var disabled = event.target.attributes.getNamedItem('disabled');

                var index = 0;
                if (dataid == 'Dalle') index = 8;
                if (dataid == 'Alle') index = 9;



                var lista_orari = this.lista_orari;

                console.log("LISTA_ORARI 2:", lista_orari);


                if (disabled == null) {

                    var newIndex = 0;

                    if (dataid == "Dalle") {

                        for (var ind in lista_orari) {

                            if (operation == '+') {
                                if (lista_orari[ind] == this.forecast[index].timeStart) {



                                    newIndex = parseInt(ind) + 1;


                                }

                            }

                            if (operation == '-') {
                                if (lista_orari[ind] == this.forecast[index].timeStart) {

                                    newIndex = parseInt(ind) - 1;
                                }

                            }


                        }
                    }

                    if (dataid == "Alle") {

                        for (var ind2 in lista_orari) {

                            if (operation == '+') {
                                if (lista_orari[ind2] == this.forecast[index].timeEnd) {

                                    newIndex = parseInt(ind2) + 1;
                                }

                            }

                            if (operation == '-') {
                                if (lista_orari[ind2] == this.forecast[index].timeEnd) {

                                    newIndex = parseInt(ind2) - 1;
                                }

                            }


                        }
                    }


                    var startIndex = 0;
                    var endIndex = lista_orari.length - 1;

                    console.log("START INDEX: ", startIndex);
                    console.log("END INDEX: ", endIndex);



                    if (dataid == "Dalle") {

                        if ((newIndex >= startIndex) && (newIndex <= endIndex)) {

                            this.forecast[index].timeStart = lista_orari[newIndex.toString()];


                            this.monthArray[this.curDay + "_1"].hour_from_intervento = this.forecast[index].timeStart;


                        }


                    }

                    if (dataid == "Alle") {

                        if ((newIndex >= startIndex) && (newIndex <= endIndex)) {

                            this.forecast[index].timeEnd = lista_orari[newIndex.toString()];

                            this.monthArray[this.curDay + "_1"].hour_to_intervento = this.forecast[index].timeEnd;
                        }



                    }

                    console.log("NewIndex", newIndex);

                }



            }

        },

        manageClickDipendenti: function (dipendente) {

            this.sheetDipendenti = false;

            window.numEventDipAvanti = 0;
            window.numEventDipIndietro = 0;

            console.log(dipendente);

            this.currDipendente = dipendente.resourceid;

            this.resetDay();

            this.changeDip();

            setTimeout(() => {

                this.syncMonth();

                //console.log( "dipobj" ,this.currDipendenteObj);
                this.isDipEbadge = parseInt(this.currDipendenteObj.is_ebadge);

                this.entrata1 = "";
                this.entrata2 = "";
                this.uscita1 = "";
                this.uscita2 = "";

            }, 100);

        },

        manageClickAziende: async function (azienda) {

            //console.log(azienda);

            var v_token = window.$cookies.get("token");

            this.currAzienda = azienda;

            // console.log("CUR YEAR", this.curYear);
            // console.log("CUR MONTH", this.curMonth);

            this.$root.$children[0].showProgress = true;

            var response = await apitimesheet.getDipendenti(
                azienda,
                v_token,
                this.curYear,
                this.curMonth
            ).then((res) => {

                this.$root.$children[0].showProgress = false;

                //that.$root.$children[0].showProgress = false;
                console.log("res from getDipendenti", res);


                var arrDipendenti = [];

                for (var dip of res.data.Dipendenti) {

                    arrDipendenti.push(dip);

                }


                this.dipendenti = arrDipendenti;

                setTimeout(() => {
                    this.sheetAziende = false;
                    this.sheetDipendenti = true;
                }, 300);



                //  console.log("ARR DIP: ", arrDipendenti);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }


            );



            // await this.syncArrayDipendenti();

            // console.log("CurAzienda", this.currAzienda);
            // console.log(this.dipendenti);

            // setTimeout(() => {
            //     this.sheetAziende = false;
            //     this.sheetDipendenti = true;
            // }, 300);

        },

        changeDip: function (selectObj) {
            console.log(selectObj);
            /*  console.log("Oggetto :", selectObj);
             console.log(selectObj.src);

             console.log(this.currDipendente);
             console.log(this.dipendenti); */

            //var selNameDip = "";
            for (const [key, dip] of Object.entries(this.dipendenti)) {
                console.log("key", key);
                if (parseInt(dip.resourceid) == this.currDipendente) {
                    this.currDipendenteObj = dip;
                    console.log("dip", dip);
                    //selNameDip = dip.fld_name;

                }
            }

            //  this.$root.$children[0].curDipTop = selNameDip;

        },

        updateStatusMonthAdmin: async function (status) {

            console.log("currDipObj", this.currDipendenteObj);

            //    return false ;// eslint-disable no-unreachable

            var v_token = window.$cookies.get("token");

            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");
            var user_id = window.$cookies.get("user_id");

            if (this.currDipendenteObj.resourceid !== undefined) {
                //alert (  this.currDipendenteObj.resourceid  );
                system = this.currDipendenteObj.v_res_id;
                token = this.currDipendenteObj.v_user;
                user_id = this.currDipendenteObj.v_user_id;

            }

            var success = 1;
            var response = null;
            try {
                response = await this.$api.tsUpdateStatus(
                    token,
                    system,
                    user_id,
                    status,
                    this.curYear,
                    this.curMonth,
                    v_token
                );
            } catch (error) {
                success = 0;
                console.log(error);
            }
            console.log(response, status, success, token, system, user_id);
            return response;
        },

        updateStatusMonth: async function (status) {
            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");
            var user_id = window.$cookies.get("user_id");
            //  var username = window.$cookies.get("username");
            //system => resourceid
            //token_system=>pass encodata
            //username

            var success = 1;
            var response = null;

            var v_key = this.generateRandomString(50, "gWnKdU7d4Sb58hFbL4G6");
            v_key = v_key + "|" + token.toString();
            v_key = v_key + "|" + system.toString();
            v_key = v_key + "|" + user_id.toString();
            v_key = v_key + "|" + status.toString();
            v_key = v_key + "|" + this.curYear.toString();
            v_key = v_key + "|" + this.curMonth.toString();
            v_key = v_key + "|" + this.generateRandomString(50, "gWnKdU7d4Sb58hFbL4G6");
            v_key = btoa(v_key);
            v_key = this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

            try {
                response = await this.$api.tsUpdateStatusApprovalNew(
                    v_key
                );
            } catch (error) {
                success = 0;
                console.log(error);
            }

            console.log(response, status, success);

            return response;
        },

        setupNavTop: async function () {
            // this.setupNavBottomDone = true ;
            /*   var disabled = false;
              if (this.curStatus == "Close" || this.curStatus == "For Approval") {
                disabled = true;
              } */


            var icon = "mdi-account-lock-open";
            var descStatus = "Aperto";
            if (this.curStatus == "Close") {
                icon = "mdi-account-lock";
                descStatus = "Chiuso";
            }
            if (this.curStatus == "For approval") {
                icon = "mdi-account-arrow-right";
                descStatus = "In Approvazione";
            }


            var color = this.colorsStati[this.curStatus];

            console.log("Color status :", color);

            var buttonsTop = [{
                text: "",
                icon: icon,
                link: "",
                id: "btn_status",
                disabled: false,
                color: color,
                title: descStatus
            }];

            this.$root.$children[0].buttonsTop = buttonsTop;
            //console.log ( icon );

            this.$root.$children[0].buttonsTop = [];

            $("#userRed").hide();
            $("#userYellow").hide();
            $("#userGreen").hide();

            if (descStatus == "Aperto") {

                $("#userRed").show();


            }

            if (descStatus == "In Approvazione") {

                $("#userYellow").show();

            }


            if (descStatus == "Chiuso") {

                $("#userGreen").show();

            }

        },

        setupNavBottom: async function () {



            this.setupNavBottomDone = true;
            var disabled = false;
            if (this.curStatus == "Close" || this.curStatus == "For approval") {
                disabled = true;
            }

            if (this.aa == 'Y' || this.su == 'Y') {
                disabled = false;
            }

            var icon = "mdi-account-lock-open";
            if (this.curStatus == "Close") icon = "mdi-account-lock";
            if (this.curStatus == "For approval") icon = "mdi-account-arrow-right";

            console.log(icon);
            console.log(disabled);

            setTimeout(() => {



                var pulsantis = [];

                pulsantis.push(

                    {
                        text: "Ricarica",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/refresh-emt.png",
                        link: "",
                        id: "btn_refr_tms",
                        disabled: false,
                        title: "Aggiorna",
                        width: 30
                    },

                );

                pulsantis.push(
                    {
                        text: "",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/save-and-open-emt.png",
                        link: "",
                        id: "btn_save_open",
                        disabled: false,
                        title: "Salva e Apri",
                        width: 30
                    }
                );

                pulsantis.push(
                    {
                        text: "",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/save-and-close.png",
                        link: "",
                        id: "btn_save_close",
                        disabled: false,
                        title: "Salva e Chiudi",
                        width: 30
                    }
                );

                pulsantis.push(
                    {
                        text: "",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/pdf-amministrativo-emt.png",
                        link: "",
                        id: "btn_pdf_amministrativo",
                        disabled: false,
                        title: "PDF Amministrativo",
                        width: 30
                    }

                );

                pulsantis.push(
                    {
                        text: "",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/pdf-completo-emt.png",
                        link: "",
                        id: "btn_pdf_completo",
                        disabled: false,
                        title: "PDF Completo",
                        width: 30
                    }

                );

                pulsantis.push(
                    {
                        text: "Indietro",
                        icon: "mdi-arrow-left",
                        image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                        link: "/confOrariChoice",
                        id: "btn_back",
                        disabled: false,
                        title: "Indietro",
                        width: 35
                    }
                );



                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 10);
        },

        resetSumDay: async function () {
            /*  this.totPresenzaMese = monthPresenza;
                  this.totStraordinariMese = monthStraordinary; */

            this.forecast[0].quantity = "";
            this.forecast[1].quantity = "";
            this.forecast[2].quantity = "";
            this.forecast[3].quantity = "";
            this.forecast[4].quantity = "";
        },

        roundstaff: function (val) {
            console.log(val);

            return val.toFixed(2);

        },

        updateSumTotals: function () {
            //update all
            var result = Object.entries(this.monthArray);
            //   console.log ("qui" ,  result );
            var monthPresenza = 0;
            var monthStraordinary = 0;
            var monthAssenza = 0;
            var monthReperibilita = 0;
            var monthIntervento = 0;

            //var monthAssenza = 0 ;

            var ordinary = "";
            var straordinary = "";
            var assenza = "";
            var reperibilita = "";
            var intervento = "";

            for (var dat of result) {
                ordinary = dat[1].hour_ordinario;
                straordinary = dat[1].hour_straordinario;
                assenza = dat[1].hour_activity;
                reperibilita = dat[1].hour_reperibilita;
                intervento = dat[1].hour_intervento;

                if (ordinary > 0) monthPresenza += parseFloat(ordinary);
                if (straordinary > 0) monthStraordinary += parseFloat(straordinary);
                if (assenza > 0) monthAssenza += parseFloat(assenza);
                if (reperibilita > 0) monthReperibilita += parseFloat(reperibilita);
                if (intervento > 0) monthIntervento += parseFloat(intervento);

            }
            this.totPresenzaMese = this.roundstaff(monthPresenza);
            this.totStraordinariMese = this.roundstaff(monthStraordinary);
            this.totAssenzaMese = this.roundstaff(monthAssenza);
            this.totReperibilitaMese = this.roundstaff(monthReperibilita);
            this.totInterventoMese = this.roundstaff(monthIntervento);

            var totali = parseFloat(monthPresenza) + parseFloat(monthStraordinary);

            this.$root.$children[0].curDipOreTop = "";
            this.$root.$children[0].curDipOreTopLabel = this.roundstaff(totali) + " H";

        },

        updateSumDay: async function () {
            // var token = window.$cookies.get("token");
            // var system = window.$cookies.get("system");

            var username = window.$cookies.get("username");
            console.log(username);

            var ord = this.forecast[0].quantity;
            var straord = this.forecast[1].quantity;
            var ass = this.forecast[2].quantity;
            var id_ass = this.forecast[3].quantity;
            var rep = this.forecast[6].quantity;
            var interv = this.forecast[7].quantity;

            if (ass == 0 || ass == "0") {
                id_ass = "";
                this.forecast[3].quantity = "";

            }

            this.forecast[4].quantity = parseFloat(ord) + parseFloat(straord);

            console.log("meseArray", this.monthArray);

            this.monthArray[this.curDay + "_1"].hour_ordinario = ord;
            this.monthArray[this.curDay + "_1"].hour_activity = ass;
            this.monthArray[this.curDay + "_1"].hour_straordinario = straord;
            this.monthArray[this.curDay + "_1"].idtypeactivity = id_ass;
            this.monthArray[this.curDay + "_1"].hour_reperibilita = rep;
            this.monthArray[this.curDay + "_1"].hour_intervento = interv;

            //trial
            this.updateSumTotals();

            //last modify update calendar icons
            this.syncMOnthIcons();


        },

        syncArrayDipendenti: async function () {

            console.log(this.currDipendente);

            // this.currDipendente = "";
            // this.$root.$children[0].curDipTop = "";

            this.$root.$children[0].curDipOreTop = "";
            this.$root.$children[0].curDipOreTopLabel = "";

            //aziende
            var arrAziende = [];
            var arrDipendenti = [];

            var aziende = this.globalArrAziende; // Object.entries( this.monthArray.aziende  ) ;
            //   var aziende =   this.aziende  ;

            console.log("Elenco aziende:", this.aziende);
            // this.currAzienda
            //console.log ( "aziendeOK" , aziende );
            for (var az of aziende) {

                var currArAzienda = az[1].fld_name;
                console.log("az", az);
                arrAziende.push(az[1].fld_name);

                var dipArray = az[1].dipendenti;
                for (var dip of dipArray) {
                    //console.log ( dip.fld_name );
                    //  console.log ( dip );
                    if (currArAzienda == this.currAzienda) {
                        arrDipendenti.push(dip);

                    }
                }

            }
            //this.aziende = arrAziende;
            this.dipendenti = arrDipendenti;

        },

        reloadGrid() {

            console.log("RELOADGRID");


            var params = this.getParamsApi();

            console.log("RELOAD_GRID");
            console.log("CUR_YEAR: ", this.curYear);
            console.log("CUR_MONTH: ", this.curMonth);
            console.log("TOKEN_USER: ", atob(params.token));
            console.log("CUR_FULL_NAME_RES: ", this.curResource);
            console.log("CUR_AZIENDA: ", this.currAzienda);

            console.log("CUR_DIPENDENTI: ", this.dipendenti);

            if (this.dipendenti.length == 0) {
                $("#sec_move_dip").hide();
            }

            if (this.dipendenti.length > 0) {
                $("#sec_move_dip").show();


                // abilito le frecce a seconda se sono sul primo o l'ultimo elemento

                var cur_pos = 0;
                var find_pos = 0;

                for (var dip of this.dipendenti) {

                    if (this.curResource == dip.fld_name) {
                        find_pos = cur_pos;
                    }

                    cur_pos = cur_pos + 1;

                }

                $("#btn_indietro_dip").show();
                $("#btn_avanti_dip").show();

                if (find_pos == 0) {

                    $("#btn_indietro_dip").hide();

                }

                console.log("FIND POS: ", find_pos);
                console.log("DIP LEN: ", this.dipendenti.length);

                if (find_pos == (this.dipendenti.length - 1)) {

                    $("#btn_avanti_dip").hide();

                }


            }


            window.anno = this.curYear;
            window.mese = this.curMonth;
            window.user_selected = atob(params.token);

            //window.cur_full_name_res = this.curResource;
            window.cur_full_name_res = this.currAzienda;

            //  $("#full_name_res").html(this.curResource);

            if (this.currAzienda != "") {

                $("#full_name_res").html(this.currAzienda);

                $("#name_dip_sel").html(this.curResource);


                window.table.ajax.reload();

            }
            else {


                this.loadAziendaUserLogged();

            }






        },

        loadAziendaUserLogged: async function () {

            var that = this;

            var v_token = window.$cookies.get("sel_token");

            var response = await apitimesheet.getAziendaByUser(
                v_token
            ).then((res) => {

                console.log("res from getAziendaByUser", res);

                $("#res_full_name").html("<b>" + res.data.Azienda + "</b>");



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        getParamsApi() {


            console.log("CUR DIP 2: ", this.currDipendente);


            var curDip = parseInt(this.currDipendente);
            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");

            if (curDip > 0) {
                console.log("curDIp", curDip, "this.dipendenti", this.dipendenti);
                //for( var dip in this.dipendenti  )
                for (const [key, dip] of Object.entries(this.dipendenti)) {
                    console.log("key", key);
                    if (parseInt(dip.resourceid) == curDip) {
                        console.log("dip", dip, "token", token, "system", system);
                        token = dip.v_user;
                        system = dip.resourceid;

                    }
                }

            }
            console.log({
                "token": token,
                "system": system
            });
            return {
                "token": token,
                "system": system
            };
        },

        syncMOnthIcons: function () {
            var arrTimesheet = this.monthArray;

            var todos = [];


            var sizeDot = "size:10px";

            //var sabdom = [] ;
            for (var day of Object.entries(arrTimesheet)) {
                console.log("giorno corrente", day[1]);
                var dayCur = day[1];
                var strDay = new Date();

                console.log("NOTA SPESE: ", dayCur.nota_spese);
                var v_nota_spese = "";
                if (dayCur.nota_spese == undefined) {
                    console.log("NOTA SPESE UNDEFINED ");
                }
                else {
                    v_nota_spese = dayCur.nota_spese;
                }
                console.log("NOTA SPESE 2: ", v_nota_spese);

                //Green for workingday
                if (dayCur.hour_ordinario > 0) {

                    var txtDesc = "Ordinarie  " + dayCur.hour_ordinario + " H";
                    if (dayCur.desc_progetto != "") {
                        txtDesc = txtDesc + " (" + dayCur.desc_progetto + ")";
                    }

                    console.log("Ordinari");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: txtDesc,
                        dates: [strDay],
                        color: "green",
                        highlight: true,
                        isComplete: true,
                        dot: true,
                    });

                }

                if (dayCur.hour_straordinario > 0) {

                    console.log("Straordinari");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Straordinario " + dayCur.hour_straordinario + " H",
                        dates: [strDay],
                        color: "gray",
                        isComplete: false,
                        dot: {
                            style: {
                                style: sizeDot,
                            }
                        }
                    });

                }

                if (dayCur.hour_reperibilita > 0) {

                    console.log("Reperibilita");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Reperibilità " + dayCur.hour_reperibilita + " H",
                        dates: [strDay],
                        color: "pink",
                        isComplete: false,
                        dot: {
                            style: {
                                style: sizeDot,
                            }
                        }
                    });

                }

                if (dayCur.hour_intervento > 0) {

                    console.log("Intervento");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Intervento " + dayCur.hour_intervento + " H",
                        dates: [strDay],
                        color: "orange",
                        isComplete: false,
                        dot: {
                            style: {
                                style: sizeDot,
                            }
                        }
                    });

                }


                if (dayCur.hour_activity > 0) {

                    console.log("Assenza");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);


                    if (dayCur.idtypeactivity == 4) // malattia
                    {


                        todos.push({
                            description: "N. Protocollo (" + dayCur.num_protocollo + ")",
                            dates: [strDay],
                            color: "red",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 3) // ferie
                    {

                        todos.push({
                            description: "Ferie " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 6) // permesso retribuito
                    {

                        todos.push({
                            description: "Permesso retribuito " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 7) // permesso non retribuito
                    {

                        todos.push({
                            description: "Permesso non retribuito " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 8) // non in forza
                    {

                        todos.push({
                            description: "Non in forza " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 17) // donazione sangue
                    {

                        todos.push({
                            description: "Donazione sangue " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 72) // permesso studio
                    {

                        todos.push({
                            description: "Permesso studio " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 73) // no work (partita iva)
                    {

                        todos.push({
                            description: "No work " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "blue",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 77) // partial work
                    {

                        var txtDesc2 = "Partial work " + dayCur.hour_activity + " H";
                        if (dayCur.desc_progetto != "") {
                            txtDesc2 = txtDesc2 + " (" + dayCur.desc_progetto + ")";
                        }


                        todos.push({
                            description: txtDesc2,
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 16) // congedo martimoniale
                    {

                        todos.push({
                            description: "Congedo matrimoniale " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 71) // permesso lutto
                    {

                        todos.push({
                            description: "Permesso lutto " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                }


                // nota spese

                if (v_nota_spese != "") {

                    console.log("Nota Spese");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Nota Spese € " + v_nota_spese,
                        dates: [strDay],
                        color: "purple",
                        isComplete: false,
                        dot: {
                            style: {
                                style: sizeDot,
                            }
                        }
                    });

                }


                if (dayCur.is_festivo) {
                    console.log("fest");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: dayCur.desc_festività,
                        dates: [strDay],
                        color: "blue",
                        isComplete: false,
                        dot: {
                            style: {
                                style: sizeDot,
                            }
                        }
                    });

                }
                if (!dayCur.is_festivo) {
                    if (
                        dayCur.day_working == "NOT WORKING" &&
                        (dayCur.desc_day == "Sab" || dayCur.desc_day == "Dom")
                    ) {
                        strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                        todos.push({
                            description: "Non lavorativo",
                            dates: [strDay],
                            color: "blue",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });
                    }

                }

            }
            this.todos = todos;

        },

        syncMonth: async function () {

            var that = this;

            $("#Ordinarie").hide();
            $("#Assenza").hide();
            $("#Straordinari").hide();
            $("#Causale").hide();
            $("#Descrizione").hide();
            $("#Reperibilità").hide();
            $("#Intervento").hide();
            $("#Dalle").hide();
            $("#Alle").hide();
            $("#Progetto").hide();
            this.dayFormatted = "";

            //var params = this.getParamsApi();

            /*          var token = window.$cookies.get("token");
                        var system = window.$cookies.get("system"); */

            var token = window.$cookies.get("sel_token");
            var system = window.$cookies.get("sel_resource_id");

            //   console.log ( system );
            this.showSpinner = true;
            var response;
            var arrTimesheet = [];

            try {
                response = await this.$api
                    .GetTimesheetCalendar(token, system, this.curYear, this.curMonth)
                    .then((res) => {
                        arrTimesheet = res.data.Timesheet;
                        console.log("Timesheet", arrTimesheet);

                        this.monthArray = arrTimesheet;

                        var result = Object.entries(arrTimesheet);
                        console.log(result);

                        var info_month = arrTimesheet.info_month;

                        console.log("CUR AZIENDA: ", this.currAzienda);


                        this.IsPartitaIva = "N";
                        if (arrTimesheet.is_partita_iva == 1) {
                            this.IsPartitaIva = "S";
                        }

                        var a = window.$cookies.get("a");
                        if ((a == "S") || (a == "Y")) {
                            this.IsPartitaIva = "S";
                        }


                        var is_su = window.$cookies.get("a");

                        if ((is_su == "S") || (is_su == "Y")) {
                            this.viewRep = "S";
                            this.viewButtonInterv = "S";
                            this.viewInterv = "N";
                        }

                        if (is_su == "N") {
                            this.viewRep = arrTimesheet.is_enable_rep;
                            this.viewButtonInterv = arrTimesheet.is_enable_interv;
                        }

                        this.enableIntervRis = arrTimesheet.is_enable_interv;


                        if (!this.setupNavBottomDone) {
                            this.su = arrTimesheet.u_complete;
                            this.aa = arrTimesheet.az_complete;

                        }

                        this.curStatus = info_month.desc_stato;
                        this.curReadOnly = info_month.editable;
                        this.piano_orario = arrTimesheet.piano_orario;

                        console.log("info_month", info_month);

                        var causali = arrTimesheet.causali;
                        console.log("causali", causali);
                        var arrCausali = [];

                        for (var causale of causali) {
                            //arrCausali[causale.idtypeactivity] = causale.description  ;
                            arrCausali.push(causale.description);
                        }
                        // console.log ( arrCausali );
                        this.causali = causali;

                        //aziende
                        var arrAziende = [];
                        var arrDipendenti = [];
                        console.log(arrDipendenti);

                        var aziende = Object.entries(arrTimesheet.aziende);
                        //console.log ( "aziendeOK" , aziende );
                        for (var az of aziende) {
                            console.log("az", az);
                            arrAziende.push(az[1].fld_name);

                        }

                        arrAziende.sort();

                        if (arrAziende.length > 1 && !this.setupNavBottomDone) {

                            //attention
                            this.aziende = arrAziende;
                            this.dipendenti = arrDipendenti;
                            this.globalArrAziende = aziende;

                            /*  this.dipendenti =  this.$root.$children[0].dipendenti;
                              this.aziende =  this.$root.$children[0].aziende;
                               this.globalArrAziende =  this.$root.$children[0].globalArrAziende; */

                        }

                        console.log("arraz", arrAziende);
                        console.log("globalArrAziende", this.globalArrAziende);
                        //this.causali = arrCausali;
                        //Start
                        //var datesOk =   [ ];
                        //  var descOk =   [ ];

                        console.log("SYNC_MONTH DIPENDENTI: ", this.dipendenti);
                        console.log("SYNC_MONTH DIPENDENTI LEN: ", this.dipendenti.length);

                        this.curResource = info_month.full_name;

                        if (this.dipendenti.length == 0) {

                            this.hideShowSecNav(false);

                        }

                        if (this.dipendenti.length > 0) {

                            this.hideShowSecNav(true);

                            $("#name_dip_sel").html(info_month.full_name);

                            // abilito le frecce a seconda se sono sul primo o l'ultimo elemento

                            var cur_pos = 0;
                            var find_pos = 0;

                            for (var dip of this.dipendenti) {

                                if (info_month.full_name == dip.fld_name) {
                                    find_pos = cur_pos;
                                }

                                cur_pos = cur_pos + 1;

                            }

                            $("#btn_indietro_dip").show();
                            $("#btn_avanti_dip").show();

                            if (find_pos == 0) {

                                $("#btn_indietro_dip").hide();

                            }

                            console.log("FIND POS: ", find_pos);
                            console.log("DIP LEN: ", this.dipendenti.length);

                            if (find_pos == (this.dipendenti.length - 1)) {

                                $("#btn_avanti_dip").hide();

                            }




                        }


                        this.syncMOnthIcons();


                        if (this.currAzienda != "") {

                            $("#res_full_name").html("<b>" + this.currAzienda + "</b>");


                        }
                        else {

                            this.loadAziendaUserLogged();

                        }

                        if (this.viewRep == "N") {
                            $("#Reperibilità").hide();
                        }


                        if (this.viewInterv == "N") {
                            $("#Intervento").hide();
                            $("#Dalle").hide();
                            $("#Alle").hide();
                        }


                        // imposto i giorni disabilitati

                        // this.datesDisabled = arrTimesheet.days_disable;

                        // var datesDisabled = [];

                        // for (var xx = 0; xx < arrTimesheet.days_disable.length; xx++) {

                        //     var elements33 = arrTimesheet.days_disable[xx].split("-");

                        //     //console.log("ELEMENTS33: ", elements33);

                        //     var v_anno33 = parseInt(elements33[0]);
                        //     var v_mese33 = parseInt(elements33[1]) - 1;
                        //     var v_giorno33 = parseInt(elements33[2]);

                        //     datesDisabled.push({
                        //         start: new Date(v_anno33, v_mese33, v_giorno33),
                        //         end: new Date(v_anno33, v_mese33, v_giorno33)
                        //     });

                        // }

                        // console.log("DATES DISABLED: ", datesDisabled);



                        // this.disabledDates = datesDisabled;






                        setTimeout(() => {

                            this.funcEvent();

                        }, 300);


                        setTimeout(() => {

                            that.dateStartEdit = null;
                            that.dateEndEdit = null;

                        }, 500);



                    });
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            console.log(response);



            this.resetSumDay();
            this.updateSumTotals();

            //Setup status at top
            this.setupNavTop();

            //if ( !this.setupNavBottomDone)
            this.setupNavBottom();

        },

        pageChange(obj) {



            this.resetDay();

            this.monthArray = [];
            this.dayFormatted = "";
            this.curYear = obj.year;
            this.curMonth = obj.month;
            this.forecast[0].quantity = 0;
            this.forecast[1].quantity = 0;
            this.forecast[2].quantity = 0;
            this.forecast[3].quantity = 0;
            this.forecast[4].quantity = 0;
            this.forecast[6].quantity = 0;

            this.entrata1 = "";
            this.entrata2 = "";
            this.uscita1 = "";
            this.uscita2 = "";

            this.day_editable = false;

            this.syncMonth();



        },

        onDayClick(day) {



            if (this.lastDayId != day.id) {
                this.dayStatusSelected = 1;
                this.lastDayId = day.id;
            } else {

                if (this.dayStatusSelected == 1) {
                    this.dayStatusSelected = 0;
                } else {
                    this.dayStatusSelected = 1;
                }

            }


            console.log("SELECIONATO: ", this.dayStatusSelected);


            $("#Ordinarie").hide();
            $("#Assenza").hide();
            $("#Straordinari").hide();
            $("#Causale").hide();
            $("#Descrizione").hide();
            $("#Reperibilità").hide();
            $("#Intervento").hide();
            $("#Dalle").hide();
            $("#Alle").hide();
            $("#Progetto").hide();
            this.dayFormatted = "";

            console.log("DISABLE DATES: ", this.datesDisabled);



            var dayDisabled = 0;
            if (this.datesDisabled.length > 0) {

                for (var xx4 = 0; xx4 < this.datesDisabled.length; xx4++) {
                    if (this.datesDisabled[xx4] == day.id) {
                        dayDisabled = 1;
                    }
                }


            }

            console.log("DAY DISABLED: ", dayDisabled);



            if (dayDisabled == 0) {

                if (this.dayStatusSelected == 1) {

                    if (this.IsPartitaIva == "S") {
                        $("#Ordinarie").show();
                        $("#Assenza").show();
                        $("#Causale").show();
                    }

                    $("#Straordinari").show();
                    $("#Descrizione").show();

                    if (this.viewRep == "S") {
                        $("#Reperibilità").show();
                    }

                    if (this.viewInterv == "S") {
                        $("#Intervento").show();
                        $("#Dalle").show();
                        $("#Alle").show();
                    }

                    if (this.countProjAssoc > 1) {
                        $("#Progetto").show();
                    }



                    console.log("MONTH ARRAY: ", this.monthArray);


                    this.curDay = day.day;

                    this.forecast[0].quantity = 0;
                    this.forecast[1].quantity = 0;
                    this.forecast[2].quantity = 0;
                    this.forecast[3].quantity = 0;
                    this.forecast[4].quantity = 0;
                    this.forecast[6].quantity = 0;
                    this.forecast[7].quantity = 0;

                    this.entrata1 = "";
                    this.entrata2 = "";
                    this.uscita1 = "";
                    this.uscita2 = "";

                    //const idx = this.days.findIndex(d => d.id === day.id);
                    console.log(day);
                    // this.forecast[0].day = day.ariaLabel;
                    if (day) this.dayFormatted = day.ariaLabel;

                    try {
                        var ord = this.monthArray[day.day + "_1"].hour_ordinario;
                        var straord = this.monthArray[day.day + "_1"].hour_straordinario;
                        var assenza = this.monthArray[day.day + "_1"].hour_activity;
                        var idtypeactivity = this.monthArray[day.day + "_1"].idtypeactivity;
                        var description = this.monthArray[day.day + "_1"].note;
                        var rep = this.monthArray[day.day + "_1"].hour_reperibilita;
                        var interv = this.monthArray[day.day + "_1"].hour_intervento;
                        var hour_from_int = this.monthArray[day.day + "_1"].hour_from_intervento;
                        var hour_to_int = this.monthArray[day.day + "_1"].hour_to_intervento;

                        console.log("EX", this.monthArray[day.day + "_1"].entrata1);

                        this.entrata1 = this.monthArray[day.day + "_1"].entrata1.substr(0, 5);
                        this.entrata2 = this.monthArray[day.day + "_1"].entrata2.substr(0, 5);
                        this.uscita1 = this.monthArray[day.day + "_1"].uscita1.substr(0, 5);
                        this.uscita2 = this.monthArray[day.day + "_1"].uscita2.substr(0, 5);

                        console.log("DESCRIZIONE: ", description);



                        $("#inputStraordinari").val(straord + " H");
                        if (description == null) {
                            description = "";
                        }
                        if (description == "null") {
                            description = "";
                        }

                        this.valDescription = description;
                        $("#inputDescrizione").val(description);




                        $("#inputReperibilità").val(rep + " H");
                        $("#inputIntervento").val(interv + " H");

                        if (hour_from_int != "") {

                            if (hour_from_int != null) {
                                var elements3 = hour_from_int.split(":");

                                hour_from_int = elements3[0] + ":" + elements3[1];

                                $("#inputDalle").val(hour_from_int);

                            }


                        }

                        if (hour_to_int != "") {

                            if (hour_to_int != null) {
                                var elements4 = hour_to_int.split(":");

                                hour_to_int = elements4[0] + ":" + elements4[1];

                                $("#inputAlle").val(hour_to_int);

                            }



                        }

                        //alert (this.entrata1);

                        this.day_editable = Boolean(this.monthArray[day.day + "_1"].day_editable);
                        this.forecast[0].quantity = ord;
                        this.forecast[1].quantity = straord;
                        this.forecast[2].quantity = assenza;
                        this.forecast[3].quantity = idtypeactivity;
                        this.forecast[4].quantity = parseFloat(ord) + parseFloat(straord);
                        this.forecast[6].quantity = rep;
                        this.forecast[7].quantity = interv;

                        this.forecast[8].timeStart = hour_from_int;
                        this.forecast[9].timeEnd = hour_to_int;

                        if (this.countProjAssoc > 1) {

                            var tot_ord = 0;
                            for (var xOrd44 = 1; xOrd44 <= 3; xOrd44++) {

                                var xKeyDay22 = this.curDay + "_" + xOrd44.toString();

                                if (xKeyDay22 in this.monthArray) {
                                    tot_ord = tot_ord + parseFloat(this.monthArray[xKeyDay22].hour_ordinario)
                                }

                            }

                            this.forecast[0].quantity = tot_ord;

                            var tot_ore_ass = this.piano_orario[0].lunedi - tot_ord;

                            this.forecast[2].quantity = tot_ore_ass;


                        }


                    } catch (error) {
                        console.log(error);
                    }

                }

            }

            if (this.dayStatusSelected == 0) {

                console.log("DAY DESELECTED");


                $(".clStatusTS").focus();
            }





        },
    },

    data() {






        var today = new Date();
        var curMonth = today.getMonth() + 1;
        var curYear = today.getFullYear();
        var curDay = today.getDate();

        var todos = [];

        // dichiarazioni
        return {

            selProgettoFilter: 0,


            oreTotaliGiornataProgetti: 0,
            dataGiornataProgetti: "",

            valuesProjects: [],

            dateStartEdit: this.getDateStartDisease(),
            dateEndEdit: this.getDateEndDisease(),


            enableIntervRis: "N",

            lista_orari: [
                "09:00",
                "09:30",
                "10:00",
                "10:30",
                "11:00",
                "11:30",
                "12:00",
                "12:30",
                "13:00",
                "13:30",
                "14:00",
                "14:30",
                "15:00",
                "15:30",
                "16:00",
                "16:30",
                "17:00",
                "17:30",
                "18:00",
            ],

            valDescription: "",


            IsPartitaIva: "N",

            datesDisabled: [],

            lastDayId: "",

            dayStatusSelected: 0,

            idProjDefault: 0,

            IsPartiIVAPartTime: "N",

            countProjAssoc: 0,

            disabledDates: [],


            viewRep: "N",

            viewButtonInterv: "N",

            viewInterv: "N",


            oreContratto: 0,

            progetti: [],

            searchProgetti: "",

            sheetProgetti: false,

            totReperibilitaMese: "0",

            totInterventoMese: "0",


            showColumns: false,




            dipendetiElenco: [],

            isDipEbadge: 0,
            entrata1: "",
            entrata2: "",
            uscita1: "",
            uscita2: "",

            curResource: "",

            itemsEbadge: ['Totali', 'Ebadge',],
            tab: null,
            menuAziende: false,
            search: '',
            searchDipendenti: '',
            todos,
            globalArrAziende: {},
            setupNavBottomDone: false,
            currDipendente: "",
            currDipendenteObj: {},
            currAzienda: "",
            su: 'N',
            aa: 'N',
            sheet: false,

            allStatus: [{
                img: 'keep.png',
                title: 'Aperto',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red"
            },
            {
                img: 'hangouts.png',
                title: 'For approval',
                "id": 2,
                icon: "mdi-account-arrow-right",
                "color": "yellow"
            },
            {
                img: 'inbox.png',
                title: 'Chiuso',
                "id": 3,
                icon: "mdi-account-lock",
                "color": "green"
            },
            ],
            tiles: [{
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inboex'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Meessenger'
            },
            {
                img: 'google.png',
                title: 'Gooegle+'
            },
            {
                img: 'keep.png',
                title: 'Keeep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'K3eep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hango3uts'
            },
            {
                img: 'messenger.png',
                title: 'Messen3ger'
            },
            {
                img: 'google.png',
                title: 'Google3+'
            },
            ],

            admindialog: false,
            sheetStatus: false,
            sheetAziende: false,
            sheetDipendenti: false,
            /*  curDayReadOnly:true, */
            totPresenzaMese: "0",
            totAssenzaMese: "0",
            totStraordinariMese: "0",
            causali: [],
            aziende: [],
            dipendenti: [],
            day_editable: false,
            curStatus: "Open",
            piano_orario: {},
            colorsStati: {
                "Close": "green",
                "For approval": "yellow",
                "Open": "red"
            },

            datesPoint: [new Date("2021-12-12"), new Date("2021-12-13")],
            showSpinner: false,
            curMonth: curMonth,
            curYear: curYear,
            curDay: curDay,

            monthArray: [],
            labels: ["SU", "MO", "TU", "WED", "TH", "FR", "SA"],
            time: 0,
            itemsQuantity: [
                "0",
                "0.5",
                "1",
                "1.5",
                "2",
                "2.5",
                "3",
                "3.5",
                "4",
                "4.5",
                "5",
                "5.5",
                "6",
                "6.5",
                "7",
                "7.5",
                "8",
                "8.5",
                "9",
                "9.5",
                "10",
            ],
            forecast: [{
                day: "Ordinarie",
                icon: "mdi-account-hard-hat",
                quantity: "0",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",
            },
            {
                day: "Straordinari",
                icon: "mdi-account-plus",
                quantity: "0",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",
            },
            {
                day: "Assenza",
                icon: "mdi-account-minus",
                quantity: "0",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",

            },
            {
                day: "Causale",
                icon: "mdi-format-list-bulleted-type",
                quantity: "Festività",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",

            },
            {
                day: "Totale",
                icon: "mdi-expand-all",
                quantity: "8",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",

            },

            {
                day: "Descrizione",
                icon: "mdi-file-document-outline",
                quantity: "Festività",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",
            },

            {
                day: "Reperibilità",
                icon: "mdi-home-search",
                quantity: "0",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",

            },

            {
                day: "Intervento",
                icon: "mdi-account-hard-hat",
                quantity: "0",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",

            },


            {
                day: "Dalle",
                icon: "mdi-clock-time-eight-outline",
                quantity: "Festività",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",
            },

            {
                day: "Alle",
                icon: "mdi-clock-time-eight-outline",
                quantity: "Festività",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",
            },

            {
                day: "Progetto",
                icon: "mdi-star-minus-outline",
                quantity: "Festività",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",
            },

            ],

            dayFormatted: "",
            dayAbout: "San Cristoforo",

            attributesOld: [{
                dot: {
                    style: {
                        "background-color": "brown",
                    },
                },
                dates: this.datesPoint
                /*  [
                                   new Date( '2021-12-12'), // Jan 12th
                                   new Date('2021-12-13'), // Jan 26th
                                   new Date(2018, 0, 15), // Jan 15th
                               ] */
                ,
            },

                /*  {
                        dot: "red",
                        dates: this.datesPoint,
                        }, */
            ],

            /*   date: new Date(), */
            date: "",

            /*   "it" : it,
            en: en,
            es: es,
            de: de */
        };
    },

    computed: {

        filteredProgetti() {


            return _.orderBy(this.progetti.filter(item => {
                if (!this.searchProgetti) return this.progetti;
                return (item.toLowerCase().includes(this.searchProgetti.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {

            var elements = _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.full_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline');

            console.log("ELEMENTS: ", elements);

            var arrDip = [];
            var n_row = 1;

            for (var dip of elements) {

                dip.n_dip = n_row;

                n_row = n_row + 1;

                arrDip.push(dip);

            }


            return arrDip;
        },



        filteredAziende() {

            console.log("AZI 1: ", this.aziende);

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        attributes() {
            var ok = 0;
            console.log(ok);
            return [

                // Attributes for todos
                ...this.todos.map((todo) => ({
                    dates: todo.dates,
                    dot: todo.dot ? {
                        color: todo.color,
                        class: todo.isComplete ? "opacity-75" : "",
                    } : false,
                    bar: todo.bar ? todo.bar : false,
                    popover: {
                        label: todo.description,
                        hideIndicator: false,
                    },
                    customData: todo,
                })),
            ];
        },
    },

    components: {
        datepicker,
        RiseLoader,
    },

};
</script>

<style>
#timesheetCalendarRisVerTs .clViewProjects {
    cursor: pointer;
    margin-left: 10px;
}


#timesheetCalendarRisVerTs .clResName {
    margin-left: 7px;
    font-size: 13px;
    font-weight: bold;
}

#timesheetCalendarRisVerTs .minusContainer {
    margin-right: 10px !important;
}

#timesheetCalendarRisVerTs .plusContainer {
    background: white !important;
    border: 1px solid !important;
    cursor: pointer !important;
    max-height: 15px !important;
    min-width: 15px !important;
    margin-right: 10px !important;
}


.cl_res_full_name {
    padding-left: 8px;
    padding-top: 2px;
}

#timesheetCalendarRisVerTs .cl_cont_azi_res_ts {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: flex-start !important;
    min-height: 70px !important;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    padding-top: 3px !important;
    background-color: white;
    border-color: #f5cf2e;
}

#timesheetCalendarRisVerTs .cont_names {
    max-width: 93%;
    display: flex;
    flex-direction: column;
    min-width: 100%;
}



.contQuanties {
    max-width: 420px !important;
}


#timesheetCalendarRisVerTs .selprogetti {
    max-width: 23px;
    min-height: 23px;
    margin-left: 208px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: -10px;
}

.cl_input_text_row {
    font-size: 11px !important;
    background-color: white;
    margin-top: 7px;
}

.cl_input_text_row:disabled {
    font-size: 11px !important;
    background-color: inherit;
    margin-top: 7px;
}

.cl_ora_interv {
    border-bottom: 1px solid #ced4da;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 4px;
    padding-left: 0px;
    margin-top: 10px;
    --background: white;
}


.cont_ts_un_prog .val_status {
    width: 10px !important;
}

.cont_ts_un_prog .val_sede_cliente {
    width: 10px !important;
}


.clRowHolidaysPermitsUser {
    display: flex;
    margin-left: 10px;
}



#myTable .dataTable tbody tr {
    padding: 5px !important;
}






.deleteRow img {
    width: 16px;
    cursor: pointer;
}

#timesheetCalendarRisVerTs .v-list-item {
    margin-left: 20px;
}

#timesheetCalendarRisVerTs select:focus-visible {
    outline: 1px dashed rgb(247, 247, 247);
}

#timesheetCalendarRisVerTs .v-select__slot {
    font-size: 10px;
}

#timesheetCalendarRisVerTs .v-list-item__title {
    font-size: 12px;
}

#timesheetCalendarRisVerTs .vc-day {
    min-height: 36px !important;
    padding: 8px;
}

#timesheetCalendarRisVerTs .contQuanties {
    max-width: 420px !important;
}

#timesheetCalendarRisVerTs .vc-title {
    font-size: 12px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    color: black !important;
}

@media only screen and (max-width: 600px) {

    #timesheetCalendarRisVerTs .v-menu__content REMOVE {
        left: 0px !important;
        min-width: 100vw !important;
        top: auto !important;
        bottom: 0px !important;
        position: fixed !important;
        min-height: 200px;
        /*   min-height: 300px; */

        background: #80808091;
        background: white;

        /*  pointer-events: none; */

    }

    #timesheetCalendarRisVerTs .v-menu__content .v-select-listREMOVE {
        max-height: 100%;
        bottom: 0px;
        position: absolute;
        overflow: auto;
        min-width: 100vw;
    }

    #timesheetCalendarRisVerTs .vc-day {
        min-height: 2px !important;
        padding: 6px 8px;
    }

    #timesheetCalendarRisVerTs .contQuanties {
        max-width: 320px !important;
    }
}

#timesheetCalendarRisVerTs span.titletotals {
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
}

#timesheetCalendarRisVerTs .totals {
    transform: translate(0px, 78px);
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    font-size: 12px;
    margin-top: 17px;
}

#timesheetCalendarRisVerTs .contDayFormatted {}

#timesheetCalendarRisVerTs .dayFormatted {
    color: black !important;
    font-weight: bolder;
}

#timesheetCalendarRisVerTs .spanStatus {
    color: red;
}

#timesheetCalendarRisVerTs .rowStatus {
    display: none;
}

#timesheetCalendarRisVerTs .progressBar {
    position: fixed;
    top: 25%;
    left: 25%;
    position: fixed;
    justify-content: center;
    display: flex;
    right: 0;
    left: 0;
    z-index: 100000;
    width: 100%;
    top: 0;
}

#timesheetCalendarRisVerTs .contcalendar .v-card__actions {
    padding: 0px !important;
    padding-top: 0px !important;
}

#timesheetCalendarRisVerTs .nocolor {
    border: none !important;
    box-shadow: none !important;
}

#timesheetCalendarRisVerTs {
    padding-top: 0px;
    max-width: 90vw;
    margin: 0 auto;
}

#timesheetCalendarRisVerTs .contcalendar {
    min-width: 60vw;
    align-content: center;
}

#timesheetCalendarRisVerTs .vc-container {
    --day-content-height: 50px;
    --day-content-width: 50px;
}

#timesheetCalendarRisVerTs .vc-container.vc-is-expanded {
    border: none;
}

#timesheetCalendarRisVerTs .selectHour {
    padding-left: 25px;
    font-size: 12px !important;
}

#timesheetCalendarRisVerTs .v-list-item {
    min-height: 42px;
}

#timesheetCalendarRisVerTs .v-list-item__icon {
    margin: 10px 0px;
}

#timesheetCalendarRisVerTs .totaleCombo input {
    color: black !important;
    font-weight: 600;
}

#timesheetCalendarRisVerTs html:not(.hydrated) body {
    display: block;
}

#timesheetCalendarRisVerTs .vc-header {
    font-family: 'Montserrat' !important;

}


#timesheetCalendarRisVerTs .minus {
    cursor: pointer !important;
    border: 0 !important;
    width: 15px !important;
    height: 15px !important;
}

#timesheetCalendarRisVerTs .plus {
    cursor: pointer !important;
    border: 0 !important;
    width: 15px !important;
    height: 15px !important;
}

#timesheetCalendarRisVerTs .text-left {
    min-height: 10px !important;
    max-height: 10px !important;
}


#timesheetCalendarRisVerTs .addbuttons {
    display: flex;
    margin-left: 5px;
    align-items: center;
    font-size: 20px;
    /* padding-bottom: 12px; */
}

#timesheetCalendarRisVerTs .conSelect {
    display: flex;

}

#timesheetCalendarRisVerTs div[disabled] {
    opacity: 0.2;
    cursor: default;
}
</style>

<style>
#timesheetCalendarRisVerTs input[type="number"] {
    -moz-appearance: textfield;
}

#timesheetCalendarRisVerTs input::-webkit-outer-spin-button,
#timesheetCalendarRisVerTs input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

#timesheetCalendarRisVerTs .detailtotals {
    display: none;
}

#timesheetCalendarRisVerTs .vc-bars {
    max-width: 18px;
    transform: translate(0px, 5px);
}

#timesheetCalendarRisVerTs .vc-bar {
    max-height: 1px !important;
}

#timesheetCalendarRisVerTs .v-application .text-center {

    overflow: auto;
}

#timesheetCalendarRisVerTs .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: inherit !important;
}

#timesheetCalendarRisVerTs .v-btn--disabled {
    opacity: 0.3;
}

#timesheetCalendarRisVerTs .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100% !important;
}

#timesheetCalendarRisVerTs .itemAzienda {
    margin-left: 15px;
}

.itemNumDip {
    margin-left: 15px;
    max-width: 20px;
}

.itemNameDip {
    margin-left: 15px;
}



#timesheetCalendarRisVerTs .headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

#timesheetCalendarRisVerTs .closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

#timesheetCalendarRisVerTs .closeStatusSheet {
    position: absolute;
    right: 0;
}

#timesheetCalendarRisVerTs .v-dialog__content {
    margin-top: 56px;
}

#timesheetCalendarRisVerTs .containerFields {
    transform: translateX(-12px);
    max-width: 420px !important;
    min-width: 420px !important;
    overflow-y: auto !important;
    min-height: 350px !important;
    max-height: 350px !important;
}

#timesheetCalendarRisVerTs .vc-dot {
    width: 8px !important;
    height: 8px !important;
}



.cont_ts_un_prog #myTable {
    margin-right: 3px;
    margin-top: 72px;
}

.cont_ts_un_prog table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_ts_un_prog table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 26px !important;
}

.cont_ts_un_prog th {
    padding-left: 5px !important;
}

.cont_ts_un_prog ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_ts_un_prog .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_ts_un_prog div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cont_ts_un_prog #myTable_wrapper {
    padding-top: 0px;
    margin-top: 6px !important
}

.cont_ts_un_prog .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

@media screen and (max-width: 960px) {

    .cont_ts_un_prog .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_ts_un_prog #myTable {
        /* margin-top:54px; */
    }

    .cont_ts_un_prog #myTable_filter {
        top: 103px;
        position: fixed;

    }

}

@media screen and (max-width: 768px) {

    #timesheetCalendarRisVerTs .cl_cont_azi_res_ts {
        display: flex !important;
        flex-direction: row !important;
        align-items: flex-start !important;
        min-height: 70px !important;
        padding-top: 19px !important;
    }

    #timesheetCalendarRisVerTs .cont_names {
        max-width: 93% !important;
        display: flex !important;
        flex-direction: column !important;
        min-width: 100% !important;
        margin-top: 3px !important;
    }


}

.cont_ts_un_prog input[type=Search] {
    display: none !important;
}

.cont_ts_un_prog ion-item {
    --min-height: 20px;
}

.cont_ts_un_prog .dataTable tbody tr td {
    vertical-align: middle;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 6px !important;
}

#timesheetCalendarRisVerTs .vc-pane-container {
    margin-top: -7px;
}

.salva {
    max-width: 25px;
    min-height: 25px;
    margin-left: -2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.salvaeapri {
    max-width: 27px;
    min-height: 27px;
    margin-left: -2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.salvaechiudi {
    max-width: 27px;
    min-height: 27px;
    margin-left: 4px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.pdfamministrativo {
    max-width: 24px;
    min-height: 24px;
    margin-left: 4px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.pdfcompleto {
    max-width: 25px;
    min-height: 25px;
    margin-left: 4px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
    white-space: normal !important;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h {
    white-space: normal !important;
}

.cont_ts_un_prog .day_red {
    color: red;
}


.cont_ts_un_prog .day_blue {
    color: blue;
}


.cont_ts_un_prog .day_green {
    color: green;
}

.cont_ts_un_prog .day_black {
    color: black;
}

.name_res {
    font-weight: bold;
    padding-left: 5px;
    transform: translateY(-4px);
}


.cont_ts_un_prog #myTable_filter {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: flex-start !important;
    min-height: 70px !important;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    padding-top: 3px !important;
    background-color: white;
    border-color: #f5cf2e;
}


#timesheetCalendarRisVerTs .cont_buttons {

    width: 122px;
    min-width: 122px;

}

#timesheetCalendarRisVerTs .name_res {
    font-weight: bold;
    padding-left: 5px;
    transform: translate(3px, 8px);
}


.move_dip {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    min-width: 100%;
    left: 0;
    transform: translate(0px, 38px);
}

.cl_h_forzatura {
    transform: translateX(-15px) !important;
}

.cl_h_ordinario {
    transform: translateX(-17px) !important;
}

.cl_h_straordinario {
    transform: translateX(-16px) !important;
}

.cl_h_assenza_ore_1 {
    transform: translateX(-16px) !important;
}

.cl_h_assenza_tipo_1 {
    transform: translateX(-16px) !important;
}

.cl_h_assenza_ore_2 {
    transform: translateX(-17px) !important;
}

.cl_h_assenza_tipo_2 {
    transform: translateX(-18px) !important;
}

.cl_h_totale {
    transform: translateX(-18px) !important;
}

.cl_h_descrizione {
    transform: translateX(-16px) !important;
}

.cl_h_progetto {
    transform: translateX(-15px) !important;
}





@media screen and (max-width: 1024px) {

    .cont_ts_un_prog .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 100vw;
        left: 0px;
        position: fixed;

    }

    .cont_ts_un_prog #myTable {
        max-width: 100vw;
        margin-right: 0px;
        min-width: 100vw;
        position: relative;
        /*   left: 12px; */
        width: 100vw !important;
        margin-top: 70px;
    }

    .cont_ts_un_prog .cl_view {
        display: inline-flex;
    }

    #timesheetCalendarRisVerTs .vc-pane-container {
        margin-top: -8px;
    }

    #timesheetCalendarRisVerTs .totals {
        margin-top: -18px !important;
    }

    .cont_ts_un_prog #myTable_wrapper {
        padding-top: 0px;
        margin-top: 6px !important;
        max-width: 100vw !important;
        overflow-x: hidden !important;
    }

    .cont_ts_un_prog ion-select {
        max-width: 100px !important;
        word-break: break-all !important;
    }

    .cont_ts_un_prog ion-select label {
        max-width: 100px !important;
        word-break: break-all !important;
    }

    .cont_ts_un_prog .cl_tipo_ass_1 {
        max-width: 100px !important;
        margin-top: -4px !important;
    }

    .cont_ts_un_prog .day_red {
        color: red !important;
        min-width: 40px !important;
        margin-top: -4px;
        min-height: 30px !important;
        padding-top: 7px !important;
    }

    .cont_ts_un_prog .day_green {
        color: green !important;
        min-width: 40px !important;
        margin-top: -4px;
        min-height: 30px !important;
        padding-top: 7px !important;
    }

    .cont_ts_un_prog .day_black {
        color: black !important;
        min-width: 40px !important;
        margin-top: -4px;
        min-height: 30px !important;
        padding-top: 7px !important;

    }

    .cont_ts_un_prog .cl_giorno {
        margin-left: 18px;
    }

    .cont_ts_un_prog .col_ordinario {
        margin-top: -4px !important;
    }

    .cont_ts_un_prog .cl_eccedenze {
        margin-top: -4px !important;
    }

    .cont_ts_un_prog .cl_ore_ass_1 {
        margin-top: -4px !important;
    }



    .cont_ts_un_prog #myTable_filter {
        display: flex;
        flex-direction: row;
        align-items: flex-start !important;
        min-height: 70px !important;
        padding-top: 3px !important;
    }

    #timesheetCalendarRisVerTs .cont_buttons {

        width: 122px;
        min-width: 122px;

    }


    .name_res {
        font-weight: bold;
        padding-left: 5px;
        transform: translate(3px, 8px);
    }

    .move_dip {
        position: absolute;
        transform: translate(0px, 34px);
    }


    .cl_h_forzatura {}

    .cl_h_ordinario {}

    .cl_h_straordinario {}

    .cl_h_assenza_ore_1 {}

    .cl_h_assenza_tipo_1 {}

    .cl_h_assenza_ore_2 {}

    .cl_h_assenza_tipo_2 {}

    .cl_h_totale {}

    .cl_h_descrizione {}

    .cl_h_progetto {}





}
</style>